export const avaTalk = [
    "你似乎每次都绕过那个地点。",
    "为什么呢？",
    "为什么不回去你过去的家，看望一下你过去的地点呢？",
    "...",
    "虽然我有能力穿梭于各个时空...",
    "但是，枝江...",
    "我并不排斥于过去。",
    "我只是觉得，造成现在这样子的局面，和我有很大的关系。",
    "是当初的矛盾，导致五人各落一方，这可不是单纯谁谁谁的责任。",
    "确实。但是，我，向晚，绝对是最应该负责任的那个。",
    "事情本应没有这么严重，可是因为我的任性毁了这一切。",
    "可是，我也明白我付不了任何责任...",
    "枝江，最终还是会迎来结局和新的故事。",
    "我做什么也无济于事了...",
    "从那之后，我再也不敢面对它...",
    "...",
    "抱歉。我不应该说这些了。",
    "我去练琴了。"
];
export default avaTalk;
