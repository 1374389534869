export const chapter4 = {
    para1: [
        { object: "Aside", text: "...", music: "dark" },
        { object: "Me", text: "氧气水平。" },
        { object: "Teammate", text: "正常。" },
        { object: "Me", text: "燃料。" },
        { object: "Teammate", text: "正常。" },
        { object: "Me", text: "导航仪。" },
        { object: "Teammate", text: "正常。" },
        { object: "Me", text: "电力。" },
        { object: "Teammate", text: "正常。" },
        { object: "Me", text: "通讯。" },
        { object: "Teammate", text: "正常。" },
        { object: "Me", text: "驾驶舱。" },
        { object: "Teammate", text: "正常。" },
        { object: "Me", text: "..." },
        { object: "Me", text: "我们已经走了多久了？" },
        { object: "Teammate", text: "36小时12分36秒，队长。" },
        { object: "Me", text: "信号失真严重吗？" },
        { object: "Teammate", text: "足够让我们找到它了。" },
        { object: "Me", text: "..." },
        { object: "Me", text: "快要到影响范围了。那个时候我们要抓紧时间。" },
        { object: "Teammate", text: "明白，队长！" },
        { object: "Aside", text: "我安排完工作后，静静地坐在驾驶舱内。" },
        { object: "Aside", text: "我慢慢掏出了一张照片，照片里的女孩爽朗地笑着，头上还戴着一个红色的晃悠悠。" },
        { object: "Me", text: "这下真要度日如年了。" },
        { object: "Teammate", text: "怎么，忘不掉？" },
        { object: "Me", text: "怎么能这么容易就忘掉..." },
        { object: "Me", text: "当初她还只是个小女孩的时候，我就被她深深地吸引住了。" },
        { object: "Me", text: "那时候，我就对她说过，我要一直陪她去最好最大的舞台。" },
        { object: "Me", text: "可现在，我却失守承诺了。" },
        { object: "Me", text: "五年的时间，这还好说..." },
        { object: "Me", text: "可要是估算错误了，回去发现已经过了几百年..." },
        { object: "Me", text: "那我就是个彻头彻尾的骗子" },
        { object: "Me", text: "我也没有办法辩解，我没有任何办法..." },
        { object: "Teammate", text: "不论是五百年、五千年还是五万年。说不定回到地球后，一个新的贝拉在等着你呢？" },
        { object: "Me", text: "不可能...这个宇宙中，只有一个北极星的天使。" },
        { object: "Teammate", text: "..." },
        { object: "Teammate", text: "确实。" },
        { object: "Teammate", text: "你也累了。" },
        { object: "Teammate", text: "我看你整天整夜的没合过眼。" },
        { object: "Teammate", text: "小憩一下，到时候我们会叫你的。" },
        { object: "Aside", text: "于是我就收下那一张小小的照片，回到了卧室中。" },
        { object: "Aside", text: "不知是我真的累了还是怎么的。" },
        { object: "Aside", text: "刚躺到床上，就闭上眼睛，睡着了。" },
        { object: "Aside", text: "..." },
        { object: "Teammate", text: "快到了，队长。" },
        { object: "Aside", text: "我立马惊醒过来，来到驾驶舱。" },
        { object: "Aside", text: "我看见远方有一个黄色的小点，正向我们驶来，逐渐变得越来越大，覆盖到了整个视野。" },
        { object: "Teammate", text: "我们已经被捕获了，队长。" },
        { object: "Me", text: "你，你，还有你，我们四个下去。" },
        { object: "Me", text: "分离飞船。" },
        { object: "Teammate", text: "明白。" },
        { object: "Aside", text: "驾驶着宇宙飞船，我们驶向了星球的深处。" },
        { object: "Aside", text: "从外表看过来，这里虽然有着大气层，但是却死气沉沉，毫无生机。" },
        { object: "Me", text: "一颗普通的荒芜星球。" },
        { object: "Me", text: "追踪信号，找到了之后就地销毁。" },
        { object: "Teammate", text: "明白。" },
        { object: "Teammate", text: "等等..." },
        { object: "Teammate", text: "好像有些不对劲...", music: "back?" },
        { object: "Aside", text: "等我们终于穿越了大气层，离地面越来越近的时候。" },
        { object: "Aside", text: "我们才发现这里并不是一片荒漠。" },
        { object: "Aside", text: "有山川，有河流，有海洋！" },
        { object: "Aside", text: "这里和地球根本没什么区别！" },
        { object: "Me", text: "这是什么情况？" },
        { object: "Teammate", text: "我们快要来到信号发射的位置了。" },
        { object: "Me", text: "好...先..." },
        { object: "Teammate", text: "快看，队长！那里居然有文明遗迹！" },
        { object: "Me", text: "什么？！" },
        { object: "Aside", text: "我望眼过去，发现远处朦胧的一片，居然是一片建筑群！" },
        { object: "Me", text: "不对！不对！这就是一个地球城市！" },
        { object: "Me", text: "怎么回事？我们开了半天，居然开回老家了？" },
        { object: "Teammate", text: "可刚刚我们看到的完全是一个荒芜星球。" },
        { object: "Teammate", text: "进去怎么就这样子了？" },
        { object: "Me", text: "继续前进，降低速度..." },
        { object: "Me", text: "打开广播，看看能不能..." },
        { object: "Teammate", text: "我们什么也收不到，队长。" },
        { object: "Teammate", text: "什么也没有！" },
        { object: "Me", text: "怎么可能？如果已经..." },
        { object: "Me", text: "不对！与上面联络！快！" },
        { object: "Teammate", text: "不用联络了。我们都在这里。" },
        { object: "Me", text: "？？？" },
        { object: "Me", text: "我不是只叫了三个人..." },
        { object: "Teammate", text: "不。你把我们所有人都叫过来了。一个不差。" },
        { object: "Me", text: "这他妈现在是什么情况？！！" },
        { object: "Aside", text: "飞船已经成功行驶到信号发射点的上空。" },
        { object: "Teammate", text: "快看！下面有好多人呢！" },
        { object: "Teammate", text: "他们似乎早早地就在等着我们了..." },
        { object: "Me", text: "..." },
        { object: "Aside", text: "我彻底搞懵了。我的心里填充满了大大小小的震惊和疑惑。" },
        { object: "Aside", text: "仿佛一场如梦似的幻觉，但却又是如此地真实。" },
        { object: "Aside", text: "这一系列反常的现象，弄得我神魂颠倒。" },
        { object: "Aside", text: "我一时间不知如何是好，只能随波逐流，任由现实的掌控。" },
        { object: "Aside", text: "我和我的队友们，都晃晃悠悠地走下了飞船，仿佛不适应这里的空气，开始大呼着气。" },
        { object: "Aside", text: "我们就如同凯旋归来的英雄一般，迎来的是一阵欢呼。" },
        { object: "Aside", text: "我似乎隐隐约约地看到，人群中一个人正在快速放大。" },
        { object: "Aside", text: "等我终于看清了她的脸的时候，她已经紧紧地拥抱住了我。" },
        { object: "Bella", text: "看！就像我说的，我们还是会在北极星上相遇的！" },
        { object: "Bella", text: "你来的还不算晚！我的巡回演出还没结束呢！" }
    ],
    para1choice: [
        { object: "Me", choice: "你真的是贝拉吗？", text: "你...你真的是贝拉吗？" },
        { object: "Me", choice: "这里是哪...", text: "这里是哪...这里是地球吗？" },
        { object: "Me", choice: "对不起...我们还有事在身", text: "贝...贝拉..." }
    ],
    para1AfterChoice0: [
        { object: "Bella", text: "喂！" },
        { object: "Aside", text: "她随即给了我一拳，一下子把我打清醒了。" },
        { object: "Bella", text: "真没礼貌！我不是贝拉，我还是谁啊？" },
        { object: "Bella", text: "怎么样，这一记可熟悉了吧。" },
        { object: "Bella", text: "可不许去看别的女人！" },
        { object: "Me", text: "怎么可能呢？...我们明明...?" },
        { object: "Bella", text: "明明什么呢？你回来了，就像你当初信守承诺的那样。" },
        { object: "Bella", text: "你没有辜负我。你还是来看我的表演了。" },
        { object: "Me", text: "但是..." },
        { object: "Bella", text: "没什么但是的。我可不喜欢听这种词。" },
        { object: "Bella", text: "我只希望你能在我的视野里，是一个快乐，幸福的人。" },
        { object: "Bella", text: "好了，花车要开过来了！" },
        { object: "Bella", text: "我们一起跳上花车来庆祝吧！" }
    ],
    para1AfterChoice1: [
        { object: "Bella", text: "不不不，小傻瓜。" },
        { object: "Bella", text: "这里是北极星。" },
        { object: "Me", text: "北极星是个恒星。" },
        { object: "Bella", text: "天上有很多个北极星。" },
        { object: "Bella", text: "但是这颗北极星是唯一被称作【家】的那一颗。" },
        { object: "Bella", text: "也就是说，你们都已经回家了。" },
        { object: "Me", text: "..." },
        { object: "Me", text: "什么北欧神话。你不会还说你是北极星天使吧。" },
        { object: "Bella", text: "哈哈，你怎么知道。" },
        { object: "Aside", text: "随机她展现了一个小小的动作。顷刻间，她的身体居然迸发出了光芒！" },
        { object: "Me", text: "什么？" },
        { object: "Bella", text: "之前的一切。只是某种梦境罢了。" },
        { object: "Bella", text: "欢迎回家！" },
        { object: "Aside", text: "随后，一阵音乐想起，一排花车扑面而来。" },
        { object: "Aside", text: "等我没反应过来，她把我拉到了花车上面，欢呼起来。" }
    ],
    para1AfterChoice2: [
        { object: "Bella", text: "嗯？怎么了？" },
        { object: "Me", text: "对不起。可是目前我还有事在身，所以..." },
        { object: "Bella", text: "当然可以。没有什么比这个更重要了！" },
        { object: "Aside", text: "我随即转过身来，对着其中一个队友说道：" },
        { object: "Me", text: "卫星是在这附近吗？" },
        { object: "Teammate", text: "emmmmmm" },
        { object: "Teammate", text: "卫星...卫星信号就在你的身前，队长。" },
        { object: "Me", text: "？" },
        { object: "Aside", text: "我转过头去，发现贝拉正在用那星空般深沉的紫色大眼睛望着我，把我吓了一跳。" },
        { object: "Bella", text: "或许我就是你们说的卫星。" },
        { object: "Bella", text: "【北极星】号。不是吗？" },
        { object: "Me", text: "这个世界真是乱了套了！还是我已经疯了！" },
        { object: "Bella", text: "这个世界还好好的。你也没疯。" },
        { object: "Bella", text: "你只是经历了一场大的梦游，现在刚刚醒来过来，还暂时无法接受真相罢了。" },
        { object: "Bella", text: "来吧，我带你去冷静冷静。跟我一起跳上去吧！" },
        { object: "Me", text: "说罢，我便不由自主地，跟着她跳上沿途的一个主题花车上。" },
    ],
    para2: [
        { object: "Aside", text: "我的队友们，也不由自主地跟着站在了花车上面。" },
        { object: "Aside", text: "我们还没脱下制服，在花车中显得格外地明显。" },
        { object: "Aside", text: "贝拉穿着她的那套漂亮的白色演出服，如同一颗闪耀的新星。" },
        { object: "Aside", text: "在彩灯和花圈的笼罩下，她站在花车中间，向周围的人群挥舞致意着。" },
        { object: "Aside", text: "周围的人也都热情地回应着她，无不举手祝贺，丢来红色的鲜艳玫瑰。" },
        { object: "Aside", text: "到处都是一派欢腾的景象。" },
        { object: "Aside", text: "人们载歌载舞着，好不热闹。" },
        { object: "Bella", text: "尽情欢呼吧，各位！" },
        { object: "Aside", text: "突然间，我好像被什么东西重重得捶了一下，完全清醒了意识。" },
        { object: "Aside", text: "眼前的一片终于不再是一片是真是假的朦胧，" },
        { object: "Aside", text: "而是绝绝对对的真实景象。" },
        { object: "Aside", text: "我突然醒来过来，过去的一切确实就是一场梦。" },
        { object: "Aside", text: "那场毫不应该的冒险，没有意义的旅行，" },
        { object: "Aside", text: "本就是臆想出来的虚幻，马上就会渐渐消失，" },
        { object: "Aside", text: "最后消失不见，如同那蔚蓝色的生命星球。" }
    ],
    para2choice: [

    ]
}
export default chapter4