<template>
  <div>
    <audio
      src="../../public/music/sweetDream.mp3"
      autoplay
      v-if="music === '-1-' && showStatus"
    />
    <audio
      src="../../public/music/star.mp3"
      autoplay
      v-if="music === '-2-' && showStatus"
    />
    <audio
      src="../../public/music/gita.mp3"
      autoplay
      v-if="music === '-3-' && showStatus"
    />
    <audio
      src="../../public/music/flow.mp3"
      autoplay
      v-if="music === '-4-' && showStatus"
    />
    <audio
      src="../../public/music/start2door.mp3"
      autoplay
      v-if="music === '-6-' && showStatus"
    />
    <audio
      src="../../public/music/low.mp3"
      ref="choice"
    />
     <transition name="fade">
        <div class="title" v-if="chapterTitleStatus && showStatus">
          <span>{{ titleIndex }}</span>
          <div class="main-title">{{ title }}</div>
        </div>
      </transition>
    <div class="chapter">
      <div class="dialog-container" id="scroll">
        <transition-group name="slide-fade">
          <div
            v-for="(message, index) in dialogList"
            :key="index"
            :class="handleMessageClass(message.object)"
          >
            <div class="message">{{ message.text }}</div>
          </div>
        </transition-group>
      </div>
      <transition name="fade" mode="out-in">
        <div v-if="choiceStatus" class="dialog-choice" key="choice">
          <div v-for="(choice, index) in currentChoice" :key="index">
            <button @click="handleChoice(index)">
              <span>{{ choice.choice }}</span>
            </button>
          </div>
        </div>
        <div class="handle-button" key="next" v-else>
          <el-button @click="next" circle icon="el-icon-right"></el-button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "titleIndex", "showStatus", "chapter"],
  data: function () {
    return {
      dialogList: [],
      choice: "",
      para: 1,
      choiceStatus: false,
      chapterTitleStatus: false,
      num: 0,
      music: "",
    };
  },
  created() {
    setTimeout(() => {
      this.chapterTitleStatus = true;
      this.music = this.titleIndex;
      clearTimeout();
    }, 3000);
    setTimeout(() => {
      this.chapterTitleStatus = false;
      clearTimeout();
    }, 10000);
  },
  methods: {
    goDown() {
      //页面滑到最底部
      this.$nextTick(() => {
        let msg = document.getElementById("scroll"); // 获取对象
        msg.scrollTop = msg.scrollHeight; // 滚动高度
      });
    },
    handleMessageClass(object) {
      if (object === "Ang") return "ang";
      if (object === "Me") return "right";
      if (object === "Aside") return "center";
      if (object === "Diana") return "diana";
      if(object === "Bella") return "bella";
      if(object === "Ava") return "ava";
      if(object === "Croal") return "croal";
      if(object === "Eillen") return "eillen";
      else return "left";
    },
    handleChoice(index) {
      this.$refs.choice.play();
      this.dialogList.push(this.currentChoice[index]);
      this.num++;
      this.choiceStatus = false;
      this.choice = "para" + this.para + "AfterChoice" + index;
      this.goDown();
    },
    dialog(current) {
      const paraIndex = this.num;

      if (paraIndex < this.chapter[current].length) {
        if (this.chapter[current][paraIndex].music) {
        this.$emit("handleMusic", this.chapter[current][paraIndex].music);
      }

        this.dialogList.push(this.chapter[current][paraIndex]);
        this.num++;
        this.goDown();
        return;
      }
      if (paraIndex === this.chapter[current].length) {
        this.currentChoice = this.chapter[current + "choice"];
        if (this.currentChoice.length) {
          this.choiceStatus = true;
        } else {
          this.$emit("moveToNext", this.title);
        }
        return;
      }
      const paraIndexAfterChoice = paraIndex - this.chapter[current].length - 1;
      if (paraIndexAfterChoice < this.chapter[this.choice].length) {
        if (this.chapter[this.choice][paraIndexAfterChoice].music) {
        this.$emit("handleMusic", this.chapter[this.choice][paraIndexAfterChoice].music);
      }
        this.dialogList.push(this.chapter[this.choice][paraIndexAfterChoice]);
        this.num++;
        this.goDown();
        return;
      } else {
        this.num = 0;
        this.para++;
        return;
      }
    },
    next() {
      for (var i = 1; i <= 10; i++) {
        if (this.para === i) this.dialog("para" + i);
      }
    },
  },
};
</script>
<style>
</style>
<style scoped>
.chapter {
  color: white;
}
.chapter .left {
  float: left;
  text-align: left;
  width: 100%;
}
.chapter .right {
  float: right;
  text-align: right;
  width: 100%;
}
.chapter .center {
  text-align: center;
  color: rgba(255, 255, 255, 0.39);
}
.chapter .diana {
  float: left;
  width: 100%;
  text-align: left;
  color: rgb(255, 255, 255);
  text-shadow: 0 0 0.2em #f87, -0 -0 0.2em #f87;
}
.chapter .bella {
  float: left;
  width: 100%;
  text-align: left;
  color: rgb(255, 255, 255);
  text-shadow: 0 0 0.2em rgb(255, 22, 185), -0 -0 0.2em rgb(255, 34, 0);
}
.chapter .ava {
  float: left;
  width: 100%;
  text-align: left;
  color: rgb(255, 255, 255);
  text-shadow: 0 0 0.2em rgb(22, 201, 255), -0 -0 0.2em rgb(0, 247, 255);
}
.chapter .croal {
  float: left;
  width: 100%;
  text-align: left;
  color: rgb(255, 255, 255);
  text-shadow: 0 0 0.2em rgb(0, 50, 189), -0 -0 0.2em rgb(28, 0, 184);
}
.chapter .eillen {
  float: left;
  width: 100%;
  text-align: left;
  color: rgb(255, 255, 255);
  text-shadow: 0 0 0.2em rgb(226, 255, 119), -0 -0 0.2em rgb(241, 255, 119);
}
.chapter .ang {
  float: left;
  width: 100%;
  text-align: left;
  color: rgb(255, 255, 255);
  text-shadow: 0 0 0.2em rgb(255, 255, 255), -0 -0 0.2em rgb(255, 255, 255);
}
.chapter {
  width: 70vh;
  margin: 0 auto;
  text-align: center;
  padding-top: 5%;
}
.chapter .dialog-container {
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: center;
}
.title {
  position: absolute;
  padding-top: 15vh;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  color:white;
}
.title .main-title {
  font-size: 20vh;
  font-weight: bolder;

}
.chapter .dialog-choice {
  text-align: center;
  padding: 20px;
}
.chapter .dialog-choice button {
  background: transparent;
  border-color: transparent;
  color: rgba(255, 255, 255, 0.219);
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}
.chapter .dialog-choice button:hover {
  background: transparent;
  border-color: transparent;
  color: rgb(255, 255, 255);
  text-shadow: 0 0 0.2em rgb(255, 255, 255) rgb(255, 255, 255) -0 0.2em #f87;
}
.chapter .dialog-choice button span {
  font-size: 15px;
}
.chapter .handle-button {
  text-align: center;
}
.chapter .handle-button button {
  border-color: white;
  color: white;
  background: transparent;
  font-size: 40px;
  padding: 2vh;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}
.chapter .handle-button button:hover {
  border-color: rgba(255, 255, 255, 0);
  color: rgb(0, 0, 0);
  background: white;
  font-size: 40px;
  padding: 2vh;
}
.message {
  padding-top: 5vh;
  padding-bottom: 5vh;
}
</style>
