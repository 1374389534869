<template>
  <div class="buffer">
      <h3 class="title">请稍等...</h3>
      <i class="el-icon-loading"></i>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.buffer{
    text-align: center;
    padding:30vh;
}
.buffer .title{
    color: white;
}
.buffer .el-icon-loading{
    color: white;
}
</style>
