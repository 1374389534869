export const chapter5 = {
    para1: [
        { object: "S", text: "..." },
        { object: "S", text: "我找了她许多年。", music: "nothing" },
        { object: "S", text: "我不知道为什么，她就这样突然消失。" },
        { object: "S", text: "就像是人间蒸发一般。" },
        { object: "S", text: "我四处打听，到处奔走。" },
        { object: "S", text: "却始终也打听不到确切消息，只有些零零散散的踪迹。" },
        { object: "S", text: "有的时候，我差不多是心灰意冷了。" },
        { object: "S", text: "可在我原本就要遗忘的时候，内心的那股火苗又重新燃烧了起来。" },
        { object: "S", text: "每次到那个时候，我才发现:" },
        { object: "S", text: "我忘不掉。" },
        { object: "S", text: "从那次的大雨，那次的表演。" },
        { object: "S", text: "我确实是被击沉了，沉入了深深的海底。" },
        { object: "S", text: "那份歌声，那份韵律。" },
        { object: "S", text: "我仿佛又悬浮起来，飞在了高高的天空上。" },
        { object: "S", text: "这是一场幻梦，一场迷离的影子。" },
        { object: "S", text: "缠绕着我，让我无法自拔..." },
        { object: "S", text: "从那个时候，我就明白," },
        { object: "S", text: "我一定要找到她。" },
        { object: "S", text: "而这里，已经是我能打听到的，离她最近的地点。", music: "people" },
        { object: "Me", text: "..." },
        { object: "Aside", text: "说道这里，他突然间一言不发了。" },
        { object: "Aside", text: "他只是呆呆着望着前方。望着那一片群流" },
        { object: "Aside", text: "天上的太阳，还在温柔地抚摸着地面。" },
        { object: "Aside", text: "地上的世界却早已变得躁动，如昨日的暮光一般，" },
        { object: "Aside", text: "人流、车流，一片片群流，他们汇聚在一起。" },
        { object: "Aside", text: "就像一片奔腾不息的大海，永不干涸地流动着。" },
        { object: "S", text: "这么多年过去，很多事情已经淡了许多..." },
        { object: "S", text: "但我还是想再见她一面，亲手送给她这份礼物。", music: "wait" }
    ],
    para1choice: [
        { object: "Me", choice: "麦克风？", text: "这个，就是那个麦克风吗？" },
        { object: "Me", choice: "你会等到她的", text: "相信我，你会等到她的。" }
    ],
    para1AfterChoice0: [
        { object: "S", text: "是的。" },
        { object: "S", text: "这个麦克风，凝练了我对她曾经的那份最真诚的感觉。" },
        { object: "S", text: "在我看来，她就像是一个自由的鸟儿。" },
        { object: "S", text: "渴望将那份甜美的声音传递给更多的人，也正是她的期望..." },
        { object: "S", text: "这么多年来...这么多年来我一直在想这值不值得..." },
        { object: "S", text: "我曾经多次想过放弃，差点将它丢进了垃圾桶里。" },
        { object: "S", text: "可是...我还是没做到。" },
        { object: "S", text: "在这一方面，我是一个及其懦弱的人。" },
        { object: "S", text: "我便一直留着...一直留着...也留着我对她的思念。" },
        { object: "S", text: "我还是希望能亲手送给她这份小小的礼物。" },
        { object: "S", text: "不过，估计它也不像当初那般珍贵了..." }
    ],
    para1AfterChoice1: [
        { object: "Me", text: "总有一天。" },
        { object: "S", text: "谢谢你。" },
        { object: "S", text: "当初，我也是这么自信地对她说的。" },
        { object: "S", text: "在如今，我也在坚持着这一信念。" },
        { object: "S", text: "虽然很多时候，我已经再想着放弃..." },
        { object: "S", text: "但我还是想...还是想..." },
        { object: "S", text: "我还是无法忘怀。" },
        { object: "S", text: "这个麦克风，凝练了我对她曾经那份最真诚的感觉。" },
        { object: "S", text: "衷心希望我能亲自交给她。" },
        { object: "S", text: "也算是我最后一份牵挂。" },
        { object: "S", text: "虽然我知道...曾经的这些可能已经变得毫无用处..." },
    ],
    para2: [
        { object: "Me", text: "..." },
        { object: "S", text: "我每天。" },
        { object: "S", text: "我每天都盯着这些群流。" },
        { object: "S", text: "它们移动的是如此之快，如此之匆忙。" },
        { object: "S", text: "看到的每一个人，每一辆车，都是一道幻影。" },
        { object: "S", text: "在这些幻影之中，是否会有她的影子。" },
        { object: "S", text: "她的歌声，她的面容？" },
        { object: "S", text: "她...她现在又怎么样了呢？" },
        { object: "S", text: "她或许早就已经抛弃了过去的身份，开启了全新的自己。" },
        { object: "S", text: "或许变成了行业内的顶流，收到万众瞩目和青睐。" },
        { object: "S", text: "又或许，只是过着普通的不能在普通的生活，就和我一样。" },
        { object: "Me", text: "..." },
        { object: "S", text: "..." },
        { object: "S", text: "感谢你能耐心听完这段故事。" },
        { object: "S", text: "我先走了。" },
        { object: "Me", text: "..." },
        { object: "Me", text: "?" },
        { object: "Me", text: "等等！" },
        { object: "Aside", text: "突然，一段悠扬的韵律从群流中散发出来。" },
        { object: "Aside", text: "那是如此地纯粹，在这尘世之中，似乎一切的浮躁都安静了下来：" },
        { object: "Aside", text: "高高的青山上  萱草花开放..." },
        { object: "Aside", text: "采一朵  送给我  小小的姑娘..." },
        { object: "Aside", text: "声音来的很快，去的也很快，就像一场风。" },
        { object: "Aside", text: "他立马摘下了小丑的面具，" },
        { object: "Me", text: "等一下！" },
        { object: "Aside", text: "还没等我反应过来，他就抱着他那破破碎碎的盒子，就冲进了群流之中..." }
    ],
    para2choice: [

    ]
}
export default chapter5;
