export const chapter2 = {
    para1: [
        { object: "S", text: "在许多年前，那本是平常的一天...", music: "nothing" },
        { object: "S", text: "我在一个风雨大作的晚上，冒着雨走进了一家小店。" },
        { object: "S", text: "并不是因为什么...只是巧合，我正好看到一家还开着的就进去了。" },
        { object: "S", text: "那个时候的我刚淋了雨，感觉身体有些发冷。" },
        { object: "S", text: "我马上就要了些酒菜，想来好好暖一暖我的身子。" },
        { object: "S", text: "可是这似乎并没有太大用处。" },
        { object: "S", text: "我出了汗，身体还夹杂着雨水，不知道为什么,反而觉得更冷了些。" },
        { object: "S", text: "当时的我，只想等着雨赶快停，我赶快走..." },
        { object: "S", text: "这一切本该如此...不是吗？", music: "firstMeet" },
        { object: "S", text: "可让我没注意到的是，这里居然还有一个小展台。" },
        { object: "S", text: "一个女人...一个女人不知道从哪里出来了..." },
        { object: "S", text: "她穿着一身紫色的衣服，性感的大腿和完美的身材无不吸引着在场的每一个人。" },
        { object: "S", text: "这就像是一份诱惑...恐怖的是，我可能是受其最深影响的那个..." },
        { object: "S", text: "她看起来很高冷...就像我们现在所说的...酷盖。" },
        { object: "Me", text: "高冷？" },
        { object: "S", text: "是的..." },
        { object: "S", text: "啊...在那幽暗的灯光下，那曼妙的身姿，一下子就吸引住了我..." },
        { object: "S", text: "我一下子忘记了眼前的吃喝，目不转睛地盯着她。" },
        { object: "S", text: "随后，她便拿起那麦克风，长期了独特而美妙的旋律：" },
        { object: "Croal", text: "该怎么去形容  你最贴切..." },
        { object: "Croal", text: "该怎么去比较  你才算最特别..." },
        { object: "S", text: "真是该死...我永远也无法重现当时那样的场景..." },
        { object: "S", text: "真是让人如痴如醉..." },
        { object: "S", text: "对了，你有听过刚刚我说的那首歌吗？" }
    ],
    para1choice: [
        { object: "Me", choice: "听过", text: "是红色高跟鞋。" },
        { object: "Me", choice: "没听过", text: "恐怕并没有..." },
    ],
    para1AfterChoice0: [
        { object: "S", text: "对！对！就是它！" },
        { object: "Aside", text: "突然间，他一下子激动起来，就算戴着面具我也能感受到他两眼冒光。" },
        { object: "S", text: "啊...好了。我继续说。" }
    ],
    para1AfterChoice1: [
        { object: "S", text: "真是可惜。" },
        { object: "S", text: "相信你也会爱上它的。" },
        { object: "Me", text: "希望如此。" },
        { object: "S", text: "好的，我继续讲。" }
    ],
    para2: [
        { object: "S", text: "啊...说到她唱歌那段..." },
        { object: "S", text: "我不知道别人是怎么想的..." },
        { object: "S", text: "反正我觉得真的是美妙极了。" },
        { object: "S", text: "她的声音既有那种甜美，又有那种冷酷；" },
        { object: "S", text: "既有那种温柔，又有那种严厉；" },
        { object: "S", text: "既有那种高昂，也有那般低沉。" },
        { object: "S", text: "这一切组合在一起，成为了我前所未闻的声音。" },
        { object: "S", text: "这个声音，似乎与我产生了谐振。" },
        { object: "S", text: "一下子就把我击倒了，现在也没站起来。" },
        { object: "S", text: "再伴随着她那缓缓舞动的身体，仿佛将整个室内都晃动了起来，" },
        { object: "S", text: "让我陷入了一种似真似假的幻梦当中。" },
        { object: "S", text: "歌曲明明已经唱完了许久，可我却还觉得余音绕梁。" },
        { object: "Audio", text: "太好了！" },
        { object: "S", text: "周围的人都鼓起了掌，只有我一下子呆呆愣住了。" },
        { object: "Me", text: "有这么夸张..." },
        { object: "S", text: "我过了好久才反应过来，仿佛时间落后了别人几分钟。" },
        { object: "S", text: "待我反应过来的时候，那个女人已经不见了。" },
        { object: "S", text: "我一下子急了，仿佛出现了戒断反应。" },
        { object: "S", text: "啊的一声，一下子就栽倒在地上，晕了过去..." },
        { object: "Me", text: "这..." },
        { object: "S", text: "等我再次醒来的时候，发现自己躺在医院的病床上。" },
        { object: "S", text: "然后我就被医生检查了一通，一看，你猜怎么着？" },
    ],
    para2choice: [
        { object: "Me", choice: "旧伤复发？", text: "你有什么旧伤，然后复发了？" },
        { object: "Me", choice: "什么事也没？", text: "发现你什么事也没有？" }
    ],
    para2AfterChoice0: [
        { object: "S", text: "不！" },
        { object: "S", text: "我什么事也没有！" },
        { object: "S", text: "医生检查不出我任何毛病，就直接结下账把我打发走了。" }
    ],
    para2AfterChoice1: [
        { object: "S", text: "没错！" },
        { object: "S", text: "医生说我什么问题，纯粹是一下子太急了。" },
        { object: "S", text: "叫我以后记得冷静一点，然后结下账把我打发走了。" }
    ],
    para3: [
        { object: "Me", text: "..." },
        { object: "S", text: "那一夜已经是深夜，我独自走回了家。" },
        { object: "S", text: "我确实是没有任何病..." },
        { object: "S", text: "但从那一夜开始，我发现我已经摆脱不了那个幻梦。" },
        { object: "S", text: "我躺在床上，却迟迟没闭眼。" },
        { object: "S", text: "那个歌声一直在我脑海里徘徊。" },
        { object: "S", text: "那个身影一直就在我的声旁..." },
        { object: "S", text: "我发现卧室里是她，客厅里也是她，厕所里也是她..." },
        { object: "S", text: "她，和她的歌声几乎无处不在。" },
        { object: "S", text: "本以为我会这样被一直折磨到天亮。" },
        { object: "S", text: "可是想她的每一刻都是甜蜜的。" },
        { object: "S", text: "霎时间，我便闭上了眼睛，做了一个甜美的梦。" }
    ],
    para3choice: [

    ]
}
export default chapter2;
