export const chapter3 = {
    para1: [
        { object: "Aside", text: "一开始，这些奇妙的旅途让我感到无比惊奇。" },
        { object: "Aside", text: "但渐渐地，我似乎已经完全接受这种设定了。" },
        { object: "Aside", text: "我和乃琳姐姐一起四处穿梭，就像个时空旅行者。" },
        { object: "Aside", text: "这个世界莫名其妙地开始毁灭，而我也莫名其妙地成为了救世小组的一员。" },
        { object: "Aside", text: "世界崩塌的迹象越来越明显，却只有我们才能感受得到。" },
        { object: "Aside", text: "我们不得不加快脚步，超过崩坏的步伐。" },
        { object: "Aside", text: "当我问乃琳姐姐为什么当初要选我来和她一起时，她说：" },
        { object: "Eillen", text: "因为你也是镜子的一部分。" },
        { object: "Aside", text: "我并不理解这句话是什么意思" },
        { object: "Aside", text: "但目前，似乎不理解也并不会影响到什么。" },
        { object: "Eillen", text: "向晚，我们来了。" },
        { object: "Me", text: "嗨！向晚姐姐！" },
        { object: "Aside", text: "我们两个就这样突然出现在马路上，面对着向晚走了过来。" },
        { object: "Aside", text: "穿着一身出行的衣服，好像正在搞什么公路旅行似的。" },
        { object: "Ava", text: "哇！真的是你！" },
        { object: "Aside", text: "向晚那夸张的表情都要把我们吓了一跳:" },
        { object: "Me", text: "是的，但是却又是一种幻像。" },
        { object: "Ava", text: "好久不见，乃琳！" },
        { object: "Ava", text: "也不知道过了过久...但我还是清楚地记得你。" },
        { object: "Ava", text: "但...额...请问，你是谁呢？" },
        { object: "Me", text: "我...我是乃琳的学生..." },
        { object: "Ava", text: "但你看起来比我都大了。" },
        { object: "Aside", text: "我楞了一下，拿出镜子一看：" },
        { object: "Aside", text: "我居然已经不是那个稚嫩的儿童模样，而是一个成熟的女性了！" },
        { object: "Eillen", text: "那我也可以问问你...你旁边的那个和你长得一模一样的家伙，但一看就知道不是你的家伙是谁吗？" },
        { object: "VaV", text: "让我来自我介绍吧。我叫晚向。" },
        { object: "VaV", text: "我和向晚已经认识很久了。恐怕比你们要久。" },
        { object: "VaV", text: "但我们之间似乎少有见面，乃琳小姐。" },
        { object: "Eillen", text: "我想起你这个怪异的家伙了...你简直就是向晚的另一面。" },
        { object: "VaV", text: "也算是正式见面吧。这一次。" },
        { object: "Eillen", text: "你出现在这里干什么？" },
        { object: "VaV", text: "恐怕和你们一样。" },
        { object: "Ava", text: "..." },
        { object: "Eillen", text: "向晚。我们这次专门前来，是为了一件紧急之事，至少不是来听你的冷笑话派对。", music: "truth" },
    ],
    para1choice: [
        { object: "Eillen", text: "枝江开始崩塌了。我们需要你的帮助。", choice: "枝江开始崩塌了。" },
        { object: "Eillen", text: "晚晚，我需要你的帮助。", choice: "你对我们很重要" },
        { object: "Eillen", text: "所以说...请现在就去枝江...我们会尽力帮你扫除一切障碍", choice: "请抓紧时间..." }
    ],
    para1AfterChoice0: [
        { object: "Ava", text: "什么？真的发生了？" },
        { object: "Eillen", text: "现在已经出现了迹象，整个世界已经进入倒计时了。" },
        { object: "Eillen", text: "如果我们现在抓紧时间，" },
        { object: "Eillen", text: "那么这一切都是可以挽救回来的。" },
        { object: "Ava", text: "所以...这就是你要我赶快前往枝江的缘由？" },
        { object: "Eillen", text: "是的，晚晚！" },
        { object: "Eillen", text: "当初我们因意外分开，那道留下来的裂痕现在已经危及到整个世界。" },
        { object: "Eillen", text: "嘉然和贝拉已经在赶来的路上了。珈乐现在已经回来了。" },
        { object: "Eillen", text: "根据引导书，只要我们聚在一起，世界就可以重新被撰写下去！" },
    ],
    para1AfterChoice1: [
        { object: "Eillen", text: "现在枝江已经开始崩塌了。" },
        { object: "Ava", text: "什么？！" },
        { object: "Eillen", text: "晚晚。还记得我们曾经是最好的朋友吗？" },
        { object: "Eillen", text: "那场意外留下来的裂缝，现在看来不仅没有自动修复，" },
        { object: "Eillen", text: "反而还裂开地越来越大，到了要毁掉世界的地步。" },
        { object: "Eillen", text: "根据指导书，如果我们五个可以再次聚在一起，" },
        { object: "Eillen", text: "枝江就可以再次被修复。" },
        { object: "Eillen", text: "以前我一直不敢尝试，但现在我出现在了你的面前，我想你也不得不相信这些了。" },
        { object: "Ava", text: "你也知道说是曾经最好的朋友了..." },
        { object: "Eillen", text: "好的...或许对你而言现在的我很陌生了，" },
        { object: "Eillen", text: "但是我们真的需要你的帮助...非常需要..." },
    ],
    para1AfterChoice2: [
        { object: "Eillen", text: "请马上行动！" },
        { object: "Ava", text: "到底是什么事，如此着急？" },
        { object: "Eillen", text: "当年留下来的裂缝，现在已经开始导致枝江的崩塌了。" },
        { object: "Eillen", text: "我们五个需要携手团结起来，才能重新修复枝江！" },
        { object: "Eillen", text: "没时间作太多解释了！请加快步伐吧！不要再耽误时间了！" },
        { object: "Ava", text: "我不喜欢你现在这样子的态度，乃琳。" },
        { object: "Eillen", text: "我也请你，不要再耍小孩子脾气，认识到事情的严重性！" },
        { object: "Ava", text: "我当然知道...但恐怕事情没有你想象中的那般简单..." },
        { object: "Eillen", text: "无论复杂...如今我们都要重新聚在一起，才能使一切不会就这样毁掉！" },
        { object: "Me", text: "各位，消消气..." }
    ],
    para2: [
        { object: "Ava", text: "..." },
        { object: "VaV", text: "恐怕不是如此，乃琳小姐。" },
        { object: "Eillen", text: "你又是什么意思？" },
        { object: "Ava", text: "你跟她们说。" },
        { object: "VaV", text: "我们都看过那本引导书...恐怕你永远也凑不齐那曾经的五个人..." },
        { object: "Eillen", text: "我才不会相信你...作为神授者...我比你这个不知道从哪里冒出来的家伙要清楚的多。" },
        { object: "Ava", text: "但是你就是这个世界的其中之一..." },
        { object: "Eillen", text: "这是什么意思？" },
        { object: "Ava", text: "当从那次意外开始，裂缝形成之时，当初诞生的生命也随之破裂了。" },
        { object: "Ava", text: "情况变得太过复杂而纠结，以至于不可能再重新联结到一起..." },
        { object: "Ava", text: "我早该想到...你让我回到枝江是因为这件事..." },
        { object: "Ava", text: "现在回去，看到的只能是无穷无尽的噩梦..." },
        { object: "Ava", text: "很抱歉我只能以这样的方式说出真相。" },
        { object: "Eillen", text: "无论如何...我还是希望我们可以再尝试一遍..." },
        { object: "VaV", text: "不需要了。枝江的故事已经结束了。恐怕任何补救都已经无济于事了。" },
        { object: "Eillen", text: "我们需要亲自去验证！" },
        { object: "Ava", text: "..." },
        { object: "Ava", text: "我不想去...乃琳。" },
        { object: "Ava", text: "亲自看到那种景象，我感到很心痛。" },
        { object: "Ava", text: "我敢打赌，你连嘉然和贝拉都没成功召回来..." },
        { object: "Eillen", text: "所以说，你是要我独自一人看到那份景象吗...", },
        { object: "Ava", text: "别这样看着我，乃琳。" },
        { object: "Eillen", text: "晚晚。你的能力比我们都要大..." },
        { object: "Eillen", text: "我不需要像嘉然那样，费力去离她千里的特殊地点打开传送门把她接回来..." },
        { object: "Eillen", text: "我也不需要像贝拉那样，点亮那些宇宙中的星星来给她指引。" },
        { object: "Eillen", text: "你...一直都是想回来就回来的那个人。" },
        { object: "Ava", text: "怎么可能！" },
        { object: "Aside", text: "向晚一下子后退了几步，仿佛被说中了什么一般。" },
        { object: "Eillen", text: "但你一直没有。你在各个地方像个水母一样游荡着，偏偏却避开了枝江。" },
        { object: "Ava", text: "..." },
        { object: "Eillen", text: "我不知道枝江到底给你留下了什么..." },
        { object: "Eillen", text: "但现在，我需要你的帮助。" },
        { object: "Eillen", text: "就像是你所说的，难道，你甘愿让我和珈乐在那里孤独地度过最后的时光？" },
        { object: "Ava", text: "额..." },
        { object: "Eillen", text: "我真想念以前的时光，那个时候我也只是个普通人。但是大家都在一起。" },
        { object: "Eillen", text: "可现在我已经完全变了一个模样，你却还是以前那个喜欢到处旅游的吟游少女。" },
        { object: "Ava", text: "别说了。" },
        { object: "Eillen", text: "...可能我最后...还是得不到好结局吧..." },
        { object: "Ava", text: "哎呀！行了！我去！好了吧！" },
        { object: "Ava", text: "你说的对！我可以直接过去！" },
        { object: "VaV", text: "向晚，你怎么自爆了啊！" },
        { object: "Ava", text: "好了，这没什么大不了的。" },
        { object: "Ava", text: "就当是一场悲壮的演出吧！" },
        { object: "Eillen", text: "终于...太好了..." },
        { object: "Ava", text: "可别急着高兴，我可不是个好伺候的人！" },
        { object: "Me", text: "太好了向晚姐姐，我们马上就能见面了！" },
        { object: "Ava", text: "好了，晚向。要不你跟我一起走吧..." },
        { object: "VaV", text: "那居然都这样了，好吧。" },
        { object: "Aside", text: "...", music: "nothing" },
        { object: "Aside", text: "宇宙的星空，仍旧在断断续续地燃烧着。" },
        { object: "Aside", text: "它们时而亮，时而灭。" },
        { object: "Aside", text: "最重要的是，那颗指引的灯塔一直在闪耀着。" },
        { object: "Bella", text: "马上就能...马上就能到那里了吧..." },
        { object: "Aside", text: "每划一段时间，她就休息一下，看着那张照片：" },
        { object: "Bella", text: "马上就能...马上就能回去了吧..." },
        { object: "Me", text: "我感觉这颗星星的燃烧很不稳定。" },
        { object: "Me", text: "这些星星的燃烧都不稳定。" },
        { object: "Eillen", text: "想办法稳住那颗星星...那是最重要的..." },
        { object: "Me", text: "要加把火了..." },
        { object: "Aside", text: "噗嗤！" },
        { object: "Aside", text: "噗嗤！噗嗤！" },
        { object: "Me", text: "可恶...不会又会..." },
        { object: "Me", text: "啊，终于点起来了！" },
        { object: "Aside", text: "南边的那颗星，终于又开始熊熊燃烧起来。" },
        { object: "Aside", text: "它便是那希望的火种，载着迷途中的小船驶向远方..." }
    ],
    para2choice: [

    ]
}
export default chapter3;
