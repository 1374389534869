<template>
  <div :class="handleMemoryClass()">
    <audio src="../../public/music/break.mp3" ref="break" />
    <audio src="../../public/music/low.mp3" ref="low" />
    <transition name="fade" mode="out-in">
      <div class="puzzle" v-if="status === 'puzzle'">
        <div
          class="puzzle-list"
          v-for="(character, index) in characterList"
          :key="index"
        >
          <transition name="fade" mode="out-in">
            <div v-if="!character.answerStatus">
              <div
                :class="character.name"
                @mousedown="into(character.name)"
              ></div>
              <el-input
                class="answer-input"
                v-model="character.currentAnswer"
                @input="handleAnswer(character, index)"
              />
            </div>
          </transition>
        </div>
      </div>
      s
      <Chapter
        v-if="status === 'memory'"
        :chapter="memory"
        @moveToNext="out"
        class="chapter"
        @handleMusic="handleMusic"
      />
    </transition>
    <MyTalk ref="myTalk" />
  </div>
</template>
F
<script>
import Chapter from "./Chapter.vue";
import memory from "../assets/Dbace/memory.js";
import character from "../assets/Dbace/character";
import MyTalk from "./MyTalk.vue";
export default {
  components: { Chapter, MyTalk },
  data() {
    return {
      memoryList: memory,
      memory: "",
      status: "puzzle",
      characterList: character,
      textName: "start",
      num: 0,
    };
  },
  methods: {
    handleMusic(music) {
      this.$message("Yes");
      if (music) {
        this.$emit("handleMusic", music);
      }
    },
    handleMemoryClass() {
      if (this.status === "puzzle") return "memory-white";
      else return "memory-black";
    },
    handleAnswer(character, index) {
      if (character.currentAnswer === this.characterList[index].answer) {
        this.characterList[index].answerStatus = true;
        this.status = "memory";
        this.$refs.myTalk.changeTalk(character.name);
        setTimeout(() => {
          this.status = "puzzle";
          clearTimeout();
        }, 50); //画面闪烁
        this.num++;
        this.$refs.break.play();
        if (this.num === this.characterList.length) {
          this.$emit("handleStatus", "fakeOver");
        }
      }
    },
    into(name) {
      this.memory = this.memoryList[name];
      this.status = "memory";
      this.$refs.low.play();
    },
    out() {
      this.status = "puzzle";
      this.$refs.low.play();
    },
  },
};
</script>

<style scope>
.memory-white {
  height: 100vh;
  background: rgb(255, 255, 255);
}
.memory-black {
  height: 100vh;
  background: rgb(0, 0, 0);
}
.puzzle {
  display: flex;
  justify-content: center;
}
.puzzle .puzzle-list {
  margin-top: 25vh;
  text-align: center;
}
.puzzle .puzzle-list .answer-input {
  text-align: center;
  width: 50%;
}
.puzzle .puzzle-list .Diana {
  width: 20vh;
  height: 20vh;
  margin: 50px;
  background: #e799b0;
}
.puzzle .puzzle-list .Bella {
  width: 20vh;
  height: 20vh;
  margin: 50px;
  background: #bd7d74;
}
.puzzle .puzzle-list .Ava {
  width: 20vh;
  height: 20vh;
  margin: 50px;
  background: #9ac8e2;
}
.puzzle .puzzle-list .Croal {
  width: 20vh;
  height: 20vh;
  margin: 50px;
  background: #b8a6d9;
}
.puzzle .puzzle-list .Eillen {
  width: 20vh;
  height: 20vh;
  margin: 50px;
  background: #576690;
}
.mytalk {
  margin-top: 50px;
}
</style>
