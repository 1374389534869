export const chapter1 = {
    para1: [
        { object: "Aside", text: "这里是枝江。", music: "city" },
        { object: "Aside", text: "一座永葆青春的城市。" },
        { object: "Aside", text: "这么多年过去了，这里依旧是如此年轻。" },
        { object: "Aside", text: "年轻的人，年轻的事物，" },
        { object: "Aside", text: "尽管他们的外表似乎已经沾上了岁月的颜色。" },
        { object: "Aside", text: "就如同那些成熟的女人一样。" },
        { object: "Aside", text: "可是却更加富有韵味了，让人感到有安全感和依靠。" },
        { object: "Aside", text: "现在回忆起来，我的乃琳姐姐，便是这其中之一..." },
        { object: "Aside", text: "..." },
        { object: "Me", text: "乃琳姐姐！帐篷已经搭好了！", music: "picnic" },
        { object: "Aside", text: "乃琳是我们的童子军队长。" },
        { object: "Aside", text: "今天，她带领我们去郊区的山上扎营，打算举行一场别开生面的篝火晚会。" },
        { object: "Eillen", text: "哎，来了！" },
        { object: "Aside", text: "她仔细检查着帐篷，看看有没有什么疏漏。" },
        { object: "Eillen", text: "...没问题！帐篷搭得很好！很结实！" },
        { object: "Eillen", text: "做得很好，小姑娘！去休息吧！" },
        { object: "Aside", text: "马上，我那白嫩的小手就多出来一块白巧克力了。" },
        { object: "Me", text: "太好了！" },
        { object: "Aside", text: "我马上和其他分到的人坐在一起，一边谈笑着一边吃了个精光。" },
        { object: "Aside", text: "夜幕渐渐升起了。乃琳姐姐开始清点人数：" },
        { object: "Eillen", text: "一、二、...十四、十五。好，一个不少！" },
        { object: "Eillen", text: "我们的东西也都准备好了。现在我们就开始点火吧！" },
        { object: "Everyone", text: "好耶！" },
        { object: "Aside", text: "我自信满满地走到柴火面前，拿出了那一火种——火柴。" },
        { object: "Eillen", text: "小心一点哦！" },
        { object: "Me", text: "当然。我可不是小孩子了。" },
        { object: "Aside", text: "我拿出火柴棒，自信地一划," },
        { object: "Aside", text: "结果没着。" },
        { object: "Aside", text: "我又拿出来一根，结果又没着。" },
        { object: "Everyone", text: "真应该带个打火机..." },
        { object: "Aside", text: "噗嗤！" },
        { object: "Aside", text: "着了！" },
        { object: "Aside", text: "我将点燃的火柴丢进柴火堆中，顿时燃起了熊熊烈火，吓得我赶忙后退！" },
        { object: "Everyone", text: "芜湖！" },
        { object: "Aside", text: "大家的情绪开始被带动起来，一起载歌载舞好不热闹..." },
        { object: "Aside", text: "欢快的篝火晚会终于结束了。" },
        { object: "Aside", text: "月亮游荡在天空的正中央，地上的人们大多都进入了梦乡。" },
        { object: "Aside", text: "大人似乎总是有许多事，以至于他们总是无法入眠。" },
        { object: "Aside", text: "乃琳姐姐坐在山坡边上，望着远处的星河。" },
        { object: "Aside", text: "留恋着那些特殊的韵律，那场及时的雨。" },
        { object: "Aside", text: "过了一会，她又掏出了那张她心爱的小照片。" },
        { object: "Aside", text: "那个照片里面有五个年轻的女孩，而她就是曾经其中的一位。" },
        { object: "Me", text: "乃琳姐姐？" },
        { object: "Eillen", text: "嗯？你怎么还不睡觉？" },
        { object: "Eillen", text: "小孩子晚睡可是对身体不好的哦！" },
        { object: "Me", text: "我睡不着。" },
        { object: "Eillen", text: "..." },
        { object: "Eillen", text: "睡不着，那就陪我过来坐坐好了。" },
        { object: "Aside", text: "于是我就坐在她的旁边，随后依偎着她身上。" },
        { object: "Aside", text: "郊外的夜晚是寒冷的，可是她的身体却很温暖。" },
        { object: "Aside", text: "就像是卧室暖炉旁边的摇篮一样，让人着迷而感到舒适。" },
        { object: "Aside", text: "她的头发软软的香香的，如同奶油蛋糕一般美妙。" },
        { object: "Me", text: "乃琳老师？" },
        { object: "Eillen", text: "嗯？怎么了？" },
    ],
    para1choice: [
        { object: "Me", choice: "想见到嘉然", text: "我好想见到嘉然姐姐。" },
        { object: "Me", choice: "想见到贝拉", text: "我好想见到贝拉姐姐。" },
        { object: "Me", choice: "想见到向晚", text: "我好想见到向晚姐姐。" },
    ],
    para1AfterChoice0: [
        { object: "Eillen", text: "..." },
        { object: "Eillen", text: "总有一天，我们能看见她的吧。" },
        { object: "Me", text: "我好想看一下嘉然姐姐的画啊。你跟我说她生活在180年前的国外。" },
        { object: "Me", text: "180年前的画，一定和现在很不一样吧。" },
        { object: "Eillen", text: "真希望我能看到。" },
        { object: "Eillen", text: "不过嘉然姐姐是一个超越了时代的人物，说不定和现在差不多。" },
        { object: "Eillen", text: "她看似非常的柔弱，可却总是勇敢，敢于在不同的地方探索和交流新事物呢！" },
        { object: "Me", text: "啊，不！不要打破我的幻想！" },
        { object: "Eillen", text: "哈哈，自始至终都只是个小孩子。" }
    ],
    para1AfterChoice1: [
        { object: "Eillen", text: "看那里。" },
        { object: "Eillen", text: "她就在那颗闪闪发光的，永不迁移位置的北极星上。" },
        { object: "Me", text: "哇！好远啊！" },
        { object: "Eillen", text: "是啊！不过就算是这样，跨越了时间和空间，她也一定会回来。" },
        { object: "Eillen", text: "她会在另一个舞台上重新站起来，她会不在留任何遗憾。" },
        { object: "Me", text: "为什么？" },
        { object: "Eillen", text: "因为这是一场承诺。一旦许下诺言，就如同泰山一般沉重。" },
        { object: "Me", text: "为什么会这样？" },
        { object: "Eillen", text: "...你以后就懂了。" }
    ],
    para1AfterChoice2: [
        { object: "Eillen", text: "听...有人在拿着吉他哼唱。" },
        { object: "Eillen", text: "说不定那就是她呢。" },
        { object: "Me", text: "你总是这么说。" },
        { object: "Eillen", text: "哈哈，确实是这样。" },
        { object: "Eillen", text: "我也不知道该怎么再见到她。" },
        { object: "Eillen", text: "她那般自由而随性，说来就来，想走就走的架势可是谁也抵挡不来的。" },
        { object: "Eillen", text: "她唯一要担心的，就是她自己罢了。" },
        { object: "Me", text: "向晚姐姐是一个知性大姐姐吗？" },
        { object: "Eillen", text: "才不是。" },
        { object: "Eillen", text: "就是个喜欢耍脾气的小少女。" },
        { object: "Eillen", text: "不过却也分外可爱。" }
    ],
    para2: [
        { object: "Aside", text: "我们又聊了一些天。" },
        { object: "Aside", text: "乃琳老师一讲起这些姐姐们，就兴致高涨，聚精会神，讲得头头是道。" },
        { object: "Aside", text: "有的时候，我都快要相信这些人真的存在。" },
        { object: "Aside", text: "但最后，就算那时还天真的我也只是当做虚幻的故事罢了。" },
        { object: "Aside", text: "乃琳老师特别喜欢这种编故事般的美好童话，但同样我也很喜欢。" },
        { object: "Aside", text: "随后我们两就这样静静地坐着，不知道坐了多久。" },
        { object: "Eillen", text: "哎..." },
        { object: "Eillen", text: "我想起来了，你今天看起来好像长高了一些..." },
        { object: "Me", text: "..." },
        { object: "Eillen", text: "..." },
        { object: "Eillen", text: "睡着了？" },
        { object: "Aside", text: "..." },
        { object: "Aside", text: "等我醒来的时候，发现我已经在帐篷里面了。", music: "nothing" },
        { object: "Eillen", text: "好的，各位！收拾东西，准备回家！" },
        { object: "Me", text: "大早上了..." },
        { object: "Aside", text: "我随便扎了下头发，开始卷起被子。" },
        { object: "Aside", text: "可在我伸手过去之时，我的手居然一下子穿过了被子，触碰到了铺盖！", music: "question" },
        { object: "Aside", text: "但很快地，我的时候像是摸到弹簧一样被弹开了。然后，一切都又恢复到正常。" },
        { object: "Aside", text: "我的心里还是咯噔一下，仿佛触碰到了不可理解之物。" },
        { object: "Aside", text: "但那时候我的，只敢相信这只是我产生了幻觉罢了。" },
        { object: "Aside", text: "我这般安慰自己..." },
        { object: "Aside", text: "可是当我在几天之后看到天空变成了难以言语的橘黄色，" },
        { object: "Aside", text: "大地开始出现裂缝，一些事物开始莫名其妙地再也无法触碰，" },
        { object: "Aside", text: "而其他人，却丝毫感受不到。" },
        { object: "Aside", text: "我不禁开始怀疑，我的幻想是否是过于严重。" },
        { object: "Me", text: "这是怎么回事？" },
        { object: "Aside", text: "过了越久，这种奇怪的事情就变得越来越多。" },
        { object: "Aside", text: "我可以直接穿过树木，周围的人开始看不到我的存在。" },
        { object: "Aside", text: "我看着枝江这个世界正在一点一点消失、被吞噬，就好像要崩塌了一样。" },
        { object: "Aside", text: "我的惊恐、怀疑，最终终于变成了恐慌。" },
        { object: "Eillen", text: "羊驼！" },
        { object: "Ang", text: "怎么了？" },
        { object: "Eillen", text: "你不是说裂缝会修复的吗？" },
        { object: "Eillen", text: "现在它已经开始吞噬这个世界了！" },
        { object: "Ang", text: "我没说它会自动修复。" },
        { object: "Eillen", text: "你为什么不把话说清楚！" },
        { obejct: "Ang", text: "那是因为只有当它再次现身的时候，我们才有机会，乃琳。" },
        { object: "Ang", text: "穿梭装置在裂缝的驱动下终于成功启动了。" },
        { object: "Ang", text: "还记得你那些，散落在各个时空的...你曾经的朋友吗？" },
        { object: "Me", text: "老师！你知道这是怎么回事吗？" },
        { object: "Eillen", text: "你也能感受到？" },
        { object: "Me", text: "嗯！" },
        { object: "Me", text: "这到底是怎么回事，老师？" },
        { object: "Eillen", text: "...跟我来。我需要你的帮助。" },
        { object: "Aside", text: "我半信半疑地，真就跟着乃琳老师来到了她的家。" },
        { object: "Aside", text: "她的家看起来特别有年代感，就好像是一座历史遗迹一样。" },
        { object: "Eillen", text: "...现在我总算知道了。" },
        { object: "Eillen", text: "这或许就是一场考验..." },
        { object: "Eillen", text: "乃琳打开了台灯下面的密码锁，一道密室入口出现在我们俩面前！" },
        { object: "Me", text: "我们这是要干什么？" },
        { object: "Eillen", text: "来吧！不是想看到嘉然姐姐、贝拉姐姐、和向晚姐姐吗？" },
        { object: "Eillen", text: "在这个世界崩塌之前，我们这就把她们给找回来！" },
        { object: "Aside", text: "当时的我，是受到何等的触动！", music: "unexpected" },
        { object: "Aside", text: "当乃琳给我领略那些不可思议的奇幻景象后，我才意识到这些并不是乃琳自编自造的童话..." }
    ],
    para2choice: [

    ]
}
export default chapter1;
