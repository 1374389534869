<template>
  <div class="fake-over">
    <MyTalk ref="talk" class="talk" @handleTalkName="handleTalkName"/>
  </div>
</template>

<script>
import MyTalk from '../Dbace/MyTalk.vue'
export default {
  components: { MyTalk },
  mounted(){
    this.$refs.talk.changeTalk("end");
  },
  methods:{
    handleTalkName(name){
      if(name === "end"){
        setTimeout(()=>{
          this.$refs.talk.changeTalk("fakeA");
          clearTimeout();
        }, 5000);
      }
      if(name === "fakeA"){
        setTimeout(()=>{
          this.$refs.talk.changeTalk("fakeB");
          clearTimeout();
        }, 5000);
      }
      if(name === "fakeB"){
        setTimeout(()=>{
          this.$refs.talk.changeTalk("fakeC");
          clearTimeout();
        }, 10000);
      }
    }
  }

}
</script>

<style scoped>
.fake-over{
  background: white;
}
.fake-over .talk{
  margin-top: 40vh;
}
</style>
