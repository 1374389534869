<template>
  <div class="bella-egg">
    <div>星星在哪里？</div>
    <div class="star">
      <el-button icon="el-icon-star-on" @click="currentText = text"></el-button>
    </div>
    <div class="text">{{ currentText }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: "我已经迷失了，周围一片黑暗，所有的星星都已经消失不见。我失去指引，感到彷徨，我的未来失去了希望。但我内心的那颗星，依旧在闪耀着。这并不能把我打到。总有一天我会重新回到舞台，这便是我的承诺，我不会再留遗憾。",
      currentText: "",
    };
  },
};
</script>

<style scoped>
.bella-egg {
  color: #fff;
  text-align: center;
  height: 100vh;
  font-family: FZYaoti;
}
.bella-egg button {
  border: transparent;
  color: transparent;
}
.bella-egg button:hover {
  background: transparent;
  color: #fff;
}
.star {
  position: fixed;
  top: 93%;
  left: 83%;
}
</style>
