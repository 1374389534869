export const character = [{
        name: "Diana",
        imgStatus: false,
        text: "嘉然",
        icon: "el-icon-sugar",
        title: "糖果梦",
        titleIndex: "-1-",
        answer: "鞋",
        answerStatus: false,
        currentAnswer: ""
    },
    {
        name: "Bella",
        imgStatus: false,
        text: "贝拉",
        icon: "el-icon-star-on",
        title: "北极星",
        titleIndex: "-2-",
        answer: "2021716",
        answerStatus: false,
        currentAnswer: ""
    },
    {
        name: "Ava",
        imgStatus: false,
        text: "向晚",
        icon: "el-icon-heavy-rain",
        title: "不雨城",
        titleIndex: "-3-",
        answer: "塔落西",
        answerStatus: false,
        currentAnswer: ""
    },
    {
        name: "Croal",
        imgStatus: false,
        text: "珈乐",
        icon: "el-icon-mic",
        title: "群流",
        titleIndex: "-4-",
        answer: "风情",
        answerStatus: false,
        currentAnswer: ""
    },
    {
        name: "Eillen",
        imgStatus: false,
        text: "乃琳",
        icon: "el-icon-table-lamp",
        title: "集结",
        titleIndex: "-5-",
        answer: "迷迭香",
        answerStatus: false,
        currentAnswer: ""
    },
]
export default character;
