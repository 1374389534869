export const chapter3 = {
    para1: [
        { object: "Me", text: "所以...接下来的事情呢？", music: "reverse" },
        { object: "S", text: "接下来...那便是我那个美妙而奇怪的梦。" },
        { object: "S", text: "我梦见了她...尽管我只看到了她几分钟。" },
        { object: "S", text: "可她的形象在梦中是如此的鲜活而真实，连衣服上的纽扣我都看的清清楚楚。" },
        { object: "S", text: "宛如那里才是真实的世界一般。" },
        { object: "S", text: "她对我说了很多东西，那些都是我从前完全不知道的，就像一场预知。" },
        { object: "Me", text: "她对你说了什么？" },
        { object: "S", text: "呵呵...说来也有意思..." },
    ],
    para1choice: [
        { object: "S", choice: "名字", text: "她告诉了我她的名字。" },
        { object: "S", choice: "理想", text: "她告诉了我她的理想。" },
        { object: "S", choice: "兴趣", text: "她告诉了我她的爱好。" }
    ],
    para1AfterChoice0: [
        { object: "Croal", text: "我叫珈乐。很高兴认识你。" },
        { object: "S", text: "我也很高兴认识你。" },
        { object: "S", text: "额..." },
        { object: "S", text: "请问是哪个珈？" },
        { object: "Croal", text: "珈，王，力，口！" },
        { object: "S", text: "真是个复杂名。" },
        { object: "Croal", text: "没关系。以后叫我乐就好了。" },
        { object: "S", text: "好的，乐。" },
        { object: "S", text: "不得不说，我已经被你的歌声完全迷住了。" },
        { object: "S", text: "请告诉我，你一般都在哪里表演，好吗？" },
        { object: "S", text: "非常感谢！" },
        { object: "Croal", text: "哈哈！当然！" },
        { object: "S", text: "她一会像个酷盖，一会又像个孩子。" },
        { object: "S", text: "却总是掩盖不了，她那烧...风情的韵味。" },
        { object: "S", text: "她将她最近的一些演出地点告诉了我，我拿笔好好的记录下来 。" },
        { object: "S", text: "有一些地方我挺熟悉，可有一些地方我却完全没听过。" },
        { object: "S", text: "随后，她又和我聊了聊她的理想，还有她的爱好。" },
    ],
    para1AfterChoice1: [
        { object: "Croal", text: "嗨！你好！" },
        { object: "Croal", text: "我的表演，让你感觉怎么样？" },
        { object: "S", text: "啊！是你，是你啊！" },
        { object: "S", text: "我不知道为什么扑通一下就跪下了，连连喊到：" },
        { object: "S", text: "啊！是你啊！" },
        { object: "Croal", text: "你也太夸张了。跪下可不是件好事，快起来吧。" },
        { object: "S", text: "对不起，我有点太激动了..." },
        { object: "S", text: "我今晚就听了一点你的歌，就完全被你击倒了！" },
        { object: "S", text: "我已经无法再忘却你了..." },
        { object: "Croal", text: "真的吗？我很感动..." },
        { object: "Croal", text: "我希望，我能把我的歌声传递给更多的人..." },
        { object: "S", text: "放心吧！踏踏实实干，你一定会越来越出名的！" },
        { object: "S", text: "金子总是会发光的！" },
        { object: "Croal", text: "但愿...但愿如此吧..." },
        { object: "S", text: "她的眼神里冷酷而藏捏着一份娇柔，身体中孕育着一种风情而妩媚。" },
        { object: "S", text: "越看着她，我就越是难以忘记..." },
        { object: "S", text: "后面，我们还讨论了一些其他事情，她还告诉了我她的名字，" },
        { object: "S", text: "她叫珈乐。" }
    ],
    para1AfterChoice2: [
        { object: "Croal", text: "嗨！你好，我叫珈乐！" },
        { object: "S", text: "啊！珈乐，是你！" },
        { object: "S", text: "虽然我只看过你的一次表演，可却俨然成为你的忠实粉丝！" },
        { object: "S", text: "我感觉美妙极了！你的歌声让我如痴如醉！" },
        { object: "Croal", text: "真的吗？...能给你带来快乐，一直都是我想做的。" },
        { object: "Croal", text: "从很小的时候，我便站在舞台上歌唱。" },
        { object: "Croal", text: "每当我拿起话筒的那一瞬间，我感觉我已经变得焕然一新了。" },
        { object: "Croal", text: "在舞台中忘却了现实的自我，仿佛寻找到了真正的自己。" },
        { object: "Croal", text: "我的意识在灵感中摆动，我的身体在节奏中有了韵律。" },
        { object: "Croal", text: "这便是我想要的，能够沉浸在美好的歌声之中。" },
        { object: "S", text: "这也便是我们想要的！" },
        { object: "S", text: "之后，我们又谈了一些其他的事情。" },
        { object: "S", text: "比如她和我说了她的理想，等等。" },
        { object: "S", text: "我得知她最近演出的地点，以及她的称呼。" },
        { object: "S", text: "她叫珈乐。" },
    ],
    para2: [
        { object: "S", text: "她还给我看了一张照片。" },
        { object: "S", text: "那张照片里，五个女孩的笑容仿佛要跳出整个画面。" },
        { object: "S", text: "而其中的一个，便是她。" },
        { object: "Croal", text: "哎！我真想念那个时候啊！" },
        { object: "Croal", text: "真的要好好珍惜时光啊！" },
        { object: "S", text: "然后...然后我就睁开了眼睛。" },
        { object: "S", text: "发现天以及十分的明亮，太阳以及悬挂在半空中，没有昨日那一点的阴郁。" },
        { object: "S", text: "我发现我已经冷静了许多。" },
        { object: "S", text: "我本该就这样放下，可是我却还是去了梦中所说的那些演出地点。" },
        { object: "S", text: "结果让我震惊的是，她真的就出现在了那些地方，一点位置也不差！" },
        { object: "S", text: "突然间，我产生了强烈的错觉，觉得我的追随便是那命中注定。" },
        { object: "S", text: "就算到现在，我也深受其影响。" },
        { object: "S", text: "从此以后，我便有时间就去看珈乐的演出，愈来愈沉溺于她的歌声之中..." },
        { object: "S", text: "我的大脑即理智又狂热，她的任何一场演出都能让我欢呼跃雀，让我无法摆脱。" },
        { object: "Me", text: "从目前看来，似乎还是很顺利的。", music: "nothing" },
        { object: "S", text: "是的，这样子的状况持续了一段时间。" },
        { object: "S", text: "直到从某一天开始..." }
    ],
    para2choice: [

    ]
}
export default chapter3;
