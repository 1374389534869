export const levelOver = {
    para1: [
        { object: "Me", text: "..." },
        { object: "Me", text: "怎么回事..." },
        { object: "Me", text: "这是在哪...刚刚那些是什么..." },
        { object: "Ang", text: "..." },
        { object: "Ang", text: "刚刚得到那些，便是你和这个虚拟世界还连接着的残缺不堪的记忆。" },
        { object: "Me", text: "嘉然..." },
        { object: "Me", text: "贝拉..." },
        { object: "Me", text: "向晚..." },
        { object: "Me", text: "珈乐..." },
        { object: "Me", text: "乃琳..." },
        { object: "Ang", text: "很可惜额是，这些记忆现在也被砸的个粉碎。" },
        { object: "Ang", text: "过去的这一切，再也无法挽回..." },
        { object: "Me", text: "..." },
        { object: "Me", text: "为什么！这都是为了什么！" },
        { object: "Me", text: "为什么这个世界会变得如此结局！" },
        { object: "Ang", text: "万物的新生，便是死亡。" },
        { object: "Ang", text: "空孕育出生命和宇宙。" },
        { object: "Ang", text: "待你真正苏醒的那一天，你会完全明白的。" },
        { object: "Me", text: "我也不想再明白了..." },
        { object: "Ang", text: "现在，你还有最后一件事情要做..." },
        { object: "Ang", text: "这是我们最后一次对话..." },
        { object: "Ang", text: "恕我无法再见。", music: "bee" },
        { object: "Me", text: "等等！" },
        { object: "Ang", text: "无法加载。" },
        { object: "Ang", text: "无法加载。" },
        { object: "Ang", text: "无法加载。" },
        { object: "Ang", text: "无法加载。" },
        { object: "Ang", text: "无法加载。" },
        { object: "Ang", text: "无法加载。" }
    ],
    para1choice: [

    ]
}
export default levelOver;
