export const chapter5 = {
    para1: [
        { object: "Mouse", text: "吱吱...吱吱..." },
        { object: "Mouse", text: "吱吱...吱吱..." },
        { object: "Me", text: " 好的...就在这里，各位都别乱跑了..." },
        { object: "Aside", text: "我将我的一些同伴带到了一个废弃的小屋中。" },
        { object: "Aside", text: "这个废弃的小屋经过我的嘉然小姐的打理，已经成为了专用的画室。" },
        { object: "Aside", text: "我的同伴总是这样，来到陌生的地方，便是这样不安地游动着。" },
        { object: "Aside", text: "事实上，以前的我也是这样。" },
        { object: "Aside", text: "我花费九牛二虎之力，才说服他们去河边洗了个澡，至少还算是比较干净。" },
        { object: "Me", text: "嗯...应该要来了..." },
        { object: "Mouse", text: "吱吱 ？吱吱？" },
        { object: "Me", text: "害，你们别紧张。不会有什么事的，我放心。" },
        { object: "Diana", text: "嗨！各位！" },
        { object: "Mouse", text: "吱吱！吱吱！" },
        { object: "Aside", text: "现场一下子骚乱起来，我的同伴们开始四处乱窜，简直就像我当初逃跑似的一模一样。" },
        { object: "Aside", text: "这确实是果不其然。" },
        { object: "Mouse", text: "吱吱！吱吱！" },
        { object: "Diana", text: "不要慌张，各位！会受伤的！" },
        { object: "Me", text: "各位！各位！..." },
        { object: "Me", text: "害，还是要用点办法才行！" }
    ],
    para1choice: [
        { object: "Me", choice: "食物", text: "给它们一点食物，让它们冷静下来！" },
        { object: "Diana", choice: "卡祖笛", text: "我想到一个办法，不知道行不行。" }
    ],
    para1AfterChoice0: [
        { object: "Diana", text: "吃的是吗...我口袋里还有一点..." },
        { object: "Aside", text: "说罢，她便放下纸和颜料，伸进口袋里面，撒下来零零碎碎的鼠片。" },
        { object: "Aside", text: "果然大家都像病毒似的席卷而来聚成一团，哄抢着那仅有的一点美味。" },
        { object: "Me", text: "我超...给我留点啊..." },
        { object: "Aside", text: "见效了。" },
        { object: "Mouse", text: "吱吱...吱吱..." },
        { object: "Me", text: "那是当然...接下来我要向你们介绍一个有意思的东西..." },
        { object: "Aside", text: "同伴们终于肯耐心听我讲话了。" }
    ],
    para1AfterChoice1: [
        { object: "Me", text: "什么办法？" },
        { object: "Aside", text: "说罢，她便放下了纸和颜料，从口袋里拿出了一个笛子。" },
        { object: "Aside", text: "我还以为会吹出什么美妙的声音，", music: "kazuMusic" },
        { object: "Aside", text: "结果当那笛子响起的时候，崩坏的音律就像放屁一样噗嗤噗嗤个不停。" },
        { object: "Aside", text: "就连我也觉得糟糕至极，难以忍受。" },
        { object: "Aside", text: "可是我的同伴却恰恰相反，它们开始冷静下来，不再乱跑了。" },
        { object: "Mouse", text: "吱吱！吱吱！" },
        { object: "Aside", text: "我听出它们在欢呼，它们很喜欢这个音乐。" },
        { object: "Me", text: "我佛了。😓" },
        { object: "Me", text: "有一点没说错，看来我真的是变了。" }
    ],
    para2: [
        { object: "Aside", text: "现场终于完全安静了下来。" },
        { object: "Aside", text: "我跑到嘉然小姐的肩膀上，像大家说道：" },
        { object: "Aside", text: "各位，今天我和嘉然小姐...也就是这位棕色头发的少女...向大家打个招呼吧，小姐！" },
        { object: "Diana", text: "嗨！各位！我是嘉然！身高一米八！" },
        { object: "Mouse", text: "吱吱！吱吱！" },
        { object: "Me", text: "在此，我像大家隆重介绍一门新玩意——" },
        { object: "Me", text: "绘画！" },
        { object: "Mouse", text: "...吱吱？..." },
        { object: "Aside", text: "下面开始沸腾起来，都开始议论纷纷。" },
        { object: "Diana", text: "等一下你们就知道这是什么了，嘻嘻。" },
        { object: "Aside", text: "她将那一卷纸平铺开，面积足足有整个鼠群这么大。" },
        { object: "Diana", text: "各位，请看！" },
        { object: "Aside", text: "只见她将手上沾满了颜料，只是在纸上画上几笔，就出现了一个同伴的模样！" },
        { object: "Mouse", text: "吱吱！吱吱！" },
        { object: "Me", text: "没错！这就是色彩掌控的艺术！", music: "dreamEnd" },
        { object: "Me", text: "我们要一起参与进来，共同完成这一伟大的画作！" },
        { object: "Aside", text: "同伴们一下子兴奋了起来。" },
        { object: "Aside", text: "它们马上身上沾满了各种各样的颜料，在空白的想象迷宫里，" },
        { object: "Aside", text: "嬉戏打闹，追逐，又或是沉思，细致地描绘着..." },
        { object: "Aside", text: "有的同伴，靠着记忆描绘出了许多漂亮的糖果。" },
        { object: "Aside", text: "它们堆放在画布的中央，似乎正在散发着甜蜜的气息。" },
        { object: "Aside", text: "有的则画出了想象中的自己，" },
        { object: "Aside", text: "挂着天使的光环，在天空中自由地飞翔着；" },
        { object: "Aside", text: "还有的加上了一些长方形的物体，" },
        { object: "Aside", text: "就像天堂中那一些屹立不倒的柱子一样，熠熠生辉。" },
        { object: "Aside", text: "我的同伴们，无一不受饥饿、寒冷、疾病的困扰。" },
        { object: "Aside", text: "自从出生以来，这似乎就是我们永恒的宿命。" },
        { object: "Aside", text: "可是现在，它们却如此地快乐，忘却了那一切痛苦。" },
        { object: "Aside", text: "我从没见过我们有如此欢快的景象。" },
        { object: "Aside", text: "很快，我们就已经画地精疲力尽了。" },
        { object: "Aside", text: "我的同伴们躺在画布上，恣意而轻松地休息着。" },
        { object: "Aside", text: "我爬上桌子，站在嘉然小姐的旁边，望着那副画。" },
        { object: "Aside", text: "突然发现，这样的景象，不就是我当初梦中的模样？" },
        { object: "Aside", text: "亦真亦幻，宛如一场幻觉，一盆雨水就可以被浇灭。" },
        { object: "Aside", text: "可是却永不忘却，就像夜半的小夜曲一样，让我们沉醉..." },
        { object: "Me", text: "感谢你，嘉然小姐。" },
        { object: "Me", text: "你让我们明白我们虽然天生堕落，却并不是止步于此。" },
        { object: "Diana", text: "哈哈..." },
        { object: "Aside", text: "她又甜甜地笑了，就像清晨的阳光一样美。" },
        { object: "Diana", text: "春天总是会到来的。" },
        { object: "Aside", text: "..." },
    ],
    para2choice: [

    ]
}
export default chapter5
