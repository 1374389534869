export const Me = {
    para1: [
        { object: "Aside", text: "嘀嘀...嘀嘀...", music: "didi!" },
        { object: "Me", text: "..." },
        { object: "Aside", text: "嘀嘀...嘀嘀..." },
        { object: "Me", text: "哎，还是起来吧。" },
        { object: "Aside", text: "我关掉了闹钟，起了床，" },
        { object: "Aside", text: "开始了一天的生活，几乎日日便是如此。" },
        { object: "Aside", text: "不过今天，似乎情况有些特殊..." },
        { object: "Aside", text: "...", music: "newBegin" },
        { object: "Aside", text: "我平常也会看看些虚拟偶主播什么的..." },
        { object: "Aside", text: "这个东西很新颖也很小众。" },
        { object: "Aside", text: "但是作为闲暇时间消遣的话，是很不错的。" },
        { object: "Aside", text: "因为我经常看一些虚拟主播，所以b站也经常给我推些虚拟主播相关的内容。" },
        { object: "Aside", text: "今天，它给我推了一个挺与众不同的视频。" },
        { object: "Aside", text: "说是什么乐华年度企划虚拟偶像团体A-SOUL。" },
        { object: "Aside", text: "我点开来了，里面是五个女孩。" },
        { object: "Aside", text: "从身型上看，" },
        { object: "Aside", text: "一个看起来娇小，头上扎着一副大大的蝴蝶结；" },
        { object: "Aside", text: "一个看起来充满了元气和活力，是这个队的队长；" },
        { object: "Aside", text: "一个有着一副夸张的双马尾，占据舞台C位。" },
        { object: "Aside", text: "一个身姿曼妙，看起来十分冷酷；" },
        { object: "Aside", text: "一个穿着漂亮的服饰，尽显成熟气息。" },
        { object: "Aside", text: "不过单纯从这个视频上来看，并不能看出太多的信息。" },
        { object: "Aside", text: "就算是这样，这个视频也还是骂声一片：" },
        { object: "Comment", text: "资本的大手来了，大家快跑！" },
        { object: "Comment", text: "我想到v圈会死，但没想到会是今天..." },
        { object: "Comment", text: "差不多得了..." },
        { object: "Aside", text: "看到这些，我并没有说什么。" },
        { object: "Aside", text: "一个企划而已，和我有什么太大关系？" },
        { object: "Aside", text: "不过，我还是偷偷关注着它。" },
        { object: "Aside", text: "我怀着看乐子的心态，想看看这个企划接下来是何发展和后果..." },
        { object: "Aside", text: "..." },
        { object: "Aside", text: "她们的团播开始了：" },
        { object: "Diana", text: "hello！大家好！这里是嘉然！" },
        { object: "Aside", text: "那个可爱的小矮子孕妇装萝莉开始进行自我介绍了：" },
        { object: "Diana", text: "关注嘉然！顿顿解馋！" },
        { object: "Diana", text: "我可是有一米八！" },
        { object: "Ava", text: "爱娃哇哎哇！这里是向晚！" },
        { object: "Aside", text: "一个双马尾的少女开始进行自我介绍了，" },
        { object: "Aside", text: "虽然她的服装和形象都设计得像是小恶魔似的，可是给她套上却充满了十足的搞笑气息。" },
        { object: "Ava", text: "作为gamer，今晚也要和我们一起愉快的玩耍啊！" },
        { object: "Bella", text: "hello，这里是贝拉！" },
        { object: "Aside", text: "那个黑头发的活力女孩开始介绍了。" },
        { object: "Bella", text: "你们也可以叫我的英文名，Bel-------------------------------------lla！" },
        { object: "Aside", text: "她把音拖得这么长，以至于全场都跟她学了起来。" },
        { object: "Croal", text: "爱上一个人，不管是不是TA。" },
        { object: "Aside", text: "随后是那个看似酷盖的的介绍，却也分外的柔情：" },
        { object: "Croal", text: "hello！这里是Vocal担当珈乐！" },
        { object: "Eillen", text: "嗨，大家好！这里是乃琳艾琳哦！" },
        { object: "Aside", text: "最后是那个黄头发的异国少女，看起来颇为成熟端庄。" },
        { object: "Eillen", text: "今后也要和大家一同前行哦！" },
        { object: "Asoul", text: "好耶！" },
        { object: "Eillen", text: "我们再来说一遍吧！" },
        { object: "Diana", text: "好！" },
        { object: "Croal", text: "好！" },
        { object: "Ava", text: "来吧！" },
        { object: "Bella", text: "好！" },
        { object: "Asoul", text: "大家好，我们是A-SOUL！" },
        { object: "Aside", text: "她们开始进行直播了。" },
        { object: "Aside", text: "她们的风评虽然变得越来越差，却也变得越来越好。" },
        { object: "Aside", text: "有越来越多的人涌了进来，积极地参与讨论。" },
        { object: "Aside", text: "她们的直播越来越多。" },
        { object: "Aside", text: "她们的节目越来越好，也越来越复杂。" },
        { object: "Aside", text: "她们的表面覆盖着那副没有灵魂的面容，却也和真正的灵魂链接起来。" },
        { object: "Aside", text: "不知道为什么，看着她们一路下来。" },
        { object: "Aside", text: "看着她们的那副皮囊，一遍遍地升级、修复。" },
        { object: "Aside", text: "我越来越有一种熟悉的感觉。" },
        { object: "Aside", text: "并不是因为经常看她们直播，而产生的那股熟悉的感觉。" },
        { object: "Aside", text: "而是一种很久远的感觉，似乎在很长一段时间的某一时刻，我便已经遇见..." },
        { object: "Aside", text: "...", music: "nothing" },
        { object: "Audio", text: "A-SOUL自从2020年12月出道以来，已经过去大半个年头。" },
        { object: "Audio", text: "从开始第一场直播，到第一次团曲、单曲、再到第一次线下活动、线下表演，" },
        { object: "Audio", text: "A-SOUL现在作为国内最为成功的虚拟偶像团体," },
        { object: "Audio", text: "虽然经历了许多风波，但也获得了巨大的成就。" },
        { object: "Audio", text: "作为一路看下去的你，" },
        { object: "Audio", text: "A-SOUL中的哪一位最能俘获你的心呢？", music: "trueEnd" }
    ],
    para1choice: [
        { object: "Me", choice: "全员", text: "当然是全员。" },
        { object: "Me", choice: "阿草", text: "当然是阿草。" },
        { object: "Me", choice: "向晚", text: "当然是向晚。" },
        { object: "Me", choice: "嘉然", text: "当然是嘉然。" },
        { object: "Me", choice: "乃琳", text: "当然是乃琳。" },
        { object: "Me", choice: "贝拉", text: "当然是贝拉。" },
        { object: "Me", choice: "珈乐", text: "当然是珈乐。" },
    ],
    para1AfterChoice0: [
        { object: "Me", text: "A-SOUL的每个人，都是独一无二的。" },
        { object: "Me", text: "嘉然的可爱，" },
        { object: "Me", text: "向晚的治愈，" },
        { object: "Me", text: "贝拉的积极，" },
        { object: "Me", text: "珈乐的柔情，" },
        { object: "Me", text: "以及乃琳的成熟" },
        { object: "Me", text: "构成了像A-SOUL这般闪耀般的存在。" },
        { object: "Me", text: "少了每一个人，这个团体都是黯淡的。" },
        { object: "Me", text: "就如同少了一颗星星，缺失了一块拼图，" },
        { object: "Me", text: "一切都将变得破碎。" },
        { object: "Me", text: "真是因为一切的巧合，将她们巧妙地聚集在一起，" },
        { object: "Me", text: "构成了巧妙的平衡，灵魂上的贴切，" },
        { object: "Me", text: "才能俘获我的心，才能让我觉得，" },
        { object: "Me", text: "A-SOUL的每一位，都是值得陪伴走下去的！" }
    ],
    para1AfterChoice1: [
        { object: "Me", text: "啊！阿草！" },
        { object: "Me", text: "谁会不喜欢这么个可爱的生物呢！" },
        { object: "Me", text: "从它刚从嘉然的视频出来那会，我的心就已经被它夺走了..." },
        { object: "Me", text: "瞧它那可爱的步伐，走起路来一扭一扭的，" },
        { object: "Me", text: "它那漂亮的外貌，尤其是那白嫩的皮肤..." },
        { object: "Me", text: "啊，我不是说我是个福瑞控这样子的..." },
        { object: "Me", text: "但是那白白嫩嫩的银屯，谁会不喜欢呢..." },
        { object: "Me", text: "我一把抓住...啊啊，抱歉。" },
        { object: "Me", text: "说岔了，其实只是因为阿草很可爱，" },
        { object: "Me", text: "然后刚好就是巧合而已啦！" }
    ],
    para1AfterChoice2: [
        { object: "Me", text: "初次见到向晚的时候，我以为她是腹黑的小恶魔。" },
        { object: "Me", text: "不过很快，她就展现出了看似真正的那一面。" },
        { object: "Me", text: "她的声音一出来就让我很绷不住。" },
        { object: "Me", text: "她的动作一做出来就分外有意思，就像是一个搞笑艺人一样。" },
        { object: "Me", text: "还会偶尔口胡一下，非常地有节目效果。" },
        { object: "Me", text: "不过她的内心仍旧包含着那一份纯真，因此总是屡屡中招。" },
        { object: "Me", text: "可更凸显出了她的那般小孩子一样的可爱。" },
        { object: "Me", text: "让人感觉到一股纯净的美好。" },
        { object: "Me", text: "不仅如此，她还是一个创作者。" },
        { object: "Me", text: "喜欢拿着吉他，唱着暖暖的歌，创作各种各样的歌词。" },
        { object: "Me", text: "早餐吃着泡面，研究各种各样奇怪的料理，" },
        { object: "Me", text: "有时候让人忍俊不禁，有时候却又让人感受到平静。" },
        { object: "Me", text: "能遇见向晚，真是我最大的巧合！" }
    ],
    para1AfterChoice3: [
        { object: "Me", text: "刚见到嘉然的时候，以为嘉然只是个普普通通的卖萌萝莉。" },
        { object: "Me", text: "可是她却在刚出道就遭受了如此多的攻击和谩骂，" },
        { object: "Me", text: "而刚开始看她的人，也是一些钱少话多的看热闹的人。" },
        { object: "Me", text: "但她用自己的真心演出，换来了许多人的理解和愧疚。" },
        { object: "Me", text: "一场宅舞二十连，俘获了多少人的心。" },
        { object: "Me", text: "嘉然是个事业心很强的人，她不断努力着，" },
        { object: "Me", text: "她的热度蹭蹭蹭的上涨，达到了傲人的成绩。" },
        { object: "Me", text: "看着她被百般谩骂再到万众瞩目，我真的...哭死。😭" },
        { object: "Me", text: "嘉然还很喜欢画画。" },
        { object: "Me", text: "她总是通过一些简单的画面，来表达最简单的心思。" },
        { object: "Me", text: "她玩弄着观众的心，是个真真正正的小恶魔，" },
        { object: "Me", text: "所有人都知道真相，可是却心甘情愿，" },
        { object: "Me", text: "这是一种多么大的魔力！" },
        { object: "Me", text: "遇见嘉然，真是一种神奇的巧合！" }
    ],
    para1AfterChoice4: [
        { object: "Me", text: "啊！乃琳。" },
        { object: "Me", text: "我早就是乃淇琳了啊！" },
        { object: "Me", text: "一开始她讲希腊神话，一股高高在上的感觉，" },
        { object: "Me", text: "确实有一种Queen的架势。" },
        { object: "Me", text: "还喜欢整天【你知道吗】这样子的反问句来问我们，" },
        { object: "Me", text: "实际上她早就知道我们并不知道。" },
        { object: "Me", text: "不过后来，我们才发现她也有可爱和软萌的一面，" },
        { object: "Me", text: "她喜欢聊家常，喜欢和我们打趣。" },
        { object: "Me", text: "最重要的是她太懂了。" },
        { object: "Me", text: "她总是三言两语就迷得观众团团转，几个动作就能让大家神魂颠倒。" },
        { object: "Me", text: "这简直是太好...太可怕了！" },
        { object: "Me", text: "她总是能坐镇指挥。" },
        { object: "Me", text: "在直播出事故时，她总能临危不乱，及时找到补救的办法。" },
        { object: "Me", text: "深刻地把握整场直播的节奏，不愧为MC控场担当！" },
        { object: "Me", text: "遇见她，真是一种天赐的巧合！" }
    ],
    para1AfterChoice5: [
        { object: "Me", text: "贝拉是一个看起来很有青春活力的邻家少女。" },
        { object: "Me", text: "不过她似乎不太擅长用复杂的思维看待问题，一切的解决方案都是如此的简单和直白。" },
        { object: "Me", text: "她美丽的外表下总是隐藏不住一股憨憨的神态。" },
        { object: "Me", text: "在平常中，她看起来天真浪漫，没有什么戒备心，也不太会去思考。" },
        { object: "Me", text: "但一舞蹈起来，她就如同天上的仙女一般，降临在这个世界！" },
        { object: "Me", text: "她是一个折了半个翅膀的天使，曾差点因为如此而止步不前。" },
        { object: "Me", text: "而如今，她带着那原本残缺的身体，却也可以自由的翱翔在天空之上！" },
        { object: "Me", text: "她总是一片真心，待人真诚，让人感觉到那一股阳光。" },
        { object: "Me", text: "那股催人奋进的希望，那种想要陪她去更大的舞台的冲动。" },
        { object: "Me", text: "这一切，皆在不言不语之中。" },
        { object: "Me", text: "能遇见她，真是我此生之幸运和巧合！" }
    ],
    para1AfterChoice6: [
        { object: "Me", text: "珈乐是一个神秘的人。" },
        { object: "Me", text: "从开头的直播训练，再到上半年的长期请假，再加上运营号没有产出，" },
        { object: "Me", text: "珈乐出场的时间远比其他四个人少。" },
        { object: "Me", text: "但这也掩盖不了她自身能力的光芒。" },
        { object: "Me", text: "每次一听到她唱歌，便感觉是海盗被塞壬勾走了魂一样，" },
        { object: "Me", text: "一下子就被迷住了，仿佛进入了无边无际的幻想世界。" },
        { object: "Me", text: "以至于结束了都还在不断回味。" },
        { object: "Me", text: "她的舞也跳的好极了。" },
        { object: "Me", text: "那力道，那感觉，简直让所有人都看呆了。" },
        { object: "Me", text: "她的表演，总是那般经验，让人无法忘却。" },
        { object: "Me", text: "她还是一个内心温柔的酷盖，让人感觉坠入了甜蜜乡那般不能挣脱。" },
        { object: "Me", text: "所以...珈乐你带我走吧！！！😭😭😭" },
        { object: "Me", text: "遇见你，真是我人生中最大的巧合！" }
    ],
    para2: [
        { object: "Aside", text: "..." },
        { object: "Aside", text: "巧合..." },
        { object: "Aside", text: "真的只是巧合吗？" },
        { object: "Me", text: "..." },
        { object: "Me", text: "..." },
        { object: "Me", text: "..." },
        { object: "Me", text: "不对！" },
        { object: "Aside", text: "突然间，我看到嘉然还在那个风雪交加的晚上，与她的小徒弟进行专心地创作：" },
        { object: "Diana", text: "这样子如何?" },
        { object: "Me", text: "几乎完美，嘉然小姐！" },
        { object: "Aside", text: "突然间，我看到贝拉仍旧在巨大的舞台上表演，周围都在为她欢呼鼓掌：" },
        { object: "Bella", text: "感谢大家！" },
        { object: "Me", text: "太棒啦！" },
        { object: "Me", text: "贝拉，我们的骄傲！" },
        { object: "Aside", text: "突然间，我看到向晚也还捧着她那吉他，在永不下雨的城市里面展现着她的冷笑话技术：" },
        { object: "Ava", text: "铛铛!" },
        { object: "Me", text: "哈哈，下雨辣！" },
        { object: "Aside", text: "突然间，我看到珈乐与他共同坐在那张椅子上，观望着前方永不停息的群流：" },
        { object: "Croal", text: "想我了吗？" },
        { object: "Me", text: "...当然😭😭😭" },
        { object: "Aside", text: "突然间，我看到乃琳正和一个小女孩进行着时空穿梭，为阻止世界的崩塌而争分夺秒：" },
        { object: "Eillen", text: "来，马上就要到了！" },
        { object: "Me", text: "啊...太神奇了！" },
        { object: "Aside", text: "那些曾经的画面，那些过去的事情，不断地从我脑海中闪现。" },
        { object: "Aside", text: "原本完全泯灭的记忆却又重新燃烧起来，那段虚拟世界的死亡之路再次浮现在我的眼前。" },
        { object: "Aside", text: "我意识到，眼前的这五个女孩，她们的世界，又和新的虚拟世界有什么不同呢？" },
        { object: "Aside", text: "或许这个新的世界，也只是那永不停歇的凛冽寒冬；" },
        { object: "Aside", text: "或许我们的所有成就，也只是北极星上那终将破灭的幻像；" },
        { object: "Aside", text: "或许我们的内心，也只是如同不雨城那般无情和苦涩；" },
        { object: "Aside", text: "或许我们的等待，也只是不断地望着群流，遥遥无期。" },
        { object: "Aside", text: "这个新的虚拟世界，最终也会分离崩析..." },
        { object: "Aside", text: "只剩下寥寥无几的人, 进行着一场根本无法成功的挽救。" },
        { object: "Aside", text: "最后，也只能亲手砸掉自己的最后一份记忆，永远地离去..." },
        { object: "Aside", text: "看起来，这一切果真如此。" },
        { object: "Aside", text: "可是，我们是否也忽略掉了，" },
        { object: "Aside", text: "凛冽的寒冬背后，便是那片温暖的梦想与生机；" },
        { object: "Aside", text: "幻像的背后，是真实的世界映射的产物；" },
        { object: "Aside", text: "干涸的心，最终也会被温柔的雨水所浇灌；" },
        { object: "Aside", text: "遥遥无期的下一刻，便是再次遇见的美好与希望；" },
        { object: "Aside", text: "世界总有它的生命周期。待到旧的世界死亡之后，新的世界便会诞生，" },
        { object: "Aside", text: "一切的美丽仍然会存在，" },
        { object: "Aside", text: "又是一个有关梦想、希望、治愈的等待的故事。" },
        { object: "Aside", text: "A-SOUL的未来...未来总是神秘而漫长的。" },
        { object: "Aside", text: "但未来的路，便是由我们，共同走下去。" },
        { object: "Aside", text: "未来的故事，便是由我们，共同来书写。" },

    ],
    para2choice: [

    ]
}
export default Me;
