export const chapter3 = {
    para1: [
        { object: "Aside", text: "这延绵的小雨，下了好几场。", music: "hill" },
        { object: "Aside ", text: "夜晚山边的石头终于不是一片滚烫了。" },
        { object: "Aside", text: "我大大方方地做了上去，眺望着不远处的城市。" },
        { object: "Aside", text: "城市，五彩斑斓着，却又带有一丝灰暗。" },
        { object: "Aside", text: "阴暗的角落却也很暴躁，明亮的地方也完全不能冷静。" },
        { object: "Aside", text: "这里的空气中总是充满着浮躁的火药味，不过现在已被冲淡了不少。" },
        { object: "Ava", text: "啊！这里怎么有人啊！" },
        { object: "Aside", text: "我回头一看，那个吉他女孩站在我背后的山坡上，她的尖叫差点把我的耳朵给震聋了。" }
    ],
    para1choice: [
        { object: "Me", choice: "当然有人", text: "这里当然有人，小姐。" },
        { objct: "Me", choice: "我认得你", text: "我认得你，你就是那个讲冷笑话小品的女孩！" }
    ],
    para1AfterChoice0: [
        { object: "Ava", text: "虽然你很有礼貌，但是请叫我向晚，谢谢。" },
        { object: "Me", text: "哪个向晚？" },
        { object: "Ava", text: "向晚意不适的那个。" },
        { object: "Me", text: "这个时候的向晚，展现出一种淡淡的高冷气息，和我曾经所见到的完全不同。" },
        { object: "Me", text: "好的，向晚。" },
        { object: "Me", text: "敢问这么晚了，一个人来这荒郊野岭干什么呢？" },
        { object: "Ava", text: "和你一样。" },
        { object: "Ava", text: "吹风。" },
        { object: "Me", text: "..." },
        { object: "Ava", text: "反转啦！我是来练琴的辣！" },
        { object: "Aside", text: "说罢她便不知从那里掏出来一个巨大的吉他：" },
        { object: "Ava", text: "兄弟们，开冲！" }
    ],
    para1AfterChoice1: [
        { object: "Ava", text: "哼！认得我又怎么了！" },
        { object: "Ava", text: "我还认得你呢，听说你是个脾气暴躁的坏蛋！" },
        { object: "Aside", text: "她表现出一股固执的小孩子脾气，和我曾经见到的她大为不同。" },
        { object: "Me", text: "我也是你的观众之一。" },
        { object: "Ava", text: "我当然知道！你回答过我的问题！" },
        { object: "Me", text: "我承认，我是控制不住自己的情绪..." },
        { object: "Me", text: "但是在你面前，我绝对，绝对不会这样。" },
        { object: "Me", text: "况且我觉得我已经好多了。" },
        { object: "Me", text: "敢问，小姐的名字是..." },
        { object: "Ava", text: "爱娃哇哎哇！这里是向晚！" },
        { object: "Ava", text: "哈哈，开玩笑的！没关系的了，放松点就好！" },
        { object: "Ava", text: "其实我是来练琴的。" },
    ],
    para2: [
        { object: "Ava", text: "她似乎一回从前的那副状态，变得元气又可爱了。" },
        { object: "Me", text: "原来你会弹吉他？" },
        { object: "Ava", text: "当然啊！不然我整天抱个吉他干嘛，不重啊。" },
        { object: "Ava", text: "再说，不会可以学嘛...是吧。" },
        { object: "Aside", text: "她开始准备好架势，缓缓地弹奏起来..." },
        { object: "Ava", text: "一、二、三、四..." },
        { object: "Ava", text: "二、二、三、四..." },
        { object: "Aside", text: "吉他那美妙轻松的韵律开始从那吉他中奔涌而来。" },
        { object: "Aside", text: "它慢悠悠地飘荡在空气中，似乎使整个世界的节奏都慢了下来。" },
        { object: "Aside", text: "然后又凝成一团，占据了在场所有人的内心。" },
        { object: "Aside", text: "其实就是我。" },
        { object: "Me", text: "太好了！太好了！" },
        { object: "Me", text: "我不禁鼓起了掌。" },
        { object: "Ava", text: "谢谢。" },
        { object: "Ava", text: "能有一个观众，这确确实实是一件好事。" },
        { object: "Ava", text: "当一个创作者的作品得不到任何认可时。" },
        { object: "Ava", text: "她的内心是否也在流泪？" },
        { object: "Ava", text: "哪怕只有一个观众，也可以让她继续坚持走下去。" },
        { object: "Me", text: "..." },
        { object: "Ava", text: "累了，休息一会。" },
        { object: "Aside", text: "她拍了拍地面，坐在了我的旁边。" },
        { object: "Aside", text: "随后，我又和她聊了一些其他的话题。" },
        { object: "Aside", text: "我们聊得很愉快，有说有笑的。" },
        { object: "Aside", text: "我没有想到，我这么长时间第一次愉快的聊天，是和一个前不久还素不相识的外地女孩。" },
        { object: "Ava", text: "好了，又可以开始了。" },
        { object: "Aside", text: "她又站了起来，拿上了她的吉他。" },
        { object: "Ava", text: "啊，对了。" },
        { object: "Ava", text: "你有见过水母吗？", music: "mus" },
    ],
    para2choice: [
        { object: "Me", choice: "见过", text: "水母？你是说那个漂浮在海里的透明生物？" },
        { object: "Me", choice: "没有", text: "没有。这里只有一片荒漠。" },
    ],
    para2AfterChoice0: [
        { object: "Ava", text: "是的。" }
    ],
    para2AfterChoice1: [
        { object: "Ava", text: "水母是一种漂浮在海里的透明生物。" }
    ],
    para3: [
        { object: "Ava", text: "它没有自己的动力系统，只能在海里随波逐流。" },
        { object: "Ava", text: "海浪去哪里，它也就只能去哪里。" },
        { object: "Ava", text: "但或许，它也有属于自己的那份小小的憧憬，有着属于它自己的一片天地，那一片自由..." },
        { object: "Ava", text: "如果我说，我能让水母在这里出现，你相信吗？" },
        { object: "Me", text: "这...你在开玩笑吧..." },
        { object: "Me", text: "既然是海中的生物，那怎么可能..." },
        { object: "Ava", text: "哈哈，瞧好了。" },
        { object: "Ava", text: "看完之后，可要记得保密哦。" },
        { object: "Aside", text: "说罢，她弹奏起她的吉他，唱起了温柔而优美的小调：" },
        { object: "Ava", text: "请给我一束光，照亮这海底..." },
        { object: "Ava", text: "学会人间技巧，参与这游戏..." },
        { object: "Aside", text: "向晚的旁边，隐隐约约的出现了一个漂浮着的生物。" },
        { object: "Me", text: "真的是一只水母！" },
        { object: "Ava", text: "水母...水母..." },
        { object: "Ava", text: "只是普通的生物...期待委婉的保护..." },
        { object: "Aside", text: "一只，两只，三只！" },
        { object: "Aside", text: "天哪！天空中真的出现了好多水母！" },
        { object: "Aside", text: "它们虽然透明，却也发着彩虹色的光芒，自由自在地在天空中舞蹈着！" },
        { object: "Aside", text: "待向晚停下时，这些小小的生物也就一个一个，像肥皂泡一样消失了。" },
        { object: "Aside", text: "我大受震撼，直到最后也没有憋出一句话来。" },
        { object: "Aside", text: "只是呆呆地坐在那里，对刚刚的景象表示惊奇不已。" },
        { object: "Ava", text: "哈哈，怎么样！" },
        { object: "Ava", text: "我原创的！" },
        { object: "Me", text: "太...太厉害了！" },
        { object: "Me", text: "为什么不对外表演这个呢？" },
        { object: "Ava", text: "并不是所有东西都要对外展示，对外炫耀。" },
        { object: "Ava", text: "这是属于自己的一片净土，一份宝藏。" },
        { object: "Ava", text: "它能让人感受到平静，它能让人感受到美好..." },
        { object: "Ava", text: "感受到前进的力量..." },
        { object: "Aside", text: "..." },
        { object: "Aside", text: "这是一次难忘的经历。" },
        { object: "Aside", text: "我与想玩的那次遭遇，让我久久不能忘怀。" },
        { object: "Aside", text: "我望向那沉重古老的镜子，发现对面的那个人居然露出了一点笑颜。" },
        { object: "Aside", text: "我发现那居然就是我自己。" },
        { object: "Aside", text: "我似乎找回了从前的那些日子。" },
        { object: "Aside", text: "干涸的心脏，似乎也有了涓涓潺流。" },
    ],
    para3choice: [

    ]
}
export default chapter3;
