export const chapter1 = {
    para1: [
        { object: "Aside", text: "我们终于回到了地球，", music: "past" },
        { object: "Aside", text: "才发现自己已经成了往事之人。" },
        { object: "Aside", text: "行走在陌生而孤独的土地上，我们每个人都跟丢了魂似的，" },
        { object: "Aside", text: "如同一群古代的难民，艰难地行进着。" },
        { object: "Aside", text: "在不久地将来会是我们早已预料到却又无法接受的结果，" },
        { object: "Aside", text: "那个在我看来熟悉的面庞，其实是她的后人，" },
        { object: "Aside", text: "带着我们一步步拐弯抹角，引入这个迷幻世界的深处。" },
        { object: "Aside", text: "她终于停下了脚步。我抬头望去，看见一个矮小的石碑，上面驻足着一个一百四十年前变得沉寂的幽灵。" },
        { object: "Aside", text: "她仍旧在上面轻盈地舞蹈者，仿佛她的生命并未从此停歇..." },
        { object: "Me", text: "..." },
        { object: "Me", text: "..." },
        { object: "Aside", text: "地球这块小小的土地上，有这么一个差不多被人遗忘的地方，" },
        { object: "Aside", text: "在这么个毫不起眼的地方，有着这么一个热爱舞蹈的女孩。" },
        { object: "Aside", text: "她的性格活泼而直爽，气质就像正午的太阳那般耀眼。" },
        { object: "Aside", text: "她虽然并不是很出名，但是却很出众。" },
        { object: "Aside", text: "无论是芭蕾、民族舞还是爵士风，她都是样样拿手。" },
        { object: "Aside", text: "她就是这么自由，总是能在任何地方惬意地舞蹈。" },
        { object: "Aside", text: "在乡野、在草坪、在舞台、甚至是在古代的废墟中。" },
        { object: "Aside", text: "她总是这么随性，从不考虑太多，" },
        { object: "Aside", text: "就像新生的鸟儿，可没有什么囚笼。" },
        { object: "Aside", text: "总是让我们振奋，忘却那世界早已展现出来的不安。。。" },
        { object: "Aside", text: "我是贝拉的忠实粉丝。" },
        { object: "Aside", text: "实际上，自从我们开始在这里工作以来，我们小组的成员就没有一个不是了。" },
        { object: "Aside", text: "我们有时间会抽出空来溜到小镇去，去看她无与伦比的精彩表演。" },
        { object: "Aside", text: "现场人群鼎沸。虽然只是个小镇，但很多人都慕名前来观看她的表演。" },
        { object: "People", text: "麻烦让一让..." },
        { object: "People", text: "我相信会是一场精彩的表演..." },
        { object: "People", text: "来，不要拥挤啊！..." },
        { object: "Me", text: "哈，怎么说，我又给各位抢到前排票了。" },
        { object: "Me", text: "到时候可得请我吃一顿啊。" },
        { object: "Teammate", text: "真搞不懂！你是怎么做到的！" },
        { object: "Teammate", text: "你不会是和对方做了什么秘密交易吧，哈哈！" },
        { object: "Teammate", text: "不这样说我是不信的。" },
        { object: "Me", text: "哎，说什么呢！怎么你也这样！" },
        { object: "Me", text: "信不信我下次不帮你抢了，你就乖乖滚到后面看影子吧！" },
        { object: "Teammate", text: "别，哥，我给你磕头了，咚咚咚！" },
        { object: "Teammate", text: "好了好了，要开始了！" },
        { object: "Teammate", text: "要来了要来了！" },
        { object: "Aside", text: "那熟悉而激动人心的音乐响起来了。" },
        { object: "Aside", text: "每个人似乎都在屏息凝神，似乎有什么大事发生了一样。" },
        { object: "Aside", text: "现场变得十分地黑暗，就连舞台也不例外，仿佛来到了没有光线的黑洞。" },
        { object: "Aside", text: "可是没过多久，舞蹈中间突然迸发出来了亮光！" },
        { object: "Aside", text: "现场开始躁动了起来，主持人激动地向着观众喊道：" },
        { object: "Host", text: "开始倒数吧！" },
        { object: "Me", text: "10！" },
        { object: "Me", text: "9！" },
        { object: "Me", text: "8！" },
        { object: "Me", text: "7！" },
        { object: "Me", text: "6！" },
        { object: "Me", text: "5！" },
        { object: "Me", text: "4！" },
        { object: "Me", text: "3！" },
        { object: "Me", text: "2！" },
        { object: "Me", text: "1！" },
        { object: "People", text: "ohhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh！！！！！！" },
        { object: "Aside", text: "贝拉，就是她，一下子从天而降，站到了舞台的最中央！" },
        { object: "Aside", text: "台上一片欢呼跃雀，狂热的喊叫声如同滚滚的热浪扑面而来！" },
        { object: "Aside", text: "贝拉开始了。" },
        { object: "Aside", text: "她自信满满地运行着专属于自己的韵律，现场很快就鸦雀无声，全都在全神贯注地观看着她的表演。" },
        { object: "Aside", text: "她的步伐是如此地优美，她的律动是如此地充满活力。" },
        { object: "Aside", text: "在场的所有人，无论是看过的还是没看过的，无一不被她的舞姿所折服。" },
        { object: "Aside", text: "当贝拉的手缓缓一抬的时候，仿佛地球都将要失重。" },
        { object: "Aside", text: "当贝拉的脚重重得踩到地板上的时候，仿佛整个山岳都要与之晃动。" },
        { object: "Aside", text: "当贝拉轻轻一跳的时候，所有人仿佛都变成了自由飞翔的鸟儿。" },
        { object: "Aside", text: "当贝拉开始旋转的时候，全世界都要与之共同舞蹈！" },
        { object: "Aside", text: "时而像飞鸟、时而像老鹰；" },
        { object: "Aside", text: "江河的气势滚滚而来，又如星辰般光芒闪耀！" },
        { object: "Aside", text: "表演结束了，" },
        { object: "Aside", text: "大家都站了起来，跳了起来，人声鼎沸！" },
        { object: "Aside", text: "就连我也时常抑制不住情绪，大声欢呼着：" }
    ],
    para1choice: [
        { object: "Me", choice: "贝拉！又是她！", text: "芜湖！贝拉！又是她！" },
        { object: "Me", choice: "我们的希望！", text: "啊！贝拉，我们的希望！" },
        { object: "Me", choice: "她太冷静了！", text: "她太冷静了！！！这是怎么做到的！" }
    ],
    para1AfterChoice0: [
        { object: "Me", text: "她总是这么优秀！她是我们的榜样！" }
    ],
    para1AfterChoice1: [
        { object: "Me", text: "我超！" }
    ],
    para1AfterChoice2: [
        { object: "Me", text: "这到底是怎么做到的？！" },
        { object: "Me", text: "真是不可思议！" }
    ],
    para2: [
        { object: "Aside", text: "大家都为贝拉的精彩表演而欢呼。" },
        { object: "Aside", text: "我们都不虚此行，每个人的脸上都带着惊讶般的愉悦。" },
        { object: "Me", text: "太棒了，各位！" },
        { object: "Me", text: "希望这不是我最后一次能看到的表演！" },
        { object: "Teammate", text: "说什么呢，队长。可不要乌鸦嘴啊。" },
        { object: "Me", text: "奈何我语文不好，找不到更好的说法了。" },
        { object: "Teammate", text: "哎，算了，各位。早点睡觉吧。明天见。" },
        { object: "Me", text: "拜拜，各位。好梦啊..." },
        { object: "Aside", text: "..." },
        { object: "Se", text: "有空吗..." },
        { object: "Se", text: "他找你..." },
        { object: "Me", text: "哈，估计又是些鸡毛蒜皮的小事。" },
        { object: "Me", text: "来了。" }
    ],
    para2choice: [

    ]
}
export default chapter1;
