export const chapter6 = {
    para1: [
        { object: "Ang", text: "这个故事没有一个开头。" },
        { object: "Ang", text: "但似乎却已经有了一个结尾。" },
        { object: "Ang", text: "或许再纯洁的情绪，也会被那分不清是非的疯狂所吞噬。" },
        { object: "Ang", text: "正如在180年前，嘉然仍旧呆在那阴暗的牢房之中。" },
        { object: "Ang", text: "她的衣服因为潮湿而变得破烂，她的头发和面容因为缺乏营养而变得枯黄饥瘦。" },
        { object: "Ang", text: "已经没有人可以再拯救她。她的名誉一塌涂地，她的恐惧已经根植在脆弱的内心。" },
        { object: "Me", text: "我们来看你了，嘉然小姐。" },
        { object: "Me", text: "看，这是我画的画。" },
        { object: "Diana", text: "画得可真好。甚至比我更好了。" },
        { object: "Diana", text: "可惜我已经不能再画画了。" },
        { object: "Me", text: "我们愿意等你，我们愿意等你直到回来。" },
        { object: "Mouse", text: "吱吱，吱吱！" },
        { object: "Ang", text: "又或是一次无法意料的冒险，让一次期望最终腐化成一次遗憾。" },
        { object: "Ang", text: "正如140年后，那个宇航员只能呆呆地站在那个石碑面前，却做不了任何事。" },
        { object: "Me", text: "..." },
        { object: "Me", text: "嗯？什么东西砸到了我的头上..." },
        { object: "Ang", text: "他拿下来一看，居然是他失踪已久的、贝拉的照片。" },
        { object: "Me", text: "..." },
        { object: "Me", text: "贝拉。" },
        { object: "Me", text: "你就是宇宙中最美妙的旋律。" },
        { object: "Ang", text: "还可以是一次狼狈不堪的糟糕旅行，永永远远地没有尽头。" },
        { object: "Ang", text: "像是20年前，向晚仍旧孤独地走在不雨的城市之间。" },
        { object: "Ang", text: "但她这一次，却感觉身体特别沉重：" },
        { object: "Me", text: "向晚？是你！" },
        { object: "Me", text: "你回来了！" },
        { object: "Ava", text: "..." },
        { object: "Ava", text: "我感觉我还是做错了什么..." },
        { object: "Me", text: "怎么了，向晚？" },
        { object: "Me", text: "向晚！谁来帮帮我？" },
        { object: "Ang", text: "也可以是那一场沉睡的梦，似乎无法苏醒却又充满了回忆，" },
        { object: "Ang", text: "就如如今，乃琳一同躺在虚空之中，从前的事就像跑马灯一样飞速闪过：" },
        { object: "Eillen", text: "你们...你们在哪..." },
        { object: "Eillen", text: "怎么这里...只剩下我一个人..." },
        { object: "Eillen", text: "但或许...本该如此了。" },
        { object: "Eillen", text: "看！这是嘉然姐姐、这是贝拉姐姐、这是向晚姐姐、这是珈乐姐姐..." },
        { object: "Eillen", text: "这是我。" },
        { object: "Me", text: "哇！姐姐看起来和现在不大一样呢！" },
        { object: "Eillen", text: "当然，那个时候的我可不比你大的了多少..." },
        { object: "Ang", text: "你和珈乐，最终回到了另一个时空，那个珈乐的时空。" },
        { object: "Ang", text: "你认识了那个珈乐的朋友，虽然两人看起来几乎没有任何共同点，" },
        { object: "Ang", text: "但你们却实实在在有着不少相同之处，某种程度上几乎是一个人。" },
        { object: "Ang", text: "你与他相处地十分愉快。" },
        { object: "Ang", text: "他与你讲那个麦克风的故事，一起和你听珈乐的演唱会。" },
        { object: "Ang", text: "事情原本就应该这么继续。" },
        { object: "Ang", text: "但恐怕腐烂的根，已经无法再支撑树木的生长。" },
        { object: "Ang", text: "当裂缝已经伸向那个世界的时候，你知道这一切已经无可奈何。" },
        { object: "Ang", text: "你同样闭上了眼睛。" },
        { object: "Ang", text: "最终的故事，也在一片吵闹与宁静之中，缓慢地死亡了。" },
        { object: "Aside", text: "【完】" }
    ],
    para1choice: [

    ]
}
export default chapter6;