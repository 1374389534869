export const chapter1 = {
    para1: [
        { object: "Aside", text: "一片荒漠..." },
        { object: "Aside", text: "热..." },
        { object: "Aside", text: "简直就像火炉..." },
        { object: "Aside", text: "周围的一切都要融化了..." },
        { object: "Me", text: "我的东西...我的东西去哪里了..." },
        { object: "Aside", text: "我焦头烂额，低着头顶着烈日，四处寻找着。" },
        { object: "Me", text: "在哪...在哪...去哪里了..." },
        { object: "Me", text: "可恶，去哪里了！" },
        { object: "Aside", text: "街上的人都匆匆忙忙地走着..." },
        { object: "Aside", text: "他们看起来几乎总是这么忙碌。" },
        { object: "Aside", text: "从小到大这么多年，几乎所有人都摆着一副臭脸。" },
        { object: "Aside", text: "当然我也不例外。" },
        { object: "Aside", text: "在哪...在哪...." },
        { object: "Passer", text: "哎哟！", music: "fight" },
        { object: "Aside", text: "太匆忙的我一下子忘记看路，以至于和前面的一个同样焦头烂额的人撞到了一起。" },
        { object: "Me", text: "不长眼睛的狗东西！" },
        { object: "Passer", text: "你说什么呢？混蛋！" },
        { object: "Me", text: "别挡着我，滚开！" },
        { object: "Passer", text: "谁拦着谁啊？啊？" },
        { object: "Aside", text: "我们两个一下子就对峙起来了。" },
        { object: "Aside", text: "谁也看谁不顺眼，气氛紧张的在这大热天快要燃起来了。" },
        { object: "Passer", text: "啊？你想怎么样？" },
        { object: "Aside", text: "他上前重重地推了我一把，我的怒火实在是忍受不了了。" }
    ],
    para1choice: [
        { object: "Aside", choice: "用力推他", text: "我马上还击，差点让他摔到地上。" },
        { object: "Aside", choice: "给他一拳", text: "我一气之下，捏紧了拳头，朝他的抽脸上狠狠地来了一下。" },
        { object: "Aside", choice: "掐他脖子", text: "我瞬间冲了上去，两只手就像大钳子一样狠狠地掐住他的脖子。" }
    ],
    para1AfterChoice0: [
        { object: "Passer", text: "你敢！" },
        { object: "Aside", text: "这个家伙像头猛牛似的猛撞击过来，企图把我死死地摁在地上。" },
        { object: "Aside", text: "我用头使劲敲了他一下，他大叫一声，终于松下手来。" },
        { object: "Aside", text: "我对着他的腹部使劲一踢，待他退到几米远，缓缓地站了起来。" },
        { object: "Aside", text: "可我刚一站起来，他又对着我的脸展开了第二波攻势，朝着我的脸就是狠狠一拳。" },
        { object: "Aside", text: "我一下子没防住，被打得晕头转向，差点再次倒地。" },
        { object: "Aside", text: "可当他还想继续的时候，我猛得一躲闪，踢中了他的膝盖骨。" },
        { object: "Aside", text: "他一下子栽倒在地上，却也把我也拉了下去。" },
        { object: "Aside", text: "结果我们两一直在地上扭打个不停，一个个面红耳赤丝毫不停歇。" },
    ],
    para1AfterChoice1: [
        { object: "Aside", text: "他完全没反应过来，痛苦地大叫了一声。" },
        { object: "Aside", text: "随后他愈加愤怒了，一下子死死地抱起了我，" },
        { object: "Aside", text: "把我像一个弹球一下顶了出去，用力踢了我一脚。" },
        { object: "Aside", text: "我一下子头晕眼花，但幸好还是防住了他的第二轮攻势。" },
        { object: "Aside", text: "就这样毫无逻辑地野蛮的扭打着，就算是鼻青脸肿了也不停歇。" },
        { object: "Passer", text: "来呀！就这点本事吗？" },
        { object: "Me", text: "今天有你好看！" }
    ],
    para1AfterChoice2: [
        { object: "Aside", text: "他被憋得面红耳赤，却一下子踢中了我的腹部。" },
        { object: "Aside", text: "我痛苦的大叫一声，捂着自己的腹部倒退了几步，" },
        { object: "Aside", text: "他却像头蛮牛似的，抓住我的衣领想给我重重的一拳！" },
        { object: "Aside ", text: "我用头狠狠地撞击他，他松手了，可是却丝毫没有退却" },
        { object: "Aside", text: "我们就这样一直打个不停，似乎没有停歇。" },
        { object: "Aside", text: "而这一切，似乎只是不小心撞到了而已。" },
        { object: "Aside", text: "却表现的像是两个凶恶的海盗，为了一片财宝而争得你死我活。" }
    ],
    para2: [
        { object: "Aside", text: "渐渐地，我们周围来了许多看热闹的人。" },
        { object: "Aside", text: "这些人，平常生活单调无聊到了极点。" },
        { object: "Aside", text: "一看见这种暴力行为，就像是触发了某种神秘力量一般，" },
        { object: "Aside", text: "着了魔似的奔涌而来，像虔诚的邪教徒一样观赏者这场血腥的演出。" },
        { object: "Aside", text: "他们兴奋地大喊着，聚精会神地观看者，仿佛这是一场无与伦比的精彩表演：" },
        { object: "Audio", text: "打他，打他！就是这样!" },
        { object: "Audio", text: "对，就是这样！哎！...不行，不要就这样倒下！" },
        { object: "Audio", text: "我投50块...我投50块，我赌这个人赢！" },
        { object: "Audio", text: "加油！加油！别放弃，让他知道厉害！" },
        { object: "Audio", text: "别停下！他可是个坏蛋！打到他站不起来为止！" },
        { object: "Police", text: "喂！" },
        { object: "Aside", text: "一阵尖锐而刺耳的口哨声响起！" },
        { object: "Audio", text: "警察来了！" },
        { object: "Audio", text: "快跑！" },
        { object: "Aside", text: "刚才还热火朝天的，结果现在一下子就作鸟兽散，人影都见不到了。" },
        { object: "Aside", text: "结果就是，等到警察走到我们面前的时候，只看见我们两个还在扭打个不停。" },
        { object: "Aside", text: "咔嚓！", music: "door" },
        { object: "Aside", text: "哐啷！" },
        { object: "Police", text: "又是你...又是你！" },
        { object: "Police", text: "你说说，你这些日子犯了多少次了？" },
        { object: "Police", text: "我一只手都要数不过来了！" },
        { object: "Me", text: "..." },
        { object: "Police", text: "总之，你先待在这里好好冷静一下吧。" },
        { object: "Police", text: "出去以后，冷静一点。不要这么冲动。" },
        { object: "Police", text: "你要一直这样，我们也没法帮你了..." },
        { object: "Police", text: "如果现代医学插手的话，你可就不只是冷静这么简单了..." },
        { object: "Police", text: "也为了你自己...好好想想吧..." },
        { object: "Aside", text: "哐啷！" },
        { object: "Me", text: "..." },
        { object: "Me", text: "啧..." },
        { object: "Aside", text: "我转过头来，望着窗外的天空。" },
        { object: "Aside", text: "铁栏杆将阳光分割好几块，却还是这么毒辣，这么刺眼。" },
        { object: "Me", text: "啊，太阳。" },
        { object: "Me", text: "你什么时候也能冷静一下呢？" },
        { object: "Me", text: "你每天都这么亢奋，难道你不累吗？" },
        { object: "Me", text: "你将这世界上所有的水都带走了。你不辛苦吗？" },
        { object: "Me", text: "..." },
        { object: "Me", text: "我什么时候，又才可以冷静一下呢..." },
        { object: "Ava", text: "哈哈，各位好啊，我是爱娃哇哎哇！" },
        { object: "Me", text: "这是什么声音..." },
        { object: "Aside", text: "我将凳子垫在脚上，透过小小的铁窗看向门外。" },
        { obejct: "Aside", text: "一群人正聚集在外头。" },
        { object: "Aside", text: "他们的神情欢快而轻松，与以往的面貌丝毫不一样。" },
        {
            object: "Aside",
            text: "而站在他们中间的，是一个有着蓝色双马尾，带着一个吉他的小女孩。"
        }
    ],
    para2choice: [

    ]
}
export default chapter1;
