export const littleTalk = [
    "每周五晚8点！",
    "乐你带我走吧😭😭😭",
    "我是虚拟的。她们是虚拟的。为什么你不是？",
    "名之曰小矮子万舰计划。",
    "嘿嘿，贝拉，嘿嘿🤤",
    "🤡回魂之日！",
    "猫中毒！",
    "贝拉的生日是七月十七日！",
    "我超！晚！",
    "你就像窝在被子里舒服~",
    "然然，我要创造一个只有你的世界！",
    "我一直都是tom啊",
    "乃琳，我真的好喜欢你啊！mua！",
    "开溜！",
    "嘉然画了一根线，原来是鞋。",
    "塔落西！",
    "风情小珈乐也可以很二次元！",
    "迷迭香！",
    "蜜月！",
    "水母之歌！",
    "红色高跟鞋！",
]
export default littleTalk
