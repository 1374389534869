export const chapter1 = {
    para1: [
        { object: "Me", text: "我邻居是一个奇怪的人。" },
        { object: "Aside", text: "当我刚认识他的时候，我还并不这么觉得。" },
        { object: "Aside", text: "可是认识了一段时间，我发现他身上有太多不寻常的地方了。" },
        { object: "Aside", text: "就比如我去他家做客的时候，我发现他有一个总是封闭着的小房间。" },
        { object: "Aside", text: "上面写着【只有珈乐能进】" },
        { object: "Aside", text: "可是我从来没见过他家出现这个叫珈乐的人。" },
        { object: "Aside", text: "当我问起他时，他总是一股快要发疯的沉醉表情，把我吓了一跳。" },
        { object: "S", text: "嘻嘻...珈乐...嘻嘻..." },
        { object: "Aside", text: "搞得我都不敢多问了..." },
        { object: "Aside", text: "还有一次，我在和他的一个朋友聊天，他也在场。" },
        { object: "Me", text: "这真是一件令人快乐的事啊！" },
        { object: "S", text: "乐...乐...嘻嘻...珈乐..." },
        { object: "S", text: "珈乐！我的珈乐！" },
        { object: "S", text: "呜呜呜..." },
        { object: "Aside", text: "他突然间就毫无征兆地倒在地上，全身抽搐着，还在不断地喃喃自语着...", music: "funny" },
        { object: "S", text: "乐你带我走吧乐！😭😭😭" },
        { object: "S", text: "没了你我可怎么活啊！！！" },
        { object: "Me", text: "他这是怎么了？" },
        { object: "Me", text: "快打120叫救护车吧！" },
        { object: "Friend", text: "不，不用！" },
        { object: "Friend", text: "给他放珈乐翻唱的那几首歌就好了！" },
        { object: "Friend", text: "拿出他的随身听！" },
        { object: "Aside", text: "我半信半疑，但还是照做了。" },
        { object: "Me", text: "哇...珈乐翻唱过不少歌呢..." },
    ],
    para1choice: [
        { objcect: "Aside", choice: "溯", text: "我点了一下【溯】" },
        { objcect: "Aside", choice: "红色高跟鞋", text: "我点了一下【红色高跟鞋】" },
        { objcect: "Aside", choice: "云烟成雨", text: "我点了一下【云烟成雨】" }
    ],
    para1AfterChoice0: [
        { object: "Croal", text: "嘟嘟  嘟嘟 嘟嘟嘟 嘟..." },
        { object: "Aside", text: "一个清纯而空灵的声音散发到空气上。" },
        { object: "Croal", text: "嘟嘟  嘟嘟 嘟嘟嘟 嘟..." },
        { object: "S", text: "啊...是她，是她！" },
        { object: "S", text: "嘟嘟  嘟嘟 嘟嘟嘟  嘟..." },
        { object: "Aside", text: "他居然也跟着唱起来了..." },
        { object: "S", text: "乐，我的乐！我会等你回来！" },
        { object: "Aside", text: "他居然一下子就站了起来，跟个没事人一样。" }
    ],
    para1AfterChoice1: [
        { object: "Croal", text: "你就像 窝 在 被子里 舒服..." },
        { object: "Croal", text: "却又像风 捉摸不住..." },
        { object: "Aside", text: "一阵甜美的声音飘荡在空气中，散发着迷人的味道。" },
        { object: "S", text: "啊...乐...乐...别丢下我..." },
        { object: "S", text: "没事的...没事的..." },
        { object: "S", text: "像手腕上散发的 香水味..." },
        { object: "S", text: "像爱不释手的  额额 额额    红色高跟鞋！" },
        { object: "Aside", text: "他就这样像个没事人一样站起来了，就好像什么也没发生一样。" }
    ],
    para1AfterChoice2: [
        { object: "Croal", text: "我多想再见你，哪怕匆匆一眼就别离..." },
        { object: "Croal", text: "路灯下昏黄的剪影,越走越漫长的林径..." },
        { object: "Aside", text: "清甜的女声从随身听中迸发，跳到了空气上。" },
        { object: "S", text: "我也好想见你，珈乐！😭😭😭" },
        { object: "S", text: "为什么就这样匆匆离去！" },
        { object: "Aside", text: "他居然跟着唱起来了:" },
        { object: "S", text: "我多想再见你,至少玩笑话还能说起..." },
        { object: "Aside", text: "街巷初次落叶的秋分，渐行渐远去的    我们..." },
        { object: "S", text: "乐，我会一直等下去的！" },
        { object: "Aside", text: "他突然一下子就站了起来，拍了拍身上的灰。" }
    ],
    para2: [
        { object: "S", text: "发生什么了？" },
        { object: "Me", text: "额...什么也没..." },
        { object: "Friend", text: "哈哈...没事..." },
        { object: "Me", text: "他这倒是是怎么回事？" },
        { object: "Aside", text: "事后，我和那个朋友悄悄说到这件事," },
        { object: "Friend", text: "我也不知道...反正遇到这种事，刚刚那样做总能好...医生也检查不出是哪里的问题..." },
        { object: "Me", text: "..." },
        { object: "Aside", text: "从那以后，我越来越觉得他是个怪胎了。" },
        { object: "Aside", text: "他做出来的行为让我感到越来越奇怪，以至于我都感觉有些害怕..." },
        { object: "Aside", text: "为了我的安全，也为了所有人的安全，" },
        { object: "Aside", text: "也为了我的那份永不磨灭的好奇，" },
        { object: "Aside", text: "我决定亲自向他询问..." },
        { object: "Aside", text: "虽然我也知道，我可能并不能问出什么..." },
        { object: "Aside", text: "我知道在有一个地方，他总是会在那，我总是能找到他，" },
        { object: "Aside", text: "那便是在傍晚的十字路口。", music: "people" },
        { object: "Aside", text: "那里总是繁忙而拥挤，" },
        { object: "Aside", text: "车流、人流...无数的群流汇聚在一起，却又充满了秩序而不混乱，" },
        { object: "Aside", text: "就这样持续好几个小时，然后才渐渐褪去潮水，变回平常平静的模样。" },
        { object: "Aside", text: "橘红色的天空与立交桥交相辉映着，马上就要变成一副倒影。" },
        { object: "Aside", text: "他便总是坐在倒影下，戴着个小丑面具，面对着那时涨时停的潮水，" },
        { object: "Aside", text: "似乎望着出了声，手中抱着一个奇怪的盒子，雕塑般一动不动着。" },
        { object: "Aside", text: "我总没有说错。今日，他仍旧坐在那里。" },
        { object: "Aside", text: "不知道的人，以为他就是摆在哪里地某种奇怪的吉祥物呢。" },
        { object: "Me", text: "嗨！" },
        { object: "Aside", text: "他没有理我，仿佛空气都凝固了。" },
        { object: "Me", text: "吃饭了吗？" },
        { object: "S", text: "..." },
        { object: "Aside", text: "他还是没有理我，戴着一副小丑面具，这样子的沉默显得格外的可怕。" },
        { object: "Me", text: "..." },
        { object: "Me", text: "请问，我能问你一些事情吗？", music: "tell" },
    ],
    para2choice: [
        { object: "Me", choice: "珈乐是谁？", text: "请问，能告诉我谁是珈乐吗？" },
        { object: "Me", choice: "为什么一直在这里", text: "请问，你为什么每天都在这里呢？" },
        { object: "Me", choice: "过去的事情", text: "请问能告诉我你为什么会这样吗？" }
    ],
    para2AfterChoice0: [

    ],
    para2AfterChoice1: [

    ],
    para2AfterChoice2: [

    ],
    para3: [
        { object: "S", text: "..." },
        { object: "Aside", text: "..." },
        { object: "Me", text: "..." },
        { object: "S", text: "如果你真的想听我的故事，就尽管坐在我旁边吧。" },
        { object: "Me", text: "当然" },
        { object: "S", text: "有关于我这些年来发生的事情...那还要回到许多年前..." }
    ],
    para3choice: [

    ]
}
export default chapter1;
