export const chapter6 = {
    para1: [
        { object: "Aside", text: "我躺在医院的病房上，虚弱的身体似乎已经恢复了许多。", music: "nothing" },
        { object: "Aside ", text: "一个警官模样的人走了进来。" },
        { object: "Aside", text: "我认识他，他就是那个每次在局子里接管我的人。" },
        { object: "Police", text: "怎么样了？感觉好多了吗?这一脚可不轻啊..." },
        { object: "Me", text: "嗯,好多了..." },
        { object: "Police", text: "你立了大功啊，这次我对你刮目相看！" },
        { object: "Me", text: "嗯？" },
        { object: "Police", text: "要不是你报了警，我们通过你的定位找到了位置，" },
        { object: "Police", text: "恐怕我们也无法这么快地阻止这么可怕的事情的发生。" },
        { object: "Police", text: "同时，你也帮我们制服了那两个危险分子。" },
        { object: "Police", text: "一调查才知道，这些家伙比我们所想的危险的多。" },
        { object: "Police", text: "所以，我们决定为你开一次市民表彰大会！" },
        { object: "Me", text: "可是我没来得及报警啊..." },
        { object: "Police", text: "嗯？" },
        { object: "Me", text: "我没能成功报警。我只是被救下来了。" },
        { object: "Police", text: "不过向晚小姐一直在说你是她的救命恩人，为她报的警。" },
        { object: "Police", text: "她四处夸奖你，人们都很期待呢！" },
        { object: "Police", text: "总之无论如何，这次你都要上去讲几句了。" },
        { object: "Police", text: "对了，向晚小姐跟我说她要离开了。" },
        { object: "Police", text: "她说她给你送了个礼物。", music: "newLife" },
        { object: "Me", text: "什么礼物？" },
        { object: "Aside", text: "我打开那庞大的包装，里面蹦出来一个熟悉的东西：" },
        { object: "Me", text: "这是她的吉他！" },
        { object: "Me", text: "她一定搞错了！" },
        { object: "Aside", text: "最终，我拿着她的吉他，还是追上了她。" },
        { object: "Me", text: "请等一下，向晚。" },
        { object: "Ava", text: "嗯？怎么了？" },
    ],
    para1choice: [
        { object: "Me", choice: "这是你的吉他", text: "这是你的吉他，你落在我那里了。" },
        { object: "Me", choice: "你要去哪？", text: "请问，你是要离开这里了吗？" },
    ],
    para1AfterChoice0: [
        { object: "Ava", text: "不。现在是你的吉他了。" },
        { object: "Me", text: "为什么？" },
        { object: "Ava", text: "我有很多个吉他。" },
        { object: "Ava", text: "这个吉他告诉我，这里是她的归宿。" },
        { object: "Ava", text: "就这么简单。" },
        { object: "Ava", text: "好了，再见！" },
        { object: "Aside", text: "她转头就走，似乎一刻也不留有怀念。" },
        { object: "Me", text: "请等等！" },
        { object: "Ava", text: "还有什么事吗？" },
        { object: "Me", text: "请问...请问你能告诉我你要去哪里吗？" },
        { object: "Ava", text: "恐怕你并不知道这个地方。" },
        { object: "Ava", text: "那是另一个时空。" },
        { object: "Ava", text: "再见了！" },
    ],
    para1AfterChoice1: [
        { object: "Ava", text: "是的。" },
        { object: "Ava", text: "我总是这样。来了来，又走了走。" },
        { object: "Me", text: "请问...请问可以说一下是去哪吗?" },
        { object: "Ava", text: "..." },
        { object: "Ava", text: "那是另一个时空" },
        { object: "Me", text: "..." },
        { object: "Me", text: "这确实是我无法涉及的地方。" },
        { object: "Ava", text: "好了，我该走了，" },
        { object: "Ava", text: "拜拜！" },
        { object: "Me", text: "请等一下！" },
        { object: "Ava", text: "嗯？" },
        { object: "Ava", text: "你的吉他，你忘记拿了！" },
        { object: "Ava", text: "那现在不是我的吉他了。那现在是你的了。" },
        { object: "Ava", text: "你忘记了吗？它可是作为礼物送给你的。" },
        { object: "Ava", text: "好的，再会吧！" }
    ],
    para2: [
        { object: "Me", text: "等等！你好像掉了个东西！" },
        { object: "Aside", text: "我看见一张照片从她的口袋里滑了出来，" },
        { object: "Aside", text: "我捡了起来，发现那里面有五个女孩的笑脸。" },
        { object: "Aside", text: "其中站在中间的那一个双马尾少女，长得特别像向晚。" },
        { object: "Ava", text: "快还给我！怎么随随便便看人家的东西！" },
        { object: "Me", text: "啊，对不起..." },
        { object: "Ava", text: "不过也谢谢你啦。拜拜！" },
        { object: "Aside", text: "她真就像个水蒸气一样，砰的一下在我面前消失了。" },
        { object: "Aside", text: "我手中抱着她的吉他，又想起她曾经说过的那些话。" },
        { object: "Aside", text: "突然间，我似乎意识到了什么..." },
        { object: "Aside", text: "..." },
        { object: "Aside", text: "表彰大会很快就到来了。" },
        { object: "Aside", text: "同样的，很快，就轮到我上场了。" },
        { object: "Aside", text: "下面围着许多观众， 他们聚精会神地看着我。" },
        { object: "Aside", text: "就好像当初的向晚冷笑话大会一样。" },
        { object: "Aside", text: "我首先，是说了几句客套的感谢话。" },
        { object: "Aside", text: "随后，我就拿起了那副吉他，开始对下面喊到：" },
        { object: "Me", text: "一只公鹿，它走得越来越快，" },
        { object: "Me", text: "它变成了什么呢？" },
        { object: "Me", text: "它变成了高速公路！" },
        { object: "Audio", text: "哈哈！哈哈！" },
        { object: "Audio", text: "下雨辣！" },
        { object: "Aside", text: "顿时间，空气又充满了那种快活的气息，那种欢歌笑语。" },
        { object: "Aside", text: "大家尽情舞蹈着，再也没有了从前的那般臭脸，" },
        { object: "Aside", text: "每个人的脸中都充满了愉悦，每个人的内心都有了那份涓涓细流..." },
        { object: "Aside", text: "..." },
        { object: "Aside", text: '晚上，不雨城又下起了一场绵绵细雨。' },
        { object: "Aside", text: "这场雨浸润着大地，浸润着世间万物，也浸润着我们的心。" },
        { object: "Aside", text: "【完】" }
    ],
    para2choice: [

    ]
}
export default chapter6;
