export const chapter5 = {
    para1: [
        { object: "Eillen", text: "贝拉的情况怎么样？" },
        { object: "Me", text: "一切安好。" },
        { object: "Me", text: "相信贝拉姐姐很快就能到达目的地！" },
        { object: "Eillen", text: "太棒了！" },
        { object: "Eillen", text: "你很棒！来，抱抱！" },
        { object: "Me", text: "啊！" },
        { object: "Aside", text: "乃琳那高耸如云而丰满的拥抱，对我来说简直就是美妙极了！" },
        { object: "Eillen", text: "好，继续观察情况。" },
        { object: "Me", text: "是的！乃琳长官！" },
        { object: "Aside", text: "宇宙中目前风平浪静，显得十分地安逸。" },
        { object: "Aside", text: "但是却又有些过于安静，安静到毫无生机。" },
        { object: "Bella", text: "不知道已经过了多久..." },
        { object: "Aside", text: "她的身影，带动着宇宙，充满了韵律，仿佛这一场旅行也是一次舞蹈。" },
        { object: "Aside", text: "舞蹈已经融入了她永恒的生命。就算再次重生，这一点也是如此显而易见。" },
        { object: "Me", text: "乃琳姐姐？" },
        { object: "Eillen", text: "嗯？" },
        { object: "Me", text: "你和贝拉姐姐之间有什么有趣的事吗？" },
        { object: "Eillen", text: "当然。" },
        { object: "Eillen", text: "以前我和她一起打游戏，这家伙打的全神贯注可认真了。" },
        { object: "Eillen", text: "有一次我不停得干扰她摸她大腿，居然摸了五十几次。" },
        { object: "Eillen", text: "结果事后差点被她打了一顿。" },
        { object: "Me", text: "哈哈！" },
        { object: "Bella", text: "..." },
        { object: "Bella", text: "安静...太过安静了..." },
        { object: "Bella", text: "韵律...韵律要消失了。" },
        { object: "Eillen", text: "等等...这是什么声音..." },
        { object: "Me", text: "嗯..." },
        { object: "Me", text: "不好！", music: "allOver" },
        { object: "Me", text: "大风来了！" },
        { object: "Aside", text: "一场突如其来的大风席卷而来！" },
        { object: "Aside", text: "天空中的恒星被从吹得灯笼般四处摇晃，一个接一个地熄灭！" },
        { object: "Eillen", text: "这是怎么回事？" },
        { object: "Me", text: "我不知道！" },
        { object: "Eillen", text: "保住那颗星星，快去！" },
        { object: "Aside", text: "那颗星星已经开始移动，我下意识跳过去抓住它，" },
        { object: "Aside", text: "可是炙热的高温让我一下子就缩了回来，双手一阵一阵地疼痛。" },
        { object: "Eillen", text: "别让它灭了！" },
        { object: "Aside", text: "我和乃琳姐姐站在那颗星球的两边，用尽各种办法为它添加火种：" },
        { object: "Eillen", text: "加油！加油！" },
        { object: "Aside", text: "宇宙中的湖面，已是狂风大作。" },
        { object: "Aside", text: "贝拉根本控制不住那颠簸的小船，只能任由其飘荡：" },
        { object: "Bella", text: "不！请放过我！我不受控制了！" },
        { object: "Eillen", text: "绝对不能...绝对不能..." },
        { object: "Aside", text: "狂风越来越大。最终，我们已经完全站不住脚了。" },
        { object: "Aside", text: "一阵旋风将我们两掀倒在地，却难以再站起来。" },
        { object: "Aside", text: "我们眼睁睁看着那颗星球上的火种一点一点被吹散到宇宙中，化成了死灰。" },
        { object: "Aside", text: "它的亮度越来越暗，迎来了它最终的那一刻熄灭..." },
        { object: "Eillen", text: "...可恶！" },
        { object: "Aside", text: "贝拉眼见着天上的星星，一颗又一颗的消失。" },
        { object: "Bella", text: "这...这就是结局了吗..." },
        { object: "Aside", text: "最后，那颗指引她前行的星星也消失了。" },
        { object: "Aside", text: "贝拉什么也看不见，周围的一切都变成了虚无。" },
        { object: "Aside", text: "一阵猛烈的大风将她一百八十度掀翻，就这样沉入无边的湖底，" },
        { object: "Aside", text: "化作了碎片。" },
        { object: "Faguan", text: "我仅代表法院，宣布本次审判结果：" },
        { object: "Faguan", text: "罪名成立！" },
        { object: "Aside", text: "砰！" },
        { object: "Eillen", text: "这不可能！" },
        { object: "Yls", text: "罪恶终将受到制裁！" },
        { object: "Yls", text: "为了人民！" },
        { object: "Eillen", text: "你就是个怂恿者！" },
        { object: "Faguan", text: "请各位保持冷静！" },
        { object: "Eillen", text: "...抱歉，是我失礼了。" },
        { object: "Faguan", text: "法庭人声鼎沸，嘉然就这样在一片呼喊之中被押下了台。" },
        { object: "People", text: "嘉然就是个恶棍！" },
        { object: "People", text: "我们终于又成功阻止了一个疯子！" },
        { object: "People", text: "哈哈！" },
        { object: "Eillen", text: "不，不...宝贝，听我说，我们还有机会..." },
        { object: "Eillen", text: "我们还可以再上诉...我们还可以再审..." },
        { object: "Diana", text: "已经来不及了..." },
        { object: "Eillen", text: "请问你说什么？" },
        { object: "Diana", text: "我说，恐怕我们已经来不及了，乃琳小姐！" },
        { object: "Aside", text: "乃琳一下子就愣住了。" },
        { object: "Aside", text: "她就这样看着嘉然被押送到马车上，离开了她的视线，完全不见了。" },
        { object: "Me", text: "乃琳姐姐...乃琳姐姐？？？" },
        { object: "Eillen", text: "来不及了..." },
        { object: "Eillen", text: "是啊。确实来不及了。" },
        { object: "Croal", text: "外面已经什么也没有了。" },
        { object: "Croal", text: "就剩我们几个了。" },
        { object: "Croal", text: "向晚到底去哪里了？" },
        { object: "Aside", text: "珈乐看起来一件焦头烂额。可乃琳似乎反而保持住镇定，就算到了如此境地也没有失去风度。" },
        { object: "Eillen", text: "向晚...向晚..." },
        { object: "Me", text: "珈乐姐姐！乃琳姐姐！我好像找到一张纸条！" },
        { object: "Me", text: "上面留有她的名字！" },
        { object: "Aside", text: "乃琳姐姐和珈乐姐姐赶紧跑了过来。" },
        { object: "Aside", text: "我们三人展开纸条，发现上面寥寥草草地写了一个字：" },
        { object: "Ava", text: "【那些噩梦让我难以忍受】" },
        { object: "Ava", text: "【抱歉，我只能这样离开】" },
        { object: "Eillen", text: "..." },
        { object: "Croal", text: "向晚跑了！" },
        { object: "Aside", text: "乃琳一句话也没说。她直挺挺地站着，但是那种权威的感觉，已经慢慢消散了。" },
        { object: "Ava", text: "你没办法凑齐五个人。", music: "storyEnd" },
        { object: "Eillen", text: "..." },
        { object: "Ava", text: "大家的心已经破裂了。" },
        { object: "Eillen", text: "向晚说的没错...我根本没有能力让五个人再次团聚。" },
        { object: "Eillen", text: "看起来到头来，我坚守的这个旧世界注定要毁灭了。" },
        { object: "Croal", text: "..." },
        { object: "Eillen", text: "为什么你用这样子的眼神看着我？" },
        { object: "Eillen", text: "你很害怕么？" },
        { object: "Croal", text: "别过来，乃琳。至少是为了孩子。" },
        { object: "Eillen", text: "...珈乐。我哪也不去。这里是枝江。这里就是我最初的家。" },
        { object: "Eillen", text: "再见了。" },
        { object: "Aside", text: "四周变得一片白暗，只剩下我、乃琳和珈乐，以及所站的那个最后的孤岛。" },
        { object: "Me", text: "乃琳姐姐...我害怕..." },
        { object: "Eillen", text: "嗯...别怕...来姐姐这里来..." },
        { object: "Croal", text: "别过去！" },
        { object: "Aside", text: "可是我还是过去了。" },
        { object: "Aside", text: "乃琳姐姐紧紧地抱住我，给了我一个温馨的微笑。" },
        { object: "Aside", text: "但过了一会，我却感觉我的头顶热热的湿湿的，带着乃琳姐姐的味道。" },
        { object: "Eillen", text: "你带着她回去吧。你知道怎么做吧。" },
        { object: "Croal", text: "嗯..." },
        { object: "Eillen", text: "那就好..." },
        { object: "Aside", text: "她又拿出了那张照片。这一次，她一直凝视着，凝视着很久。" },
        { object: "Me", text: "乃琳姐姐？...你不走吗？" },
        { object: "Eillen", text: "哈哈...我稍后就来。" },
        { object: "Aside", text: "珈乐牵着我的手，走向了密室。" },
        { object: "Aside", text: "我仍忍不住回过头来。" },
        { object: "Aside", text: "她并没有跟着我们。" },
        { object: "Aside", text: "她只是平静地站在那里，面对着我们。" },
        { object: "Aside", text: "刹那间，乃琳跟随着她那心心念念的枝江，都化为了飞灰。" }
    ],
    para1choice: [

    ]
}
export default chapter5;
