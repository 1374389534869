export const chapter6 = {
    para1: [
        { object: "Aside", text: "春天。" },
        { object: "Aside", text: "春天真的来了。" },
        { object: "Aside", text: "最后一场雪也结束了。" },
        { object: "Aside", text: "冰雪开始消融，草木开始生长。" },
        { object: "Aside", text: "食物也开始渐渐多起来了。" },
        { object: "Aside", text: "小镇似乎开始重建了。" },
        { object: "Aside", text: "不少人千里迢迢，开始建设着他们的新家园。" },
        { object: "Aside", text: "小镇开始重新热闹起来，就和我小时候所见到的那样。" },
        { object: "Aside", text: "嘉然小姐仍旧在那里画画。" },
        { object: "Aside", text: "不过拜访她的人更多了，而她也有了小小的名气。" },
        { object: "Aside", text: "虽然似乎也有人对嘉然小姐产生了非议，但至始至终都成不了气候。" },
        { object: "Aside", text: "嘉然小姐有时候会跟他们讲起她的那个小小徒弟，也就是那个小小老鼠的故事。" },
        { object: "Aside", text: "每次说起，她的客人们总是会忍俊不禁，觉得这是一个非常可爱的童话。" },
        { object: "Aside", text: "当然，嘉然小姐的头脑一直都很清醒。" },
        { object: "Aside", text: "冬天夜里的故事，仍旧再继续。" },
        { object: "Aside", text: "我仍旧在做那个甜蜜的糖果梦。" },
        { object: "Aside", text: "但我不在感到困惑，不在感到可怕。" },
        { object: "Aside", text: "我知道那是上帝赐予我的礼物，它使我有了梦想，有了希望。" },
        { object: "Aside", text: "怀着沉重地睡意缓缓闭上了眼睛，梦乡里的那一切，仍旧在吸引着我走向远方。" },
        { object: "Aside", text: "..." },
        { object: "post", text: "嘉然小姐，你的信。" },
        { object: "Diana", text: "好的...谁会给我写信呢？" },
        { object: "Aside", text: "嘉然小姐耐心地拆开信封，展开了那张纸：" },
        { object: "Aside", text: "之间纸上留了大片空白，只有边上一个颤颤巍巍的大字：" },
        { object: "Aside", text: "【欢迎回家，嘉然。】" },
        { object: "Aside", text: "【————乃琳】" },
        { object: "Aside", text: "【完】" },
    ],
    para1choice: [

    ]
}
export default chapter6
