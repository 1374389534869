<template>
  <div class="wrong">
    <p>错误的时空！</p>
    <p>这里或许曾经存在，但不是现在.</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.wrong {
  background: #fff;
  height: 70vh;
  font-family: FZYaoti;
  display: flex;
  justify-content: center;
  vertical-align: center;
  padding-top: 30vh;
}
</style>
