export const chapter4 = {
    para1: [
        { object: "S", text: "一天，她本来正在正常的演出...", music: "go" },
        { object: "S", text: "可是突然间，她的麦克风开始发出滋滋的声响。" },
        { object: "S", text: "她的声音在这种影响下变了形，" },
        { object: "S", text: "简直就是完全影响了她的发挥。" },
        { object: "S", text: "啊，怎么回事！" },
        { object: "S", text: "大家这么说着，以为下一场总会没事。" },
        { object: "S", text: "可是到下一场，下下场，再到以后的很多场..." },
        { object: "S", text: "珈乐的麦总是会发出滋滋的响声。" },
        { object: "S", text: "无论换了多少个。" },
        { object: "S", text: "甚至在一些大场合，也会出这样离奇的事故。" },
        { object: "S", text: "她的声音变得不再这么好听，不再这么有吸引力了。" },
        { object: "S", text: "渐渐地，她的观众变得越来越少，人们不再这么有耐心听她唱歌了。" },
        { object: "S", text: "甚至是珈乐自己，也开始失去了自信。" },
        { object: "S", text: "她的演出越来越少，几乎是将自己封闭了起来。" },
        { object: "S", text: "她愤怒地将这些麦克风摔得个粉碎，也没能完全遏制住她的怒火。" },
        { object: "S", text: "她自认为，这是一场诅咒。" },
        { object: "Me", text: "这么可怕？" },
        { object: "S", text: "如果你这么认为的话。" },
        { object: "S", text: "她变得越来越消沉，几乎要感觉不到那是她了。" },
        { object: "S", text: "作为看着她一路走过来的人，我们之间不仅是粉丝和表演者，" },
        { object: "S", text: "我们还是互相之间的朋友。" },
        { object: "S", text: "有一次，我和她聊天。" },
        { object: "S", text: "无意之中，我们便还是谈到了这一直想避开的事：" },
        { object: "Croal", text: "我无法相信...我无法相信这就是结局。" },
        { object: "S", text: "这不是结局。" },
        { object: "Croal", text: "无论如何，我们都找不到问题所在...这就是一场诅咒。" },
        { object: "S", text: "就算这是一场诅咒，我们也一定能找到破解它的办法。" },
        { object: "S", text: "我的预知梦会告诉我该如何解决的。" },
        { object: "Croal", text: "你真相信你的预知梦？" },
        { object: "S", text: "当然。这里面可是有你啊。" },
        { object: "Croal", text: "哎...完蛋了。我的歌声，全部都毁了..." },
        { object: "S", text: "胡说什么呢？珈乐？没有麦，你的声音可是一点也没受到影响..." },
        { object: "Croal", text: "可是没了麦，我那微弱的声音无法将歌声传递到更远的地方..." },
        { object: "S", text: "..." },
        { object: "S", text: "等着我，珈乐！" },
        { object: "S", text: "我一定给你弄出个不会有电流声的麦！" },
        { object: "S", text: "说罢，我便站起身来，准备离开。" },
        { object: "S", text: "不要消沉，珈乐！" },
        { object: "Croal", text: "可是...可是..." },
        { object: "Croal", text: "这么多人...这么长时间都没有找到问题，你怎么能这么打包票呢？" },
        { object: "S", text: "哈哈，珈乐。" },
        { object: "S", text: "到时候记得付钱就好了。" },
        { object: "S", text: "定制的东西，可不便宜。" },
        { object: "Me", text: "你真要她付钱？" },
        { object: "S", text: "当时还真是那么想的。" },
        { object: "S", text: "那时的我比现在年轻多了，怀着一腔热血说干就干。" },
        { object: "S", text: "我刚好也是做这行的，多多少少懂得一些。" },
        { object: "S", text: "我还认识一些朋友，他们也愿意来帮助我。" },
        { object: "S", text: "待到有空的时候，我们就聚在一起，仔细去研究这个问题。" },
        { object: "S", text: "我们调查了很长一段时间。" },
        { object: "S", text: "我们仔细研究当初演出的地点，时间，天气和环境..." },
        { object: "S", text: "又去研究了一下以前的演出地点，时间，天气和环境..." },
        { object: "S", text: "我们还抽样了珈乐的声音，去问专业人员。" },
        { object: "S", text: "我们问了很多人，也学到了不少东西。" },
        { object: "S", text: "最后我们发现了问题的所在！" },
        { object: "S", text: "当我们分开来探索问题的时候，似乎一切都相安无事。" },
        { object: "S", text: "但当我们合并起来看的时候，一种奇妙的巧合便恰好造成了这一问题！" },
        { object: "S", text: "而我们要做的便是从麦克风的角度上去打破这种巧合！" },
        { object: "S", text: "终于找到了解决的方案，我们都感到很高兴。" },
        { object: "Me", text: "所以说，最后你真的卖给她了？" },
        { object: "S", text: "...其实，我只是想送给她。" },
        { object: "S", text: "能再次听到她的歌声，我就已经感到很开心了。" },
        { object: "S", text: "..." },
        { object: "S", text: "当时的我，做的实在是太过入迷、太专心了。" },
        { object: "S", text: "以至于我完全忘记关注珈乐的情况。" },
        { object: "S", text: "我可能被告知过..但是我却完全忘记了这么一回事。" },
        { object: "S", text: "这么一回，如此重要的事。" },
        { object: "S", text: "在我们做好麦克风的晚上，我又做了那个奇怪的梦。" },
        { object: "S", text: "我梦见天空一片阴沉，似乎随时随地都要下一场倾盆大雨。" },
        { object: "S", text: "珈乐就远远地站在对面，呆若木鸡的，一动也不动。" },
        { object: "S", text: "她的脸色似乎不太好，身体也看起来很是瘦弱，似乎早已失去了那份青春年华。" },
        { object: "S", text: "怎么了？珈乐？..." },
        { object: "Croal", text: "..." },
        { object: "Croal", text: "你完全不需要做这么多...." },
    ],
    para1choice: [
        { object: "S", choice: "我不明白", text: "我听不懂这是什么意思，珈乐。" },
        { object: "S", choice: "这是我自愿的", text: "没关系...这是我自愿的..." },
        { object: "S", choice: "别这样...", text: "别这样...别再说这种丧气话了。" }
    ],
    para1AfterChoice0: [
        { object: "S", text: "你变得越来越奇怪了。" },
        { object: "Croal", text: "这是一个秘密。" },
        { object: "S", text: "她的声音变得如此的冷酷，我都怀疑我们是否曾经相识。" },
        { object: "Croal", text: "在不久的将来，我将会消失。" },
        { object: "Croal", text: "你会后悔为我做这么多，你会感觉你完全错了..." },
        { object: "S", text: "我不许你这么说，珈乐。" },
        { object: "S", text: "就在现在...能否给我唱一首歌，珈乐。" },
        { object: "S", text: "我已经好久没听你唱歌了。" },
        { object: "S", text: "没想到，她真的照做了。" },
        { object: "S", text: "那份甜美的声音再次从我身边想起，就像从前一样。" },
        { object: "S", text: "我仿佛回到了从前的时候。" },
        { object: "S", text: "那个时候，她还是这么自信，我还是这么沉醉。" },
        { object: "S", text: "太好了！珈乐！" },
        { object: "S", text: "你还是这么优秀。你还是这么楚楚动人。" },
        { object: "S", text: "相信自己，珈乐。你一点也没变。" },
        { object: "Croal", text: "不。" },
        { object: "Croal", text: "再见。" }
    ],
    para1AfterChoice1: [
        { object: "Croal", text: "不。只是因为我而已。" },
        { object: "Croal", text: "这是一场陷阱。" },
        { object: "Croal", text: "请不要再想念我了。" },
        { object: "S", text: "为什么？..." },
        { object: "S", text: "我不明白为什么会这样，珈乐。" },
        { object: "S", text: "但相信我，一切都会变好的！" },
        { object: "Croal", text: "你不明白。" },
        { object: "Croal", text: "她的声音变得如凌冽的寒冬一般寒冷，把我吓了一跳。" },
        { object: "S", text: "我当然不明白。" },
        { object: "S", text: "..." },
        { object: "S", text: "我知道了。那么，可以再唱一首歌吗？" },
        { object: "S", text: "就算是为了我。" },
        { object: "Croal", text: "该怎么去形容你最贴切..." },
        { object: "Croal", text: "该怎么去比较你才算最特别..." },
        { object: "S", text: "她突然就唱起来了，是经典的曲目。" },
        { object: "S", text: "天空中开始逐渐下起了雨。" },
        { object: "S", text: "雨越下越大，渐渐盖住了珈乐的歌声。" },
        { object: "S", text: "雨水冲刷着珈乐的声音，我的听觉变得模糊不清。" },
        { object: "S", text: "当这个世界完全只剩下那片空虚的白噪声之时，珈乐也没了身影。" }
    ],
    para1AfterChoice2: [
        { object: "Croal", text: "我没说丧气话。" },
        { object: "Croal", text: "我只是觉得，我不适合..." },
        { object: "Croal", text: "确实是该结束了。" },
        { object: "S", text: "结束什么？我不知道你在说什么..." },
        { object: "Croal", text: "你不应该知道。" },
        { object: "Croal", text: "你只用明白，过去的那些只是一场幻梦就好..." },
        { object: "S", text: "清醒一点！想想你的愿望！想想你的理想！" },
        { object: "Croal", text: "..." },
        { object: "S", text: "为我唱首歌吧..." },
        { object: "S", text: "她真的唱了。" },
        { object: "S", text: "她唱了一首我从未听过的歌。" },
        { object: "S", text: "当她唱起这首歌的时候，两个周围出现了滚滚的浓雾。" },
        { object: "S", text: "我的视野变得模糊不清，只看见她渐渐闭上了眼睛。" },
        { object: "S", text: "她的声音变成了薄薄的一片，" },
        { object: "S", text: "当最后一句韵律结束后，她就被那风吹得干干净净，留不下一点痕迹。" }
    ],
    para2: [
        { object: "S", text: "我一下子被惊醒了。" },
        { object: "S", text: "天还刚刚有点颜色，鸟儿却已经在开始叽叽喳喳的争鸣。" },
        { object: "S", text: "这完全是一场噩梦，我被吓得出了一场冷汗。" },
        { object: "S", text: "我越想越不对劲，觉得这预示着什么。" },
        { object: "S", text: "我立马踹开被子，收拾好那个麦克风，拔腿就往营地跑。" },
        { object: "S", text: "珈乐住着的营地，在一片干净的草地上。" },
        { object: "S", text: "那里时常会有着烧烤派对，还有一系列有趣的活动，好不热闹。" },
        { object: "S", text: "等着我...珈乐..." },
        { object: "S", text: "她还一定在...一定在那里。" },
        { object: "S", text: "我拼命的跑，一刻也不停歇。" },
        { object: "S", text: "结果待我走到目的地的时候，我被什么东西绊倒了，重重地摔了一跤。" },
        { object: "S", text: "麦克风...麦克风..." },
        { object: "S", text: "我将麦克风紧紧地握在手上，它没有收到一点影响。" },
        { object: "S", text: "还好...还好..." },
        { object: "S", text: "我缓缓地站了起来。望向那前方：" },
        { object: "S", text: "空旷的草地中，还是那么得干净和清新。" },
        { object: "S", text: "草地上面什么也不剩下。" },
        { object: "S", text: "就像是，从来没有来过一样..." }
    ],
    para2choice: [

    ]

}
export default chapter4;
