<template>
  <div>
    <audio src="../../public/music/hover.mp3" ref="au1" />

    <!-- <video
      src="../../public/video/ast.mp4"
      style="
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      "
      autoplay="autoplay"
      loop="loop"
      muted="muted"
      class="video"
    ></video> -->
    <div class="title">
      <h1 class="header">
        <span
          :class="handleTitleStyle(index)"
          v-for="(word, index) in title"
          :key="index"
        >
          {{ word }}
        </span>
      </h1>
    </div>
    <div class="door">
      <div v-for="(door, index) in doors" :key="index">
        <div :class="handleDoorsStyle(index)" v-if="index <= level">
          <div class="door-inline" @mouseout="handleImg(false, index)">
            <div
              class="handle-button"
              key="level"
              v-if="!door.imgStatus"
              @mouseover="handleImg(true, index)"
            >
              <el-button circle :icon="door.icon"></el-button>
            </div>
            <div key="level-start" v-else>
              <img
                :src="require('../../public/img/' + door.name + '.png')"
                @mousedown="story(door.name)"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="level === -1">
        <div class="door-outline-light">
          <div class="door-inline">
            <div class="handle-button">
              <el-button circle icon="el-icon-user-solid" @click="story('Ang')"></el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bottom">
      <span>{{ handleBottom() }}</span>
    </div> -->
    <div class="little-talk">
     <LittleTalk/>
    </div>
  </div>
</template>

<script>
import LittleTalk from '../Dbace/LittleTalk.vue';
import db from "../utils/loaclStorage";
export default {
  components:{LittleTalk},
  props:["character"],
  data() {
    return {
      title: ["D", "B", "A", "C", "E"],
      doors: [],
      level: 0,
      index: -1,
      saver: "",
      imgStatus: false,
    };
  },
  created() {
     this.doors = this.character;
    db.save("INITIAL", "INITIAL");
    this.saver = db.get("SAVER");
    this.level = this.saver.level;
    if(this.level === 5){
      this.$emit("handleStatus", "levelOver");
      db.save("INITIAL", "LEVELOVER");
    }
    if(this.level > 5 || this.level < -1){
      this.$emit("handleStatus", "wrong");
    }
  },
  methods: {
    handleTitleStyle(index) {
      if (index <= this.level) {
        return "light-up" + index;
      } else {
        return "light-down";
      }
    },
    handleDoorsStyle(index) {
      if (index === this.level) {
        return "door-outline-light";
      } else {
        return "door-outline";
      }
    },
    handleImg(bool, index) {
      this.doors[index].imgStatus = bool;
      this.index = index;
      this.$refs.au1.play();
    },
    handleBottom() {
      if (this.index === -1) {
        return "";
      }
      return this.doors[this.index].text;
    },
    story(name) {
      this.$emit("handleStatus", name);
    },
  },
};
</script>
<style>
</style>
<style scoped>
.title {
  text-align: center;
}
.title .header {
  font-size: 10vh;
  font-family: fantasy;
}
.title .header .light-up0 {
  color: white;
  text-shadow: 0 0 0.2em #f87, -0 -0 0.2em #f87;
}
.title .header .light-up1 {
  color: white;
  text-shadow: 0 0 0.2em rgb(255, 22, 185), -0 -0 0.2em rgb(255, 34, 0);
}
.title .header .light-up2 {
  color: white;
  text-shadow: 0 0 0.2em rgb(119, 228, 255), -0 -0 0.2em rgb(119, 255, 255);
}
.title .header .light-up3 {
  color: white;
  text-shadow: 0 0 0.2em rgb(0, 24, 90), -0 -0 0.2em rgb(15, 2, 90);
}
.title .header .light-up4 {
  color: white;
  text-shadow: 0 0 0.2em rgb(226, 255, 119), -0 -0 0.2em rgb(241, 255, 119);
}
.title .header .light-down {
  color: rgb(121, 121, 121);
}
.door {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
img {
  position: relative;
  z-index: 100;
  width: 100%;
  height: 40vh;
}
.door .door-outline {
  margin: 3vh;
  height: 40vh;
  width: 20vh;
  border: white 1px solid;
  padding: 2vh;
}
.door .door-outline-light {
  margin: 3vh;
  height: 40vh;
  width: 20vh;
  border: white 2px solid;
  padding: 2vh;
  box-shadow: 0 0 0.2em rgb(255, 255, 255), -0 -0 0.2em rgb(255, 255, 255);
}
.door .door-outline .door-inline {
  border: white 1px solid;
  height: 40vh;
}
.door .door-outline-light .door-inline {
  border: white 2px solid;
  height: 40vh;
}
.handle-button {
  text-align: center;
  padding-top: 15vh;
  position: relative;
  z-index: 10;
}
.bottom {
  padding-top: 2vh;
  text-align: center;
  color: white;
}
.little-talk{
  text-align: center;
  position: relative;
  z-index: 20;
}
</style>
