<template>
  <div class="little-talk">
    <transition name="fade" mode="out-in">
      <el-button type="text" @click="handleTalk" v-if="status">{{
        talk
      }}</el-button>
    </transition>
  </div>
</template>

<script>
import littleTalk from "../assets/Dbace/LittleTalk.js";
export default {
  data() {
    return {
      talk: "这里是幻境。",
      status: true,
    };
  },
  created() {
    this.handleTalk();
  },
  methods: {
    handleTalk() {
      const index = Math.floor(Math.random() * littleTalk.length);
      this.talk = littleTalk[index];
      this.status = !this.status;
      setTimeout(() => {
        this.status = !this.status;
      }, 500);
    },
  },
};
</script>

<style scoped>
.little-talk button {
  color: rgba(255, 255, 255, 0.452);
  font-size: 2vh;
  border: transparent;
}
.little-talk button:hover {
  color: white;
  font-size: 2vh;
  border: transparent;
  background: transparent;
}
</style>
