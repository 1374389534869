export const chapter4 = {
    para1: [
        { object: "Me", text: "嗯...", music: "nothing" },
        { object: "Me", text: "看起来这个吉他女孩已经引起了很大的热议..." },
        { object: "Aside", text: "我坐在街角的一张椅子上，看着最新的报纸:" },
        { object: "Aside", text: "关于向晚的新闻几乎占据了头条，还有一段非常长的独家采访。" },
        { object: "Report", text: "对于观看您表演的粉丝越来越多，您是否有什么想要说的呢？" },
        { object: "Ava", text: "额...我来到这个地方，我给大家带来了快乐，这是一件好事。" },
        { object: "Ava", text: "我希望这种快乐并不应该只靠我来传递。" },
        { object: "Ava", text: "这里会有更多有趣的灵魂的！" },
        { object: "Me", text: "..." },
        { object: "Me", text: "这不是好事。" },
        { object: "Me", text: "她并不属于这里。" },
        { object: "Aside", text: "我放下了报纸，本就应该如此离开。" },
        { object: "Aside", text: "突然，我好像听到了什么，于是本来站起来的我又缓缓地做了下去：" },
        { object: "Behind1", text: "我的意思是，" },
        { object: "Behind1", text: "为什么她每次一过来，这里就开始下雨？" },
        { object: "Behind1", text: "她不来的时候，这里就滴水不沾？" },
        { object: "Behind2", text: "这确实是..." },
        { object: "Behind1", text: "我在这里呆了许多年了。这里几乎不下雨。" },
        { object: "Behind1", text: "我敢说这个人有改变天气的能力！" },
        { object: "Behind2", text: "但是这也太..." },
        { object: "Behind1", text: "还有玄乎的呢！" },
        { object: "Behind1", text: "我尝试跟踪她。" },
        { object: "Behind1", text: "你猜怎么着。" },
        { object: "Behind1", text: "每次她走到没人的地方，就突然像水蒸气一样，砰的一下消失了！" },
        { object: "Behind1", text: "我敢打赌说她每次也是这么来的。" },
        { object: "Behind2", text: "真有这么夸张！" },
        { object: "Behind1", text: "还有呢！我给你看一件超乎人类想象的事..." },
        { object: "Behind1", text: "我有视频，绝对不是p的。" },
        { object: "Behind2", text: "..." },
        { object: "Behind2", text: "..." },
        { object: "Behind2", text: "这开什么玩笑！" },
        { object: "Behind1", text: "所以...你觉得怎么样？" },
        { object: "Behind2", text: "这个女孩绝对不是个普通人！" },
        { object: "Behind1", text: "所以说...成交？" },
        { object: "Behind2", text: "成交，当然！这真是令人兴奋！" },
        { object: "Behind1", text: "我很高兴。" },
        { object: "Aside", text: "..." },
        { object: "Me", text: "..." },
        { object: "He", text: "hi，你好啊！", music: "fight" },
        { object: "Aside", text: "原本在我背后的声音突然在我的前面出现，吓得我一激灵。" },
        { object: "Aside", text: "我站起来一看才发现，原来就是上次和我打架的那个人！" },
        { object: "He", text: "你怎么在这里？" },
    ],
    para1choice: [
        { object: "Me", choice: "我还想问你呢！", text: "我也想说...我们路人缘确实很好呢。" },
        { object: "Me", choice: "看看报纸而已", text: "看看报纸，找个安静地方罢了。" },
        { object: "Me", choice: "与你无关", text: "我在哪是我自己的事。" }
    ],
    para1AfterChoice0: [
        { object: "He", text: "这么说来，确实。" },
        { object: "He", text: "或许我们真的有些缘分。" },
        { object: "Me", text: "但我们的初次见面并不是一次很好的体验。" },
        { object: "He", text: "好了，别提了。哈哈。" },
        { object: "He", text: "那个时候谁不是脾气暴躁。" },
        { object: "He", text: "不过现在，看我这样，看你这样，" },
        { object: "He", text: "我们不也还能心平气和的说话么。" },
        { object: "Me", text: "我希望我们今也能这样。" },
        { object: "He", text: "哈哈，当然，那肯定的。" },
        { object: "He", text: "等有空了，我真想和你正式交个朋友。" },
        { object: "He", text: "祝你好运，拜拜！" },
        { object: "Me", text: "嗯，再见。" },
    ],
    para1AfterChoice1: [
        { object: "He", text: "请问...可以让我看看吗？" },
        { object: "Aside", text: "我将报纸递给了他。" },
        { object: "He", text: "他端详了一遍，然后说道：" },
        { object: "He", text: "我喜欢看报纸。" },
        { object: "He", text: "不过现在看得人似乎越来越少了。" },
        { object: "Me", text: "时代在变化。" },
        { object: "He", text: "但我觉得，报纸里的某些东西，还是暂时无法替代的。" },
        { object: "He", text: "怎么样？我希望上次的事并没有让你记恨在心。" },
        { object: "Me", text: "再普通不过的事罢了。" },
        { object: "He", text: "你是有着一个广阔之心的人。当初的我们太急躁了，以至于犯了如此的错误。" },
        { object: "Me", text: "确实...现在想想，很是愚蠢。" },
        { object: "He", text: "人都会犯错的。不过至少我们愿意改正和挽回。" },
        { object: "He", text: "好了，我先走了。" },
        { object: "Me", text: "再见。" },
    ],
    para1AfterChoice2: [
        { object: "He", text: "抱歉，我确实有点那个了。" },
        { object: "Me", text: "没关系。" },
        { object: "He", text: "不得不说，你和当初不太一样了啊。" },
        { object: "He", text: "当初你那暴躁的气势把我吓一跳。" },
        { object: "Me", text: "你也知道是当初了。" },
        { object: "He", text: "有空的话，我很愿意和你交一次朋友。" },
        { object: "He", text: "最近怎样？有去看向晚的节目秀吗？" },
        { object: "Me", text: "当然..." },
        { object: "He", text: "啊...我想起来了。前面那个高个子的，不会就是你吧。" },
        { object: "Me", text: "恐怕并不是。" },
        { object: "He", text: "哦，好吧。" },
        { object: "He", text: "我相信我们还会再会的。" },
        { object: "Me", text: "再会吧！" },
        { object: "He", text: "再见！" }
    ],
    para2: [
        { object: "Aside", text: "我越想越觉得这事奇怪。" },
        { object: "Aside", text: "不知道怎么得，" },
        { object: "Aside", text: "在当初那大热天，虽然是呆在阴凉处，" },
        { object: "Aside", text: "可是他却给了我一种无法言表的寒意。" },
        { object: "Aside", text: "搞得我都在止不住地颤抖。" },
        { object: "Aside ", text: "我越想越觉得奇怪，居然有些害怕。" },
        { object: "Aside", text: "结合当时听到他所说的关于向晚的那些话，" },
        { object: "Aside", text: "一种奇怪而可怕的想法从我的脑海里面浮现出来。" },
        { object: "Aside", text: "越想越是夸张，仿佛真的发生了一样。" },
        { object: "Me", text: "不，这不可能。" },
        { object: "Me", text: "这太牵强了。" },
        { object: "Me", text: "再说了，和我又有什么关系呢？" },
        { object: "Aside", text: "终于，在最后，我停止了这一荒诞的想法。" },
        { object: "Aside", text: "但是内心还是止不住地有些浮躁。" },
        { object: "Aside", text: "...", music: "nothing" },
        { object: "Aside", text: "夜幕又降临了。" },
        { object: "Aside", text: "我又独自一人来到山坡上，凝神望着远方。" },
        { object: "Aside", text: "突然间，我听到了一个奇怪的声音。" },
        { object: "Aside", text: "我发现我总是能察觉到这些奇怪的声音" },
        { object: "Aside", text: "我偷偷藏在草丛底下，听着一股急切的脚步声越来越近。" },
        { object: "Aside", text: "双眼轻轻一瞥，在月光中隐隐约约看清了那个人的脸：" },
        { object: "Me", text: "是他！" },
        { object: "Aside", text: "他走得很着急，一下子就过去了。" },
        { object: "Me", text: "夜晚急急忙忙地来到这山上，准没什么好事。" },
        { object: "Aside", text: "我决定跟过去，不管是什么，我都要一探究竟。" }
    ],
    para2choice: [

    ]
}
export default chapter4