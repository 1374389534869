export const chapter3 = {
    para1: [
        { object: "Aside", text: "我决定和人类进行一次正式的接触。" },
        { object: "Aside", text: "不知道为什么，我隐隐约约地觉得这个叫嘉然的女孩并不是坏人。" },
        { object: "Aside", text: "如果我能遵守人类的礼貌，或许她会好好的待见我。" },
        { object: "Aside", text: "这样子下来，我就能搞懂我身上到底发生了什么。" },
        { object: "Aside", text: "正好，外面的大雪总算是没有一直不停下去。" },
        { object: "Aside", text: "窗外变得十分安静，冬日的暖暖阳光照在大地上，居然也有了一丝温和。" },
        { object: "Aside", text: "仿佛就如同春天要到来了一般。" },
        { object: "Me", text: "是时候展现全新的我了！", music: "newMe" },
        { object: "Mouse", text: "吱吱！吱吱！" },
        { object: "Me", text: "滚吧你！" },
        { object: "Aside", text: "就这样，我的计划开始了。" },
        { object: "Aside", text: "我少有地走进小溪旁边，跳进清澈的河水里面，" },
        { object: "Aside", text: "洗了几乎是这辈子第一次澡。" },
        { object: "Aside", text: "在阳光的照射下，水温温的，有一种梦幻的美感。我在里面滚来滚去，希望能够把全身上下都洗个干净。" },
        { object: "Aside", text: "在长达半个小时的沐浴后，我感觉我比人类都要干净了。" },
        { object: "Me", text: "好！下一步，衣服！" },
        { object: "Aside", text: "别说。我还真有一件。" },
        { object: "Aside", text: "那还是在好几年前，从一直仓鼠那偷来的。" },
        { object: "Aside", text: "当初本来是当被子盖，没想到有一天真的会穿。" },
        { object: "Aside", text: "我把它拿了出来，洗了洗，然后晒干，就像新的一样。" },
        { object: "Aside", text: "我试了试，挺紧的，不过还行。" },
        { object: "Aside", text: "穿上后，对着小溪望向自己：" },
        { object: "Me", text: "我还是很帅的。" },
        { object: "Aside", text: "然后，我觉得人类应该都喜欢那种花香的气息。" },
        { object: "Aside", text: "于是我便四处打听，终于在一处花园内找到了留存的几朵野花。" },
        { object: "Aside", text: "我拿起花瓣嚼了几口，然后一直往身上蹭，反正什么方法都试了。" },
        { object: "Aside", text: "结果就是...挺有效的。除了差点被一只猫给捉到外。" },
        { object: "Me", text: "我的身体充满着花的香味...我说不定就是花仙子呢。" },
        { object: "Aside", text: "最后我还去教我人类语的老师那里，温习了一下我的人类语，还学习了一下一些文明用语。" },
        { object: "Aside", text: "顺便说一下，我的老师其实是一只呆若木鸡的虎皮鹦鹉。" },
        { object: "Aside", text: "穿上小礼服，散发着高雅的清香，如今的我已经是另一幅模样了呢。" },
        { object: "Me", text: "吱吱！...啊不对，要用学过的成语..." },
        { object: "Me", text: "万事俱备！出发！" },
        { object: "Aside", text: "这一天，怀着紧张而忐忑的心情，我又来到了那个名为嘉然小姐的房子门前。", music: "nothing" },
        { object: "Aside", text: "我跳了起来，摁了一下门铃。" },
        { object: "Aside", text: "【叮咚】", music: "doorRing" },
        { object: "Aside", text: "..." },
        { object: "Diana", text: "谁啊？" },
        { object: "Aside", text: "哗啦一下，门被打开了。" },
        { object: "Me", text: "你好...嘉然...小姐..." },
        { object: "Aside", text: "应该是这么说的吧..." },
        { object: "Diana", text: "嗨!鼠先生。进来吧，外面冷。" },
        { object: "Aside", text: "嘉然小姐穿着一身粉红色的小高腰，就连我也觉得可爱过头了。" },
        { object: "Diana", text: "我这里有一些吃的。看看有你喜欢吃的吗？" },
        { object: "Me", text: "这些...我都可以吃吗？" },
        { object: "Diana", text: "当然。" },
        { object: "Aside", text: "当时的我自然是毫不客气，冲上去就是一顿猛恰。" },
        { object: "Aside", text: "可吃到一半，我却才发现我有点无礼了，赶忙停了下来。" },
        { object: "Diana", text: "哼哼。" },
        { object: "Aside", text: "她朝我轻轻地笑了一下，是那么的阳光而甜蜜。" },
        { object: "Diana", text: "哦，对了。找我有何事呢？" },
        { object: "Aside", text: "这时我才想起来我的目的。" },
        { object: "Me", text: "啊...听闻小姐有一个绝妙的色彩组合，若之为一个糖果..." },
        { object: "Diana", text: "啊...你说是这幅画吗？" },
        { object: "Aside", text: "原来这个东西，叫画。" },
        { object: "Aside", text: "她从箱子里掏出了那张纸，果不其然，我又瞬间被吸引住了。" },
        { object: "Me", text: "啊...从前有幸见过一面，被完全吸引住了，" },
        { object: "Me", text: "半夜总是会产生奇怪的幻觉，属实是感到困惑。" },
        { object: "Diana", text: "听你这么说...你应该是做梦了。" },
        { object: "Me", text: "请问...梦到底是什么呢？" },
        { object: "Diana", text: "梦...有一种说法就是，梦是大脑潜意识的产物。" },
        { object: "Diana", text: "梦里的事情，总是那样荒诞不经，但是却又是充满想象力的。" },
        { object: "Diana", text: "实际上，现在的我们，也总是在不断地幻想着。" },
        { object: "Diana", text: "有时候，这便是我们前进的力量。", music: "whatsdream" },
    ],
    para1choice: [
        { object: "Me", choice: "怀疑", text: "幻想怎么能成为前进的力量呢？" },
        { object: "Me", choice: "疑惑", text: "我无法理解..." },
    ],
    para1AfterChoice0: [
        { object: "Diana", text: "人们经常会有一些看起来遥远而不切实际，但是又想去努力实现的想法。" },
        { object: "Diana", text: "我们称之为梦想。" },
        { object: "Diana", text: "梦想着未来的生活，梦想着以后的我们，梦想着将来的世界..." },
        { object: "Diana", text: "正是因为我们有了梦想，我们才不会仅仅停留在当下，" },
        { object: "Diana", text: "停留在本能的那一小小的满足。" },
        { object: "Diana", text: "我们想象着美好，我们冒险，我们超越，" },
        { object: "Diana", text: "我们希望，我们祈愿，我们幸福，全都源于那小小的梦想。" },
        { object: "Diana", text: "而这幅画，也是这小小梦想的一种表达。" },
        { object: "Diana", text: "想要试试吗？把你的梦想表达出来！" }
    ],
    para1AfterChoice1: [
        { object: "Diana", text: "我们总是在幻想，甚至把这份幻想代入梦境中。" },
        { object: "Diana", text: "总有些看似不切实际的想法，它们一直在牵引着我们的行为，让我们无不受影响。" },
        { object: "Diana", text: "那便是梦想。" },
        { object: "Diana", text: "在梦想中，我们期盼着未来，我们思考着幸福，我们充满了希望。" },
        { object: "Diana", text: "人类所创造的，毁灭的，表达出来的一切，痛苦的，快乐的，又或是充满抑郁的，" },
        { object: "Diana", text: "都与这小小的梦想有关。" },
        { object: "Diana", text: "梦想，是灵感的源泉。" },
        { object: "Diana", text: "你想试试吗？让你的梦想在纸上成为永恒！" }
    ],
    para2: [
        { object: "Aside", text: "说罢， 她就把一张空白的纸放在桌子上，还拿出了几个充满各式各样颜色的盒子。" },
        { object: "Diana", text: "将颜料粘在纸上，就可以创造伟大的作品！" },
        { object: "Aside", text: "我被她刚刚说的那一串话听懵了，好一阵子才反应过来..." },
        { object: "Me", text: "像这样？" },
        { object: "Aside", text: "我的脚粘了一点颜色，然后按在了纸上。" },
        { object: "Diana", text: "没错！" },
        { object: "Me", text: "天哪！" },
        { object: "Aside", text: "我大惊失色，发现自己原来也可以掌控颜色！" },
        { object: "Aside", text: "那些颜色，天空的颜色，河水的颜色，树林的颜色...我都可以掌握！" },
        { object: "Aside", text: "我突然感觉充满了各式各样的想法，想把它赶快展现出来！" }
    ],
    para2choice: [
        { object: "Aside", choice: "《毕嘉鼠》", text: "我的四只脚一下子沾上了许多颜料。" },
        { object: "Aside", choice: "《鼠鼠的幸福生活》", text: "我沾上了一些灰白灰白的颜料，在纸上画了几个点。" },
        { object: "Aside", choice: "《春天》", text: "我一想到那些花草的颜色，便马上在纸上留下了早春的痕迹。" },
    ],
    para2AfterChoice0: [
        { object: "Aside", text: "我在纸上大显身手，马上整张纸就变成了胡乱而抽象的一片，如同所说的干瘪的彩虹。" },
        { object: "Aside", text: "然后我又疯狂地涂抹着，" },
        { object: "Aside", text: "最后在中间画了一个小小的黑点，还画了一个高高的长方形物体。" },
        { object: "Diana", text: "这画的是什么呢？" },
        { object: "Me", text: "这是我在第一次见到【画】这个东西时，所表现的具体而又抽象的心理体现。" },
        { object: "Me", text: "自从知道绘画这一事物之后，我突然间有了方向，我想要成为同类间最出色的画家。" },
        { object: "Aside", text: "我头头是道地这么说着，然后将画面指向了一个黑点:" },
        { object: "Me", text: "这个是我。" },
        { object: "Aside", text: "然后又指向了那个长方形物体。" },
        { object: "Me", text: "这个就是嘉然小姐。" }
    ],
    para2AfterChoice1: [
        { object: "Aside", text: "然后我在中间最显眼的地方画上了一个巨大的黄色球形，上面打着许多粉红色的小点。" },
        { object: "Aside", text: "我还画了很多黄色的线条，看起来就像是在闪闪发光一样。" },
        { object: "Me", text: "搞定！" },
        { object: "Diana", text: "这是什么呢？" },
        { object: "Me", text: "啊...这，这便是我那第一次的【梦】..." },
        { object: "Me", text: "大家都围绕在糖果堆旁边，吃着数不尽的美食..." },
        { object: "Me", text: "大家都是如此快乐...大家都是如此幸福..." },
        { object: "Me", text: "要是真的有这么一个好地方...该多好啊..." }
    ],
    para2AfterChoice2: [
        { object: "Aside", text: "然后我又画了很多根绿色的线。" },
        { object: "Aside", text: "画上了蓝天...画上了白云..." },
        { object: "Aside", text: "画上了幸福的颜色。" },
        { object: "Diana", text: "这是什么？" },
        { object: "Me", text: "这...这就是春天..." },
        { object: "Me", text: "春天，一切都是那么得生机勃勃，充满了生命。" },
        { object: "Me", text: "不再那么寒冷，也不再那么无情..." },
        { object: "Me", text: "就像一位母亲一样，它创造着生命，孕育着生命..." }
    ],
    para3: [
        { object: "Diana", text: "哈哈！" },
        { object: "Aside", text: "嘉然小姐甜甜地笑道：" },
        { object: "Diana", text: "看来，你也是有梦想的小家伙了。" },
        { object: "Aside", text: "..." },
        { object: "Aside", text: "从此以后，我便一发不可收拾了。" },
        { object: "Aside", text: "饥饿，寒冷的问题仍旧困扰着我。" },
        { object: "Aside", text: "凛冽的寒冬仍旧没有过去。" },
        { object: "Aside", text: "但每过一段时间，我都会去拜访嘉然小姐，和她一起画画。" },
        { object: "Aside", text: "我慢慢意识到，生活并不是一成不变的。" },
        { object: "Aside", text: "我在绘画中释放我的灵感，我的想法不再拘泥于本能。" },
        { object: "Aside", text: "那些一直都困扰着我的问题，并不是理所应当的。" },
        { object: "Aside", text: "这些都是可以去改善的。" },
        { object: "Aside", text: "这些都是可以去改变的。" },
        { object: "Aside", text: "我的生活可以变得更好。" },
        { object: "Aside", text: "渐渐的，我发现我变了。" },
        { object: "Aside", text: "从不洗澡的我，现在每天都会花点时间清理自己。" },
        { object: "Aside", text: "原本邋遢混乱的小窝，也被我好好地打理了一遍。" },
        { object: "Aside", text: "虽然生活还是很糟糕，但却也不破败了。" },
        { object: "Aside", text: "我感觉也没有这么可怕了。" },
        { object: "Aside", text: "我做了很多以前从来不会做的事，我感觉我的生活有了真正的追求。" },
        { object: "Aside", text: "而不是单纯最基本问题的满足。" },
        { object: "Me", text: "嘉然小姐...或许真的是个巫女。" },
        { object: "Me", text: "她的咒语，居然让我对未来有了一丝丝期望..." },
        { object: "Aside", text: "..." },
        { object: "Aside", text: "..." },
        { object: "Aside", text: "就这样过去了一段时间。", music: "nothing" },
        { object: "Aside", text: "一天，我的脑海里突然蹦出了神奇而荒诞的想法，以至于一开始都把我给吓了一跳：" },
        { object: "Me", text: "我应该把绘画介绍给我的同伴。" },
        { object: "Me", text: "我们要共同画一副画！" }
    ],
    para3choice: [

    ]
}
export default chapter3