<template>
  <div>
    <img src="../../../public/img/dianaAndEillen.png"/>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
