export const start = {
    para1: [
        { object: "Me", text: "." },
        { object: "Me", text: ".." },
        { object: "Me", text: "..." },
        { object: "Ang", text: "嗯..." },
        { object: "Ang", text: "似乎是醒了？" }
    ],
    para1choice: [
        { choice: "我在哪...", text: "我，我在哪里...", object: "Me" },
        { choice: "我怎么了...", text: "怎么回事...发生了什么...", object: "Me" },
        { choice: "什么声音...", text: "这是什么声音...", object: "Me" },
    ],
    para1AfterChoice0: [
        { object: "Ang", text: "你在宇宙的边界。" },
        { object: "Ang", text: "我们正穿过最后一道墙，这也是这个虚拟世界的最后一块记忆。" },
        { object: "Ang", text: "可能再过不了多久，这最后的火焰声也要消失殆尽了。" }
    ],
    para1AfterChoice1: [
        { object: "Ang", text: "发生了一点小意外..." },
        { object: "Ang", text: "导致这个虚拟的世界就这样消失不见，我们正处在最后一道边界。" },
        { object: "Ang", text: "让你醒着去经历这一切，我不知道这属实是幸运还是悲哀。" }
    ],
    para1AfterChoice2: [
        { object: "Ang", text: "火焰的声音。就像在家一样，模拟地如此真实。" },
        { object: "Ang", text: "可惜这一切完美的构造，最后只剩下短小而残缺的记忆。" },
        { object: "Ang", text: "你还记得什么吗？希望你还能记得什么。" }
    ],
    para2: [

    ],
    para2choice: [
        { choice: "虚拟？", text: "你所说的虚拟指的是?...", object: "Me" },
        { choice: "看不见", text: "我什么也看不见...只有一片黑暗。", object: "Me" },
        { choice: "听不懂", text: "我完全听不懂你在说什么...", object: "Me" },
        { choice: "你是谁？", text: "你是谁？", object: "Me" }
    ],
    para2AfterChoice0: [
        { object: "Ang", text: "你必定是知道虚拟是什么意思。" },
        { object: "Ang", text: "意思便是...荒诞不经的一系列过程,所造成的的一系列荒诞不经的结果。" },
        { object: "Ang", text: "实际上，你便是这虚拟的第一把交椅。" }
    ],
    para2AfterChoice1: [
        { object: "Ang", text: "没有光，这是必然的。" },
        { object: "Ang", text: "这不是你的问题。因为任何能发光的事物都不存在了。" },
        { object: "Ang", text: "在很久以前，我亲眼见到一个又一个光子消失，不知道去了哪里。" }
    ],
    para2AfterChoice2: [
        { object: "Ang", text: "看来，你已经完全不记得之前所发生的事情了。" },
        { object: "Ang", text: "但应该没什么关系。这一切本来就没那么重要了。" },
        { object: "Ang", text: "你所作的，只是应该去享受接下来的过程。" }
    ],
    para2AfterChoice3: [
        { object: "Ang", text: "我...我是带领你走完这段漫长仪式的一个普通人罢了。" },
        { object: "Ang", text: "你也可以说我是个幽灵..." },
        { object: "Ang", text: "那一天，我从坟墓里爬出来，成了孤魂野鬼。" }
    ],
    para3: [
        { object: "Me", text: "你说了这么多,可我还是不明白你在说什么..." },
    ],
    para3choice: [
        { object: "Me", choice: "怎么做?", text: "所以说...接下来要做什么？" },
        { object: "Me", choice: "胡说八道！", text: "简直就是一派胡言！" },
    ],
    para3AfterChoice0: [
        { object: "Ang", text: "做什么...其实现在也暂时什么也做不了了。" },
        { object: "Ang", text: "唯一要做的，就是去感受。" },
        { object: "Ang", text: "去感受和那五位女孩的记忆吧，她们会帮助你到达新世界的。" },
        { object: "Me", text: "那五个女孩...?" },
        { object: "Me", text: "等等，你的意思是...?????" }
    ],
    para3AfterChoice1: [
        { object: "Ang", text: "随你怎么想了...不过在这样情况的情况下，这样也是十分正常的。" },
        { object: "Ang", text: "你本应该就那样迷迷糊糊地过去...可是现在，你得和我一起面对。" },
        { object: "Ang", text: "来吧，放松。多想想那五个女孩的事情..." },
        { object: "Ang", text: "我暂且退一段落..." },
        { object: "Me", text: "等等，我还没有..." }
    ],
    para4: [

    ],
    para4choice: [

    ]
}
export default start