<template>
  <div>
    <audio
      autoplay
      src="../../public/music/start.mp3"
      v-if="music === 'ding'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/fire.mp3"
      v-if="music === 'start'"
    />
    <audio
      autoplay
      src="../../public/music/start2door.mp3"
      v-if="music === 'start2door'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/space.mp3"
      v-if="music === 'doors'"
    />
    <audio autoplay src="../../public/music/into.mp3" v-if="music === 'into'" />
    <audio autoplay src="../../public/music/out.mp3" v-if="music === 'out'" />
    <audio
    loop
      autoplay
      src="../../public/music/bee.mp3"
      v-if="music === 'bee'"
    />
    <audio
    loop
      autoplay
      src="../../public/music/Mili - A wonderful tale.mp3"
      v-if="music === 'memory'"
    />
     <audio
    loop
      autoplay
      src="../../public/music/Nanaka - 蜜月Un·Deux·Trois（Cover 鏡音リン）.mp3"
      v-if="music === 'empty'"
    />
    <audio
    loop
      autoplay
      src="../../public/music/heart.mp3"
      v-if="music === 'heart'"
    />
    <audio
    loop
      autoplay
      src="../../public/music/error.mp3"
      v-if="music === 'error'"
    />
    <!-- Diana -->
    <audio
      autoplay
      loop
      src="../../public/music/snow.mp3"
      v-if="music === 'snow'"
    />
    <audio
      autoplay
      loop
      src="../../public/music/Martin Ermen - Gymnopedie Nr. 1.mp3"
      v-if="music === 'room'"
    />
    <audio
      autoplay
      src="../../public/music/image.mp3"
      v-if="music === 'image'"
    />
    <audio
      autoplay
      loop
      src="../../public/music/nervous.mp3"
      v-if="music === 'nervous'"
    />
    <audio
      autoplay
      loop
      src="../../public/music/dream.mp3"
      v-if="music === 'dream'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/newMe.mp3"
      v-if="music === 'newMe'"
    />
    <audio
      autoplay
      src="../../public/music/doorRing.mp3"
      v-if="music === 'doorRing'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/whatsdream.mp3"
      v-if="music === 'whatsdream'"
    />
    <audio
      autoplay
      src="../../public/music/kazuMusic.mp3"
      v-if="music === 'kazuMusic'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/八云轨 - 秒 · 莹露.mp3"
      v-if="music === 'dreamEnd'"
    />
    <!-- Bella -->
    <audio
      loop
      autoplay
      src="../../public/music/tiktok.mp3"
      v-if="music === 'tiktok'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/Ashram - Elizabeth.mp3"
      v-if="music === 'Why?'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/Ashram - All'Imbrunire.mp3"
      v-if="music === 'BellaEnd'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/Ashram - Tango para Mi Padre y Marialuna.mp3"
      v-if="music === 'past'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/Ashram - Fourth (unreleased).mp3"
      v-if="music === 'goodBye'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/Ashram - Ultimo carillon.mp3"
      v-if="music === 'back?'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/dark.mp3"
      v-if="music === 'dark'"
    />
     <audio
      loop
      autoplay
      src="../../public/music/night.mp3"
      v-if="music === 'night'"
    />
    <!-- Ava -->
    <audio
      loop
      autoplay
      src="../../public/music/fight.mp3"
      v-if="music === 'fight'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/hello.mp3"
      v-if="music === 'hello!'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/uBio高尾和树 - Secret Base (吉他版).mp3"
      v-if="music === 'rain'"
    />
    <audio
      autoplay
      src="../../public/music/hill.mp3"
      v-if="music === 'hill'"
    />
    <audio
    loop
      autoplay
      src="../../public/music/ゴンチチ - イツデモアナタト.mp3"
      v-if="music === 'mus'"
    />
    <audio
    loop
      autoplay
      src="../../public/music/Luca Stricagnoli - Thunderstruck.mp3"
      v-if="music === 'cage'"
    />
    <audio
    loop
      autoplay
      src="../../public/music/GIN - North Line.mp3"
      v-if="music === 'newLife'"
    />
    <audio
      autoplay
      src="../../public/music/door.mp3"
      v-if="music === 'door'"
    />
    <!-- Croal -->
    <audio
      autoplay
      src="../../public/music/funny.mp3"
      v-if="music === 'funny'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/people.mp3"
      v-if="music === 'people'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/¿Téo？ - Ascended Vibrations.mp3"
      v-if="music === 'tell'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/EveWang子荷 - 红色高跟鞋 伴奏.mp3"
      v-if="music === 'firstMeet'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/柳轻颂 - 溯（钢琴版）.mp3"
      v-if="music === 'reverse'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/无疆 - 云烟成雨（原版伴奏）.mp3"
      v-if="music === 'go'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/张小斐 - 萱草花 (哼唱版).mp3"
      v-if="music === 'wait'"
    />
    <!-- Eillen -->
    <audio
      loop
      autoplay
      src="../../public/music/city.mp3"
      v-if="music === 'city'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/忘乡 - 人间风景.mp3"
      v-if="music === 'picnic'"
    />
    <audio
      autoplay
      src="../../public/music/shock.mp3"
      v-if="music === 'shock'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/Mili - Unexpected.mp3"
      v-if="music === 'unexpected'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/月代彩 - あの小さな星空.mp3"
      v-if="music === 'spaceStar'"
    />
    <audio
    loop
      autoplay
      src="../../public/music/Luca Stricagnoli - Thunderstruck.mp3"
      v-if="music === 'truth'"
    />
    <audio
      autoplay
      src="../../public/music/earthquake.mp3"
      v-if="music === 'earthquake'"
    />
    <audio
      autoplay
      src="../../public/music/knock.mp3"
      v-if="music === 'knock'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/question.mp3"
      v-if="music === 'question'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/Mili - The cathedral which burns.mp3"
      v-if="music === 'allOver'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/Mili - Main theme.mp3"
      v-if="music === 'storyEnd'"
    />
    <!-- Me -->
    <audio
      autoplay
      src="../../public/music/didi.mp3"
      v-if="music === 'didi!'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/newBegin.mp3"
      v-if="music === 'newBegin'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/Mili - Madism.mp3"
      v-if="music === 'trueEnd'"
    />
    <!-- egg -->
    <audio
      loop
      autoplay
      src="../../public/music/dianaEgg.mp3"
      v-if="music === 'dianaEgg'"
    />
    <audio
      loop
      autoplay
      src="../../public/music/Victor Butzelaar - The Bird.mp3"
      v-if="music === 'Ang'"
    />

  </div>
</template>

<script>
export default {
  props: ["music"],
};
</script>
