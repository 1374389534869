export const chapter3 = {
    para1: [
        { object: "Aside", text: "现在并不算很晚。", music: "nothing" },
        { object: "Aside", text: "但这里的人通常早早地休息，所以大街上只有我一个人在孤独地奔跑。" },
        { object: "Aside", text: "我其实完全不需要如此着急。" },
        { object: "Aside", text: "可是我就是想要快点见到她，" },
        { object: "Aside", text: "跟她说一次告别，哪怕只有几分钟。" },
        { object: "Aside", text: "贝拉...贝拉..." },
        { object: "Aside", text: "我匆匆忙忙地跑到贝拉的家门口，冷静的敲了几下门。" },
        { object: "Aside", text: "开门的人是一个陌生人：" },
        { object: "Mandan", text: "你好？" },
        { object: "Me", text: "你好，请问贝拉在吗？" },
        { object: "Mandan", text: "哦，她去剧院了。你去那里看看吧。" },
        { object: "Aside", text: "我拔腿就跑，来到了剧院，一刻也不休息。" },
        { object: "Aside", text: "终于，我东问西问，在一个化妆室里，见到了她独自的身影，正在盯着一张看起来毫无特点的挂画。" },
        { object: "Aside", text: "这张挂画里面有五个女孩，其中一个还和贝拉长得特别像。" },
        { object: "Bella", text: "我似乎和她们有什么关系...可是我却又不太记得了..." },
        { object: "Bella", text: "她们到底是谁，总是出现在我的脑海之中呢？..." },
        { object: "Bella", text: "嗯？你怎么来了？", music: "goodBye" }
    ],
    para1choice: [
        { object: "Me", choice: "我要走了，贝拉", text: "贝拉...我要离开了。" },
        { object: "Me", choice: "没什么...", text: "没什么，只是想看看你。" },
        { object: "Me", choice: "你可真漂亮啊", text: "现在的你，可真是漂亮啊..." }
    ],
    para1AfterChoice0: [
        { object: "Bella", text: "啊？你要去哪里呢？" },
        { object: "Me", text: "去很远，很远的地方。可能要很久才会回来。" },
        { object: "Bella", text: "真是无情啊..." },
        { object: "Bella", text: "说好，要陪我去更大的舞台的呢？" },
        { object: "Me", text: "贝拉，我..." },
        { object: "Aside", text: "我的眼泪差点就流了下来。可是此时贝拉紧紧地抱住了我。" },
        { object: "Bella", text: "没关系的，傻瓜。人总是会有离别的。" },
        { object: "Bella", text: "我会一直等着你的，等着你一辈子。" },
        { object: "Bella", text: "我们会在北极星上相见的。" },
        { object: "Bella", text: "小时候，我祖母跟我说我其实是北极星上的天使。" },
        { object: "Bella", text: "北极星说要派一位天使去地球，为他们带来光芒而指引。" },
        { object: "Bella", text: "如今看来，说不定也真就是呢。" },
        { object: "Me", text: "哎，贝拉...我...我很抱歉..." },
        { object: "Me", text: "请等着我！我一定会回来的！" },
        { object: "Bella", text: "什么时候走呢？" },
        { object: "Me", text: "明天..." },
        { object: "Bella", text: "那确实有一件事，是十分可惜的了。" },
        { object: "Me", text: "啊？" },
        { object: "Bella", text: "因为明天，我就要去外地参加大型演出了！" }
    ],
    para1AfterChoice1: [
        { object: "Bella", text: "有时候你可真有意思。" },
        { object: "Aside", text: "她轻轻地笑了一笑，随后开始摆弄自己的身姿。" },
        { object: "Bella", text: "怎么样。这身好看吗？" },
        { object: "Aside", text: "这时候我才注意到，她穿着一身我从未见过的演出服，看起来就像仙女一般。" },
        { object: "Bella", text: "明天我就要去外地巡演了呢！那可是个大舞台！" },
        { object: "Bella", text: "比以往更多的人，更大的舞台！" },
        { object: "Bella", text: "怎么样，到时候你一定要来啊！" },
        { object: "Me", text: "我..." },
        { object: "Aside", text: "突然不知道为什么，几滴眼泪不争气地落了下来，正好被她给发现了：" },
        { object: "Bella", text: "你怎么了？我做错什么了吗？" },
        { object: "Me", text: "没...没什么..." },
        { object: "Me", text: "...只是，我要走了，贝拉。" },
        { object: "Me", text: "我要去很远的地方，可能很久才能回来。" },
        { object: "Me", text: "抱歉，可能今后的表演我都没法看了..." },
        { object: "Me", text: "请等着我！我一定会回来的！" },
        { object: "Bella", text: "没什么关系的。" },
        { object: "Bella", text: "傻瓜，别哭了。" },
        { object: "Bella", text: "我会一直等着你的。" },
        { obect: "Bella", text: "我们会在北极星上相见的。" }
    ],
    para1AfterChoice2: [
        { object: "Me", text: "她穿着一件白暂的演出服，一下子就把我吸引住了。" },
        { object: "Bella", text: "当然！你猜猜！我穿这身衣服干什么呢？" },
        { object: "Me", text: "我...我不知道..." },
        { object: "Bella", text: "笨蛋！当然是去演出啦！" },
        { object: "Me", text: "嗯？你不是前些日子才..." },
        { object: "Bella", text: "哈哈，想不到吧！" },
        { object: "Bella", text: "这次可不一样，这次我要去外地巡演！" },
        { object: "Bella", text: "更多的人，更广阔的舞台！" },
        { object: "Bella", text: "到时候会有好多人来看我的表演...说实话还有点紧张呢..." },
        { object: "Bella", text: "哎？你怎么了？你怎么哭了？" },
        { object: "Me", text: "没...没什么..." },
        { object: "Me", text: "只是我要走了，要去很远的地方...可能要很长时间。" },
        { object: "Me", text: "我是特地过来向你告别的..." },
        { object: "Me", text: "请等着我！我一定会回来的！" },
        { object: "Bella", text: "哎...人总有悲欢离合的嘛。" },
        { object: "Bella", text: "不过没关系的。" },
        { object: "Bella", text: "我，大聪明，会一直等着你回来的！" },
        { object: "Bella", text: "不管你去了哪里，" },
        { object: "Bella", text: "最终,我们都会在北极星上相见。" }
    ],
    para2: [
        { object: "Aside", text: "第二天很快就到来了。" },
        { object: "Aside", text: "那个大剧院里早早地就挤满了许多人。" },
        { object: "Aside", text: "他们一大早就来排队，只希望能够更早地来到这里。" },
        { object: "Aside", text: "他们热火朝天地谈论着，那个充满活力的女孩，注定是要闻名于全世界。" },
        { object: "People", text: "相信我，这将是一场奇迹！" },
        { object: "People", text: "今晚，她将会成为全国讨论的对象！" },
        { object: "People", text: "绝对是一场精彩的演出！" },
        { object: "Aside", text: "剧场里的人群拥挤着。" },
        { object: "Aside", text: "他们早就翘首以盼，等待着贝拉无与伦比的演出。" },
        { object: "Aside", text: "突然间，灯光暗了下来，大家一下子屏息凝神。" },
        { object: "Aside", text: "随后，如同往常那样，灯光瞬间聚集在了舞台中央！" },
        { object: "Host", text: "可以倒数了，各位！" },
        { object: "Aside", text: "大家一下子热烈欢呼起来，呼声比以往任何一场都要高！" },
        { object: "People", text: "10！" },
        { object: "People", text: "9！" },
        { object: "People", text: "8！" },
        { object: "People", text: "7！" },
        { object: "People", text: "6！" },
        { object: "People", text: "5！" },
        { object: "People", text: "4！" },
        { object: "People", text: "3！" },
        { object: "People", text: "2！" },
        { object: "People", text: "1！" },
        { object: "Rocket", text: "发射！" },
        { object: "Aside", text: "熊熊的火焰掀起阵阵烟尘，将火箭带离了地面。" },
        { object: "Aside", text: "燃料引擎很快就将我们代入到太空中。" },
        { object: "Aside", text: "火箭分离，我回望着我那离开的地方。" },
        { object: "Aside", text: "开始的它，是如此地庞大，以至于外面全是它的景色。" },
        { object: "Aside", text: "渐渐地，它就变成了了一个看起来像是圆形的球的东西，上面刻有许多美丽的花纹。" },
        { object: "Aside", text: "到了后台，它就变成了一个乒乓球大小的物体，似乎我一直手就可以捧住。" },
        { object: "Aside", text: "我死死地盯着它。" },
        { object: "Aside", text: "看着它从一个球变成一个圆，然后又变成一个点。" },
        { object: "Aside", text: "随后便是完全消失，再也无法看见。" },
        { object: "Aside", text: "剩下的，只有那宇宙中无尽的黑暗和数不尽的孤寂。" }
    ],
    para2choice: [

    ]
}
export default chapter3;
