<template>
  <div class="before">

    <div class="container">
        <div v-if="status === 'tips'" key="tips">
          <div class="tips">
          </div>
          <el-button type="text" @click="go" class="continue"
            >start</el-button
          >
        </div>
        <div v-if="status === 'logo'" key="logo">
          <img src="../../public/img/logo.jpg" class="logo" />
          <div>Fan-Made</div>
        </div>
        <div v-if="status === 'text'" key="text">
          <p class="text">我在哪？</p>
        </div>
    </div>
  </div>
</template>

<script>
import screenfull from "screenfull";
export default {
  data() {
    return {
      status: "tips",
    };
  },
  methods: {
    go() {
      this.status = "logo";
      this.clickFullscreen();
      setTimeout(() => {
        this.status = "text";
        this.$emit("handleMusic","ding");
        clearTimeout();
        setTimeout(() => {
          this.$emit("handleStatus", "before");
          clearTimeout();
        }, 1500);
      }, 5000);
    },
    clickFullscreen() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: "you browser can not work",
          type: "warning",
        });
        return false;
      }
      screenfull.toggle();
    },
  },
};
</script>

<style>
.before {
  background: white;
  height: 100vh;
  font-family: FZYaoti;
}
.before .container {
  text-align: center;
  padding-top: 10vh;
}
.before .container .continue {
  padding-top: 40vh;
  color: #000;
}
.before .container .logo {
  width: 50%;
  height: 50%;
}
.before .container .text {
  padding-top: 30vh;
  font-size: 5vh;
}
</style>
