export const chapter2 = {
    para1: [
        { object: "Ava", text: "各位，我说一件事...", music: "hello!" },
        { object: "Aside", text: "这个女孩似乎每一段时间都会来一次这里。" },
        { object: "Aside", text: "好巧不巧的是，她每次都会出现在我家对面。" },
        { object: "Aside", text: "每当我正好无所事事躺在家中的时候，" },
        { object: "Aside", text: "那个女孩就开始站在一个不知道哪里来的肥皂盒上，自信满满地说着写什么..." },
        { object: "Me", text: "...吵死了..." },
        { object: "Aside", text: "对于喜欢安静的我而言，这个声音就像是窗边的小鸟一样叽叽喳喳不停。" },
        { object: "Aside", text: "这一天，我终于按捺不住了。" },
        { object: "Aside", text: "我走下了楼，走向了那一片人群。" },
        { object: "Aside", text: "周围围着不少人。不过他们可不是在看一场激烈的群架，他们的面容十分地放松和平和。" },
        { object: "Aside", text: "我费力地挤了进去，勉强进了一个比较前面的位置。" },
        { object: "Ava", text: "最近我爱上了吃生蚝，" },
        { object: "Ava", text: "我拎着一袋生蚝走在路上，" },
        { object: "Ava", text: "生蚝的袋子破了，" },
        { object: "Ava", text: "生蚝掉进了泥土里，" },
        { object: "Ava", text: "这叫什么？" },
        { object: "Ava", text: "这就叫 【蚝喜欢泥】！DuangDuangDuangDuang！" },
        { object: "Aside", text: "一说到这里，她就开始谈起那吉他，来了段诙谐的小段奏。" },
        { object: "Ava", text: "哒哒！" },
        { object: "Audio", text: "哈哈...😅" },
        { object: "Audio", text: "啊哈哈...😅" },
        { object: "Me", text: "额..." },
        { object: "Me", text: "什么蠢节目..." },
        { object: "Aside", text: "但不知道为什么，等她再次到来的时候，我又来看了。" },
        { object: "Aside", text: "现场还是围着不少人，不过似乎比上次更多了。" },
        { object: "Ava", text: "咳咳！接下来是抢答环节！" },
        { object: "Ava", text: "请仔细听！" },
        { object: "Ava", text: "小动物们聚餐，只有大象伯伯很生气。" },
        { object: "Ava", text: "这是为什么呢？" },
    ],
    para1choice: [
        { object: "Me", choice: "因为这里是气象局。", text: "额...因为这里是气象局？" },
        { object: "Me", choice: "因为生气勃勃。", text: "嗯...因为生气勃勃？" },
        { object: "Me", choice: "不回答", text: "..." },
    ],
    para1AfterChoice0: [
        { object: "Ava", text: "..." },
        { object: "Ava", text: "答对啦！" },
        { object: "Ava", text: "完全正确！哒哒！" }
    ],
    para1AfterChoice1: [
        { object: "Ava", text: "嗯..." },
        { object: "Ava", text: "对了，但是又完全不对。" },
        { object: "Audio", text: "因为是气象局？" },
        { object: "Ava", text: "答对了！完全正确！哒哒！" }
    ],
    para1AfterChoice2: [
        { object: "Ava", text: "哈哈！没有人答得上来！" },
        { object: "Ava", text: "答案就是：" },
        { object: "Ava", text: "因为是气象局！boom！" },
    ],
    para2: [
        { object: "Aside", text: "哈哈！😅" },
        { object: "Aside", text: "众人发出了尴尬而奇妙的笑声。" },
        { object: "Ava", text: "好的，下一题！" },
        { object: "Ava", text: "你们知道足球队里最二次元的是谁吗？" },
    ],
    para2choice: [
        { object: "Me", choice: "守门员", text: "守门员？" },
        { object: "Me", choice: "前锋", text: "是前锋吗？" },
        { object: "Me", choice: "不回答", text: "..." }
    ],
    para2AferChoice0: [
        { object: "Ava", text: "完全正确！" },
        { object: "Ava", text: "因为...萌酱！" }
    ],
    para2AfterChoice1: [
        { object: "Ava", text: "不对哦！" },
        { object: "Ava", text: "其实是守门员！" },
        { object: "Ava", text: "因为是门酱！" }
    ],
    para2AfterChoice0: [
        { object: "Audio", text: "..." },
        { object: "Ava", text: "咚咚咚咚..." },
        { object: "Ava", text: "揭晓正确答案，守门员！" },
        { object: "Ava", text: "因为...门将！" }
    ],
    para3: [
        { object: "Audio", text: "这太蠢了，哈哈！" },
        { object: "Me", text: "我佛了，这也行😅" },
        { object: "Ava", text: "下一个，最后一题！" },
        { object: "Ava", text: "铛铛！" },
        { object: "Ava", text: "请问，" },
        { object: "Ava", text: "一个人生病了去医院打点滴，" },
        { object: "Ava", text: "然后她一直在那笑，这是为什么呢？" }
    ],
    para3choice: [
        { object: "Me", text: "因为她笑点高", choice: "因为...她笑点高？" },
        { object: "Me", text: "因为她笑点低", choice: "因为...她笑点低？" },
        { object: "Me", text: "不回答", choice: "..." },
    ],
    para3AfterChoice0: [
        { object: "Ava", text: "蹬蹬蹬..." },
        { object: "Ava", text: "不对哦！" },
        { object: "Ava", text: "其实，是因为她笑点低！" },
        { object: "Ava", text: "哈哈..." }
    ],
    para3AfterChoice1: [
        { object: "Ava", text: "蹬蹬蹬..." },
        { object: "Ava", text: "没错！" },
        { object: "Avao", text: "恭喜你答对啦！" }
    ],
    para4AfteChocie2: [
        { object: "Ava", text: "看来，没有人答得上哦!" },
        { object: "Ava", text: "其实，" },
        { object: "Ava", text: "其实是因为她笑点低辣..." }
    ],
    para4: [
        { object: "Audio", text: "啊，天哪..." },
        { object: "Audio", text: "我还以为是下雨了，原来是你给我整无语了...😅" },
        { object: "Aside", text: "不知道为什么，我感觉被气笑了。" },
        { object: "Aside", text: "一时间觉得非常无聊，又很有意思。" },
        { object: "Aside", text: "就是那种，看了两下觉得好怪，但是又想去再看两眼的那种感觉。" },
        { object: "Aside", text: "我又听了几个她讲的笑话。" },
        { object: "Aside", text: "每次她讲完这些笑话的时候，配合那种诙谐的吉他曲调，还有那怪萌怪萌的大舌头，" },
        { object: "Aside", text: "虽然讲得笑话都很冷，很无聊。" },
        { object: "Aside", text: "但是整体上却又充满了搞笑气息，弄得大家阵阵发笑。" },
        { object: "Aside", text: "这种少有而奇怪的氛围就像是一种传染病，一下子就传染给了每个站在这里的人。" },
        { object: "Aside", text: "又像是在每个人的心里放了一个痒痒挠，只要稍微动一下，就忍不住充满快活的气息。" },
        { object: "Aside", text: "有一种难以言说的上头，让人久久无法摆脱。" },
        { object: "Ava", text: "好，今天的演出就到这里，我们下期再见！" },
        { object: "Ava", text: "拜拜！" },
        { object: "Audio", text: "再见！" },
        { object: "Me", text: "...", music: "rain" },
        { object: "Aside", text: "..." },
        { object: "Aside", text: "下雨了。" },
        { object: "Aside", text: "在这座不雨城，下起了绵绵细雨。" },
        { object: "Aside", text: "我似乎很久，没有在这个城市里见过下雨了。" },
        { object: "Aside", text: "几乎整日整夜，都是那难以忍受的高温。" },
        { object: "Aside", text: "鸡蛋放在地上都能被烤熟，钥匙掏出来都可能要融化。" },
        { object: "Aside", text: "毒辣的太阳鞭打着大地，企图将这里的一切水分都榨的干干净净，流下一具干瘪的尸骨。" },
        { object: "Aside", text: "偶尔当然还会有雨水降临大地，" },
        { object: "Aside", text: "但那不叫下雨。" },
        { object: "Aside", text: "而是一场更加无情的风暴。" },
        { object: "Aside", text: "这里居然下起了绵绵细雨。" },
        { object: "Aside", text: "它就像青涩的大姐姐一样温柔。" },
        { object: "Aside", text: "我不由得走了出去，在这曼妙的细语中轻松地漫步。" },
        { object: "Aside", text: "感触着她的身体，感受着她的抚摸，" },
        { object: "Aside", text: "仿佛已经涌入她的怀抱，回到那个在人生中还算是美好的时代，" },
        { object: "Aside", text: "从那些不知道哪里来的小册子里面看到的那些美丽的景象，还有那些总是牵动人心的词语。" },
        { object: "Me", text: "【春天】，【美丽】，【生机】，【和平】..." },
        { object: "Me", text: "所有人都能愉快的相处，哪怕只是一瞬。" },
        { object: "Aside", text: "我朦朦胧胧的瞥见了那个美好的未来，倒影中似乎浮现出一丝丝笑脸。" },
        { object: "Aside", text: "突然间，我似乎又发现了从前的自己。" },
        { object: "Aside", text: "沉浸在这细雨中，那暴躁的脾气，暴力的态度，再也消失不见了。" }
    ],
    para4choice: [

    ]
}
export default chapter2;
