export const myTalk = {
    start: [
        "？？？",
        "这里...这里又是哪里...",
        "这些...又是什么...",
        "我要做什么...",
        "可恶...为什么这么快就走了...",
        "...",
        "我想想看..."
    ],
    Diana: [
        "不！嘉然！",
        "不！我仅存的记忆！"
    ],
    Bella: [
        "我的贝拉！我的贝拉！",
        "我把这一切都毁了！"
    ],
    Ava: [
        "向晚！我的爹！",
        "我在干什么？！"
    ],
    Croal: [
        "珈乐，不！快停下！",
        "我的那些记忆！"
    ],
    Eillen: [
        "我的乃琳！",
        "..."
    ],
    end: [
        "不...完了...一切都完了...",
        "我毁掉了我最后的记忆。",
        "这个虚拟世界最后的存在。",
        "那五个女孩的故事...",
        "已经永远结束了...",
        "没了...",
        "我已经什么也不存在了...",
        "...",
        "...",
        "结束了。"
    ],
    fakeA: [
        "...",
        "嗨，我回来了。",
        "很高兴，虚拟世界已经被完全抹除干净。",
        "至少在我看来是这样子的。",
        "我...应该说是你。",
        "成功把他送到了一个新的世界。",
        "在那块世界里，他将会度过新的生活。",
        "...",
        "很抱歉。",
        "本来这一切都是我的职责，可是把你也牵扯了进来。",
        "但是，你做的很好！",
        "故事已经结束了。",
        "而你，只用当做一个游戏就好。",
        "好了，这里已经什么也没有了。",
        "其实我也该走了。",
        "你也可以关掉了...或者卸载掉...做什么都好...",
        "出去散散步，骂骂作者什么的...",
        "随你便了。",
        "好了，再见。",
        "游戏已经结束了。"
    ],
    fakeB: [
        "已经...已经没东西了...",
        "现在就剩下这白茫茫的一片了。",
        "还有我...",
        "不过马上，我也要消失了..."
    ],
    fakeC: [
        "你为什么还在这里？",
        "你怎么还没有关掉游戏？",
        "...",
        "看来，你是一个难以满足的人。",
        "我的时间不多吧。",
        "想知道那个人去了新世界之后怎么样了吗？",
        "说出来也挺有意思的，不过我想还是你亲自去看看会比较好。",
        "说错了...其实还是在黑听...",
        "好...使用F12，或者是右键点击审查元素...",
        "会弹出一个奇怪的窗口...所有的秘密都在这里面了...",
        "打开一个叫Application的栏目...里面有个叫localStroage的地方，进入...",
        "你会发现有一些东西...这些东西是控制这个世界的关键钥匙...",
        "然后,找到一个叫INITIAL的参数...把它修改成ASOUL。",
        "没错。这就是关键字。记得刷新。",
        "好了，我已经没有时间了。",
        "这些话，我只能说一次...",
        "永别了。"
    ]

}
export default myTalk;
