<template>
  <div class="end">
    <transition name="fade" mode="out-in">
      <div class="container" v-if="status">
        <div v-if="num === 0">
          {{ end.first }}
        </div>
        <div v-if="num === 1">
          {{ end.second }}
        </div>
        <div v-if="num === 2">
          {{ end.third }}
        </div>
        <div v-if="num === 3">
          {{ end.forth }}
        </div>
        <div v-if="num === 4">
          <p>{{ end.present[0] }}</p>
          <p>{{ end.present[1] }}</p>
        </div>
        <div v-if="num === 5" class="title">
          <p>
            {{ end.title }}
          </p>
        </div>
        <div v-if="num === 6">
          {{ end.over }}
          <p v-for="(follow, index) in end.follows" :key="index">
            <span>{{ follow.name }}</span>
            <a :href="follow.link" target="_blank">{{ follow.link }}</a>
          </p>
        </div>
        <div v-if="num === 7">
          <p>{{ endOption.title }}</p>
          <p>{{ endOption.object }}</p>
        </div>
      </div>
    </transition>
    <div class="handle-button" v-if="num < 7">
      <el-button icon="el-icon-right" @click="next" />
    </div>
  </div>
</template>

<script>
import End from "../assets/Dbace/end.js";
import db from '../utils/loaclStorage.js';
export default {
  data() {
    return {
      end: End,
      endOption: "",
      num: 0,
      status: true,
    };
  },
  methods: {
    next() {
      this.num++;

      this.status = !this.status;
      setTimeout(() => {
        this.status = !this.status;
        clearTimeout();
      }, 1);
      if (this.num === 7) this.showEndList();
    },
    showEndList() {
      var index = 0;
      setInterval(() => {
        if (index < this.end.endList.length) {
          this.endOption = this.end.endList[index];
          index++;
          this.status = !this.status;
          setTimeout(() => {
            this.status = !this.status;
            clearTimeout();
          }, 1);
        }
      }, 3000);
    },
  },
};
</script>

<style scoped>
.end {
  height: 100vh;
  background: white;
  font-family: FZYaoti;
}
.end .container {
  padding-top: 30vh;
  padding-left: 20vh;
  padding-right: 20vh;
  text-align: center;
  height: 50vh;
}
.end .handle-button {
  text-align: center;
}
.end .handle-button button {
  color: rgba(0, 0, 0, 0.438);
  border: transparent;
  background: transparent;
}
.end .handle-button button:hover {
  color: rgb(0, 0, 0);
}
.end .container .title {
  font-size: 50px;
}
</style>
