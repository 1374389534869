export const chapter1 = {
    para1: [
        { object: "Aside", text: "冷。", music: "snow" },
        { object: "Aside", text: "好冷。" },
        { object: "Aside", text: "我只能畏畏缩缩地游走在这些巨大的水泥建筑物之间，" },
        { object: "Aside", text: "才不会被那又是风又是雪的怪物撕的个粉碎。" },
        { object: "Aside", text: "狭隘的视线微微上抬着，想看看有没有值得我探索的新住处。" },
        { object: "Aside", text: "可似乎除了黑得要把我吸进去的毫无生机的窗户外，" },
        { object: "Aside", text: "似乎再也没有什么看起来富有生命力的地方。" },
        { object: "Aside", text: "除此之外，便是一片白茫茫的凶险雪地。" },
        { object: "Me", text: "哎。" },
        { object: "Aside", text: "我失望地吱吱着，声音飞到我耳边两三米外的地方，消失不见了。" },
        { object: "Aside", text: "可却还是要继续去寻找着，" },
        { object: "Aside", text: "或者是去面临着继续饿肚子的命运。" },
        { object: "Aside", text: "现在想想着...又有多少天没吃东西了呢？" },
        { object: "Aside", text: "似乎是从隔壁的那位一倒不起后开始..." },
        { object: "Me", text: "..." },
        { object: "Aside", text: "出了事之后，这里的雪就下个不停。" },
        { object: "Aside", text: "似乎是要将这里活埋，让后人找不到方向。" },
        { object: "Aside", text: "吃。繁殖。等死。" },
        { object: "Aside", text: "与腐烂物共存，世间之污垢，都与我们有关。" },
        { object: "Aside", text: "这是我们的生活，也是我们的死亡。" },
        { object: "Aside", text: "天生的我们，便是天生的苦难..." },
        { object: "Aside", text: "除此之外，已经毫无意义..." },
        { object: "Me", text: "..." },
        { object: "Me", text: "我..." },
        { object: "Me", text: "好冷..好饿..." },
        { object: "Me", text: "..." },
        { object: "Me", text: "等一下！" },
        { object: "Aside", text: "在不远的转角处，居然还有一处明亮而温暖的灯光！" },
        { object: "Aside", text: "自从出现那些难以理解的动荡后，似乎过去的光芒便消失了。" },
        { object: "Aside", text: "但这里，必定还是最后的余辉！" },
        { object: "Aside", text: "刚刚那没用的哲学思考已经完全被抛散了。" },
        { object: "Aside", text: "精疲力尽的我突然间又有了力量，" },
        { object: "Aside", text: "我转过转角，一下子蹿上地阶，飞奔过去，跨越如沼泽地般的雪地，跳过两层围栏，" },
        { object: "Aside", text: "终于来到了这个最后的希望面前。" },
        { object: "Me", text: "太好了！这里面一定有很多吃的！吱吱！" },
        { object: "Aside", text: "作为天生的小偷家，总是对周围的环境有着超长的观察力。" },
        { object: "Aside", text: "绕了一圈，我发现后门的边上有一个小缝，加把力说不定可以钻进去。" },
        { object: "Aside", text: "测边的窗户也并不是完全封闭的。" },
        { object: "Aside", text: "一面墙上有一个小小的排气口，稍微跳一跳应该也可以进去。" },
        { object: "Aside", text: "似乎都还可以。" },
    ],
    para1choice: [
        { object: "Aside", choice: "从门缝进去", text: "我还是窜向了门缝，努力地蠕动着。" },
        { object: "Me", choice: "从窗户进去", text: "还是从窗户进去吧。" },
        { object: "Aside", choice: "从排气口进去", text: "我望向了排气口。" }
    ],
    para1AfterChoice0: [
        { object: "Me", text: "可恶..." },
        { object: "Me", text: "再加把劲..." },
        { object: "Aside", text: "狭窄的门缝快把我的内脏都挤出来了，但最终我还是成功了。" },
        { object: "Aside", text: "终于和凌冽的风雪暂时告一段落，仅有的小小本能，似乎也要被满足了。" }
    ],
    para1AfterChoice1: [
        { object: "Me", text: "嗯..." },
        { object: "Aside", text: "似乎这个房间里还没有人。" },
        { object: "Aside", text: "我轻轻地移动着，像一个肥皂似的滑了进去，没有发出一点声响。" },
        { object: "Aside", text: "似乎很安静。" },
        { object: "Aside", text: "但是我却有一种强烈的预感，那份能满足我仅有本能的东西，就在我的附近。" }
    ],
    para1AfterChoice2: [
        { object: "Me", text: "从闻着的味道上来看..." },
        { object: "Me", text: "这里似乎最靠近厨房。" },
        { object: "Aside", text: "我努力跳跃着，终于让小概率事件得到了发生。" },
        { object: "Aside", text: "娴熟地溜了进去，就像从前走过的那些地方。" },
        { object: "Aside", text: "只不过，它们都已经成了过去。" },
        { object: "Aside", text: "突然，我脚下一空，摔在了地上，幸好没发出什么声响。" },
        { object: "Aside", text: "我闻到了食物的香味。它似乎离我很近，可是却又很远。" }
    ],
    para2: [],
    para2choice: [

    ]
}
export default chapter1
