export const End = {
    first: "因为某种不可控力，原本的虚拟世界已经变得分离崩析。作为沉睡在虚拟世界的主人公，漫游在宇宙的最后一道边界。神志不清的你，脑海里仅剩下那五个世界中和五个女孩残缺的记忆...",
    second: "但你没有意识到的是，一个新的世界正在向你缓缓招手。当你彻底醒悟后，你发现自己已经是这个新故事的书写人之一...",
    third: "你不再犹豫。但未来的路，究竟会如何发展呢？",
    forth: "全剧终",
    title: "-黑听-",
    present: ["toope  制作",
        "献给A-SOUL，以及那始终陪伴的一个魂们"
    ],
    over: "你已经游玩了游戏的全部内容！感兴趣的话可以关注一下A-SOUL哦！",
    follows: [{
            name: "嘉然",
            link: "https://space.bilibili.com/672328094/"
        },
        {
            name: "贝拉",
            link: "https://space.bilibili.com/672353429/"
        },
        {
            name: "向晚",
            link: "https://space.bilibili.com/672346917/"
        },
        {
            name: "珈乐",
            link: "https://space.bilibili.com/351609538/"
        },
        {
            name: "乃琳",
            link: "https://space.bilibili.com/672342685/"
        },
        {
            name: "A-SOUL官方账号",
            link: "https://space.bilibili.com/703007996/"
        },
        {
            name: "作者",
            link: "https://space.bilibili.com/50964470/"
        }
    ],
    endList: [
        { title: "引擎支持", object: "VUE" },
        { title: "编码", object: "toope" },
        { title: "剧本撰写", object: "toope" },
        { title: "结构设计", object: "toope" },
        { title: "美术", object: "toope" },
        { title: "路线设计", object: "toope" },
        { title: "背景音乐", object: "网络资源" },
        { title: "音效设计", object: "网络资源" },
        { title: "发布方", object: "toope" },
        { title: "鸣谢", object: "所有人" },
        { title: "", object: "感谢游玩！" },
    ]
}
export default End;