export const chapter6 = {
    para1: [
        { object: "Aside", text: "时间...时间确实就是个玩笑..." },
        { object: "Aside", text: "等我们回到地球的时候。却发现城市的样貌已经完全变了。" },
        { object: "Aside", text: "昔日的小镇变得高楼林立，再也看不出出来以前的痕迹。" },
        { object: "Me", text: "不...不..." },
        { object: "Me", text: "可恶...我找不到她的照片了..." },
        { object: "Me", text: "现在是什么时候?" },
        { object: "Teammate", text: "天哪！地球已经过去了两百年！" },
        { object: "Me", text: "...你...你说什么？" },
        { object: "Teammate", text: "已经过去两百年了，队长！" },
        { object: "Aside", text: "我一下子愣住了，好久都没有出声。" },
        { object: "Me", text: "不...不..." },
        { object: "Aside", text: "我几乎是疯了。我一下子冲上前去，没逮到一个路人就上去问：" },
        { object: "Me", text: "你认不认识贝拉？" },
        { object: "Me", text: "你认不认识贝拉？" },
        { object: "Me", text: "你认不认识贝拉？！" },
        { object: "Passer", text: "你干什么..." },
        { object: "Passer", text: "先生，我不认识，请放开我..." },
        { object: "Passer", text: "世界上有太多人叫贝拉了..." },
        { object: "Teammate", text: "天哪，队长...别这样，你冷静点..." },
        { object: "Me", text: "你叫我冷静！你叫我冷静！" },
        { object: "Passer", text: "贝拉？你是说两个世纪前那个优秀的舞蹈家吗？" },
        { object: "Me", text: "是！是！快带我见她！" },
        { object: "Passer", text: "她是我们这里的历史名人呢！可惜似乎现在许多人都不认识了...请跟我走，先生。" },
        { object: "Me", text: "对不起，我有点激动了..." },
        { object: "Aside", text: "..." },
        { object: "Aside", text: "我们终于回到了地球，" },
        { object: "Aside", text: "才发现自己已经成了往事之人。" },
        { object: "Aside", text: "行走在陌生而孤独的土地上，我们每个人都跟丢了魂似的，" },
        { object: "Aside", text: "如同一群古代的难民，艰难地行进着。" },
        { object: "Aside", text: "在不久地将来会是我们早已预料到却又无法接受的结果，" },
        { object: "Aside", text: "那个在我看来熟悉的面庞，其实是她的后人，" },
        { object: "Aside", text: "带着我们一步步拐弯抹角，引入这个迷幻世界的深处。" },
        { object: "Aside", text: "她终于停下了脚步。我抬头望去，看见一个矮小的石碑，上面驻足着一个一百四十年前变得沉寂的幽灵。" },
        { object: "Aside", text: "她仍旧在上面轻盈地舞蹈者，仿佛她的生命并未从此停歇..." },
        { object: "Passer", text: "贝拉还流传了一个美丽的传说呢！据说在她年轻的时候，她有一个非常伟大的粉丝..." },
        { object: "Me", text: "那确实是一个美丽的传说。" },
        { object: "Passer", text: "可惜那个男子离开了她，去了很远的地方。" },
        { object: "Passer", text: "她等啊，等。" },
        { object: "Passer", text: "在她进行全国巡演时，她在等他。" },
        { object: "Passer", text: "在她腰伤复犯，被造谣诽谤时，她在等他。" },
        { object: "Passer", text: "在她成为顶流偶像，功成身退时，她在等他。" },
        { object: "Passer", text: "她等了一辈子，也没有等到他回来。" },
        { object: "Me", text: "..." },
        { object: "Bella", text: "傻瓜，我们会在北极星见面的。" },
        { object: "Me", text: "不，你错了，贝拉。" },
        { object: "Me", text: "我们在北极星，见到的只是互相之间虚假的幻像。" },
        { object: "Me", text: "可现在，我们真真切切地站在对方面前，就像当初一样。" },
        { object: "Me", text: "贝拉...我回来了...我没有失守承诺。" },
        { object: "Me", text: "看看我贝拉..." },
        { object: "Me", text: "请看看我...哪怕这已经是最后一眼..." },
        { object: "Me", text: "贝拉...我很抱歉..." },
        { object: "Me", text: "我万万没想到，我们只是做了个梦，却已然度过你的一生。" },
        { object: "Aside", text: "【完】" }
    ],
    para1choice: [

    ]
}
export default chapter6
