export const chapter2 = {
    para1: [
        { object: "Aside", text: "我就这么大摇大摆地走着，和周围的人说着早安，就像日常那样。", music: "nothing" },
        { object: "Aside", text: "可是，当我终于来到那办公室的时候，我发现出了奇的安静，仿佛被施了静止的魔法。" },
        { object: "Aside", text: "我突然意识到有些不对劲。" },
        { object: "Aside", text: "我站直了身子，缓缓地推开了大门。", music: "tiktok" },
        { objecct: "Aside", text: "发现那个白发苍苍的老人仍旧一动不动地坐在他那起灰的椅子上，" },
        { object: "Aside", text: "而对面都是我的几个队友。" },
        { object: "Aside", text: "他们的神情有些严肃，似乎带有心事，和昨日晚上的狂欢判若两人。" },
        { object: "Aside", text: "他们给我留了张中间的椅子。我坐了下来，就这样面对着他：" },
        { object: "Old", text: "好了...居然都到齐了，那我们就开始吧..." },
        { object: "Aside", text: "他终于动了一下，掀起阵阵烟尘。" },
        { object: "Me", text: "发生什么了？" },
        { object: "Old", text: "咳咳..." },
        { object: "Old", text: "你还记得上个世纪发射的【北极星】号探测器吗？" },
        { object: "Me", text: "当然...已经失踪很久了..." },
        { object: "Aside", text: "随后，他拿出来一直铅笔。" },
        { object: "Aside", text: "他将它悬在半空中，然后松手，" },
        { object: "Aside", text: "铅笔并没有直直地坠落，而是像一片羽毛一样，缓缓地飘散着！" },
        { object: "Teammate", text: "天哪！！！" },
        { object: "Old", text: "失重？并不是。你我都好好地坐在这里。这是幻象。" },
        { object: "Me", text: "这是怎么回事？" },
        { object: "Old", text: "我们找到北极星号了。它现在停留在一颗小星球上面。" },
        { object: "Old", text: "但我们没有想到的是，这个探测器里面居然有一块镜像物质。" },
        { object: "Old", text: "它将地球上的景象投影到了那个星球，同时，它星球上的现象也会投影到地球上。" },
        { object: "Old", text: "这一切，其实都是幻像。但随着这种幻像越来越多，我们恐怕很难在区分出来，" },
        { object: "Old", text: "到时候人类的生活必将是一片混乱。" },
        { object: "Me", text: "要我们做什么？把它拿回来？" },
        { object: "Old", text: "不不...直接就地销毁，这东西对我们目前根本有害而无利。" },
        { object: "Aside", text: "随后不知道为什么，便是一阵沉默。" },
        { object: "Me", text: "能不能现在就告诉我们是在哪个星球？" },
        { object: "Old", text: "在那。" },
        { object: "Aside", text: "随后他指了一下天窗。那是对准北极星的位置。" },
        { object: "Old", text: "我们走过去其实根本不需要多久..." },
        { object: "Old", text: "但是这颗星球靠近一颗黑洞，时间过得非常缓慢。" },
        { object: "Old", text: "我们估算了一下，可能你们全程需要五天的时间，" },
        { object: "Old", text: "但地球至少已经过了五年。" },
        { object: "Me", text: "..." },
        { object: "Old", text: "怎么样？还有什么问题吗？" },
    ],
    para1choice: [
        { object: "Me", choice: "接受行动", text: "没问题了。我同意这次行动。" },
        { object: "Me", choice: "为什么不派其他人？", text: "为什么不派其他人去？为什么非要派我们组呢？" },
        { object: "Me", choice: "考虑一下", text: "对不起，我想我需要一些时间考虑一下..." }
    ],
    para1AfterChoice0: [
        { object: "Old", text: "那其他人呢？你们有什么问题吗？" },
        { object: "Teammate", text: "没什么问题。" },
        { object: "Teammate", text: "就这样吧！" },
        { object: "Old", text: "好，居然没有问题，那各位这几天准备一下吧。" },
        { object: "Old", text: "该给谁打招呼就给谁打打招呼，毕竟是要去不久的时间的。" },
        { object: "Old", text: "好，散会了。祝一帆风顺！" },
        { object: "Aside", text: "我和我的队员们都离开了。" },
        { object: "Aside", text: "我没想到，我居然这么轻易就接下了这场任务。" },
        { object: "Aside", text: "就连我的同伴们也一样。" },
        { object: "Aside", text: "我突然间有些感到害怕，感觉这并不是一场平常的任务。" },
        { object: "Aside", text: "我隐隐约约地感受到这场旅行并不会这么顺从任意。" },
        { object: "Aside", text: "在时间面前，人类永远只是个笑话。" },
        { object: "Aside", text: "呼之即来，挥之即去。" },
        { object: "Aside", text: "但有的时候又想想，觉得这不就是我们应该做的事情么？" },
        { object: "Aside", text: "这不就是我们身处在如此状态下的使命么？" },
        { object: "Aside", text: "我不再想太多，只觉得应该做好当下的事，迎接新的冒险。" }
    ],
    para1AfterChoice1: [
        { object: "Old", text: "我们...我们自然也想过..." },
        { object: "Old", text: "可是在这么多人中，你们几乎是唯一见过并且使用过镜像物质的人。" },
        { object: "Me", text: "我可还没见过镜像物质可以这么用..." },
        { object: "Old", text: "可你们已经比别了解太多了。" },
        { object: "Old", text: "不是你们，恐怕连镜像物质在哪都找不到。" },
        { object: "Old", text: "所以思来想去，我们都觉得你们小组是最好的人选。" },
        { object: "Old", text: "这确实不是一场常规任务..." },
        { object: "Old", text: "所以，这次也比不算一次命令。" },
        { object: "Old", text: "倒更像是一种请求..." },
        { object: "Me", text: "..." },
        { object: "Old", text: "如果我可以，我一定亲自上去。可惜我做不到。" },
        { object: "Old", text: "我已经老了。" },
        { object: "Me", text: "好了，别说了。" },
        { object: "Me", text: "但我需要目前最好的装备，最好的供给。" },
        { object: "Me", text: "这是一场冒险。" },
        { object: "Me", text: "我愿意接受。" },
        { object: "Me", text: "你们呢？" },
        { object: "Teammate", text: "你都说没问题了，我们还留在这干嘛呢？" },
        { object: "Teammate", text: "当然是跟着你了，队长！" },
        { object: "Old", text: "我非常感谢...真的..." },
        { object: "Me", text: "把你的这份心意，留到我们回来的时候再说吧。" },
        { object: "Me", text: "愿到时候你还记得我们。" },
        { object: "Aside", text: "我们又说了些其他的安排，然后就散会了。" },
        { object: "Aside", text: "接受了这次任务，我感觉内心一阵沉重，但是却又感觉放下了什么，一阵轻松。" }
    ],
    para1AfterChoice2: [
        { object: "Old", text: "好的，没有问题。" },
        { object: "Old", text: "但是可能没有太多的时间。" },
        { object: "Old", text: "最晚一个星期后就要出发。" },
        { object: "Old", text: "那个时候不走的话，下一次就要等到半年之后。" },
        { object: "Old", text: "那个时候，幻像估计已经开始侵入所有人的日常生活之中了。" },
        { object: "Old", text: "对不起，但是事情就是这么严峻。" },
        { object: "Old", text: "给三天的时间，可以吗？" },
        { object: "Me", text: "..." },
        { object: "Me", text: "没有问题。" },
        { object: "Aside", text: "大伙就这样散会了。" },
        { object: "Aside", text: "明明是大白天，我却浑浑噩噩地走着，感觉冷得在发抖。" },
        { object: "Aside", text: "我知道考虑其实并不可以改变什么结果。" },
        { object: "Aside", text: "我只是想找个理由，让一切不要来得这么迅猛。" },
        { object: "Aside", text: "我感觉到迎头一棒，差点被击倒。" },
        { object: "Aside", text: "在孤独的宇宙中看着地球的时间就这样逝去，" },
        { object: "Aside", text: "这种滋味实在是难以忍受。" },
        { object: "Aside", text: "时间是宝贵的。可是现在，它却就像一个玩笑。" },
        { object: "Aside", text: "轻松筑起我们的一切，可又随时会毁于一旦。" }
    ],
    para2: [
        { object: "Aside", text: "...", music: "night" },
        { object: "Aside", text: "夜幕降临，皎洁的月光缓缓地升起来了。" },
        { object: "Aside", text: "天空中抹上了一轮淡淡的黑紫色。" },
        { object: "Aside", text: "随后，便是那星星闪耀的舞台。" },
        { object: "Aside", text: "红的、蓝的、紫的、黄的，它们就像舞台中央的少女，" },
        { object: "Aside", text: "闪烁着，跃动着，迸发着属于自己的光芒。" },
        { object: "Aside", text: "而北极星，就是那天空中最亮的那一颗。" },
        { object: "Aside", text: "无论何时何地，它都不会挪动位置，悬挂在北天极之上。" },
        { object: "Aside", text: "似乎是在指引人们方向。" },
        { object: "Aside", text: "也似乎是在等待人们回家。" },
        { object: "Aside", text: "我和我的队友们，坐在基地旁边的阳台上。" },
        { object: "Teammate", text: "哎，最后一天了。" },
        { object: "Me", text: "怎么样，和你家人都打好招呼了么。" },
        { object: "Teammate", text: "哎。其实真想谈，也没什么好谈的。本来就多少年没见面了。" },
        { object: "Teammate", text: "我也是。我父母当初还叫我别回去了。不过我想这次以后，我要回家一趟。" },
        { object: "Me", text: "至少五年的时间...但对于我们来说只过了几天而已..." },
        { object: "Teammate", text: "害，其实也没什么不好。" },
        { object: "Teammate", text: "干五天的活，就可以拿五年的工资呢！" },
        { object: "Teammate", text: "说的也是，哈哈。而且现在科技发展这么快，说不定以后虚拟设备就很先进了呢。" },
        { object: "Teammate", text: "说不定过几天回来我就可以抱着我的二次元老婆睡觉了呢！" },
        { object: "Teammate", text: "可别惦记你那二次元了！" },
        { object: "Teammate", text: "芜湖！二次元万岁！" },
        { object: "Teammate", text: "不过还有一点...还有一点那就是贝拉。" },
        { object: "Teammate", text: "等到那个时候我们再回来，不知道贝拉还会不会再演出了。" },
        { object: "Teammate", text: "是啊..." },
        { object: "Teammate", text: "尤其是咱队长，追了贝拉这么久。" },
        { object: "Teammate", text: "如果到时候真是那样，那可要多伤心啊..." },
        { object: "Me", text: "贝拉...贝拉！！！" },
        { object: "Aside", text: "我突然大叫一声，把所有人都吓了一跳。" },
        { object: "Me", text: "贝拉等着我贝拉！" },
        { object: "Aside", text: "我突然从矮小的阳台上跳了下去，直奔小镇的方向。" },
        { object: "Teammate", text: "队长！你要去哪啊！" },
        { object: "Me", text: "替我保密，我马上就会回来！" }
    ],
    para2choice: [

    ]
}
export default chapter2
