<template>
  <div class="start2doors">
    <transition name="fade" mode="out-in">
      <div v-if="status === '1'" key="1">
        <h1 class="title">toope 制作</h1>
      </div>
      <div v-if="status === '2'" key="2">
        <h1 class="title">-黑听-</h1>
        <!-- <img src="../../public/img/undersound.jpeg" class="img"> -->
      </div>
      <div v-if="status === '3'" key="3">
          <p class="title">大家的心已经崩裂了</p>
          <p>五个人，散落在五个不同的时空...</p>
        </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: "1",
    };
  },
  created() {
    this.go();
  },
  methods: {
    go() {
      setTimeout(() => {
        this.status = "2";
        clearTimeout();
        setTimeout(() => {
          this.status = "3";
          clearTimeout();
        }, 6000)
      }, 4000);
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.start2doors {
  font-family: FZYaoti;
  color: rgb(255, 255, 255);
  width: 100%;
  height: 100vh;
  margin: auto;
  text-align: center;
}
.start2doors .title {
  padding-top: 25%;
}
.start2doors .img {
  opacity: 0.1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
</style>
