export const chapter3 = {
    para1: [
        { object: "Aside", text: "仅仅是...仅仅是看了那个所谓的糖果。", music: "nothing" },
        { object: "Aside", text: "我就遭遇了有史以来最荒诞离奇的事件。" },
        { object: "Aside", text: "那天晚上，在仍有饿意的情况下，我就这么在我腐烂的小窝里闭上了眼睛。" },
        { object: "Aside", text: "可是感觉很快，我就重新睁开了眼睛。" },
        { object: "Aside", text: "这一睡可不得了。我感觉再也没有任何的疲惫，饥饿和寒冷了。" },
        { object: "Aside", text: "我感觉浑身上下一身轻松，再也没有了任何的烦恼。" },
        { object: "Aside", text: "我发现前面有一道亮光。" },
        { object: "Aside", text: "这道亮光不再是冰冷苍白的那些亮光，而是充满了甜蜜，美好与幸福的亮光。" },
        { object: "Aside", text: "我便就这样身不由己，被牵引着，来到那个我从没见过的场景：" },
        { object: "Aside", text: "天空突然变得像雪一样明亮！", music: "dream" },
        { object: "Aside", text: "那些肮脏、恶臭、腐烂、阴湿带病的东西全部不见了。" },
        { object: "Aside", text: "取而代之的，是摆在眼前的无数甜到掉牙的糖果！！！" },
        { object: "Aside", text: "粉的，红的，蓝的，紫的，应有尽有！" },
        { object: "Me", text: "吱吱！" },
        { object: "Aside", text: "我欢呼一声，直接冲了过去！" },
        { object: "Aside", text: "我的同伴们一个个就像天使一样，在天空中飘荡着，享受着这数不尽的美食。" },
        { object: "Aside", text: "我钻进这些糖果堆里面，大吃特吃，把我的肚子吃成个气球。" },
        { object: "Aside", text: "我渐渐失去了重力，愉悦地漂浮在天空中，看着后面来的同伴与我们一起享受这道幸福。" },
        { object: "Me", text: "太好了！！！" },
        { object: "Aside", text: "然后我又醒了。", music: "nothing" },
        { object: "Aside", text: "还是那个阴暗的下水道，还是被饥饿折磨着。" },
        { object: "Aside", text: "似乎刚刚发生的那一切，只是一个遥不可及的谎言。" },
        { object: "Aside", text: "我以前从来没有这样过。" },
        { object: "Aside", text: "接下来的几天，相同的事情在不断地重演。" },
        { object: "Aside", text: "美好的幻想总在我的眼前略过，却又像肥皂泡泡一样破灭。" },
        { object: "Aside", text: "我开始魂不守舍，现实在我面前变得熟悉而又陌生。" },
        { object: "Aside", text: "我从来没有感觉到这样亦真亦幻，模模糊糊牵扯着我走向未知的地方。" },
        { object: "Aside", text: "我有点害怕了，害怕那个女孩是不是真的是什么巫女，" },
        { object: "Aside", text: "而我在与她对视的那一刻，就已经中了她可怕而邪恶的巫术。" },
        { object: "Aside", text: "最终让所有同伴都变成她的狗！" },
        { object: "Aside", text: "我被吓出了一声冷汗。" },
        { object: "Aside", text: "我决定去问一下我的同伴。" },
        { object: "Aside", text: "..." },
        { object: "Aside", text: "..." },
        { object: "Mouse", text: "..吱吱？" },
        { object: "Mouse", text: "吱吱吱！" },
        { object: "Mouse", text: "吱吱...吱吱..." },
        { object: "Me", text: "天哪...麻烦你说慢点..." },
        { object: "Mouse", text: "吱吱...吱吱吱！吱吱吱！吱吱吱！" },
        { object: "Mouse", text: "..吱吱！吱吱吱..." }
    ],
    para1choice: [
        { object: "Me", choice: "我不相信！", text: "我不信...我才不信！这不可能！你给我走..." },
        { object: "Me", choice: "我听不懂！", text: "我实在是搞不懂！算了，你还是走吧！" },
        { object: "Me", choice: "放屁！", text: "脱裤子放屁！我还活的好好的呢！" }
    ],
    para1AfterChoice0: [
        { object: "Mouse", text: "。。吱吱！吱吱！！！..." }
    ],
    para1AfterChoice1: [
        { object: "Mouse", text: "..吱吱.." }
    ],
    para1AfterChoice2: [
        { object: "Mouse", text: ".吱吱！吱吱。吱吱吱！！！" }
    ],
    para2: [
        { object: "Aside", text: "不知道为什么我感觉很烦躁。" },
        { object: "Aside", text: "现在，生理和心理的双重痛苦在折磨着我，让我难以摆脱。" },
        { object: "Aside", text: "刹那间，我似乎有看见了那个女孩的影子。" },
        { object: "Aside", text: "她蹲了下来，缓缓地对我说道：" },
        { object: "Aside", text: "嘻嘻。这是一场糖果梦。" },
        { object: "Me", text: "..." },
        { object: "Me", text: "看来，不再过去一趟，我永远也搞不清楚到底发生了什么。" }
    ],
    para2choice: [

    ]
}
export default chapter3
