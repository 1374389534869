export const chapter6 = {
    para1: [
        { object: "Me", text: "等等我！" },
        { object: "Aside", text: "我从没见他还能跑得这么快，我根本跟不上他的速度。" },
        { object: "Aside", text: "他宛如也成了那场风，驰骋着，不会有任何停歇。" },
        { object: "Me", text: "天哪..." },
        { object: "S", text: "乐...等着我...等着我..." },
        { object: "Aside", text: "他当然着急。他当然害怕。" },
        { object: "Aside", text: "他就像一个疯子一样跑着，害怕他像从前那样，再次错过。" },
        { object: "Aside", text: "却又不知道要重新等多少个年头。" },
        { object: "S", text: "等着我！等着我！" },
        { object: "Aside", text: "阳光渐渐地爬升起来了。" },
        { object: "Aside", text: "我看着他逐渐跑进那阳光之中，没了踪影。" },
        { object: "Me", text: "..." },
        { object: "Aside", text: "等我再次看到他的时候，我发现他正在被一群保安拦截着。" },
        { object: "S", text: "不！让我给她！让我给她！" },
        { object: "Police", text: "冷静一点...待确认安全，我们才能帮你代交。" },
        { object: "S", text: "不行，我要亲自给她！" },
        { object: "S", text: "快放开我！" },
        { object: "Aside", text: "我看见一辆黑色高级轿车，在众人的簇拥下下来了一位看起来十分高贵的女性。" },
        { object: "Me", text: "她的神情冷酷，正准备走进一栋高档的府邸之中。" },
        { object: "S", text: "乐！你还记得我吗？" },
        { object: "S", text: "珈乐！记得吗，那份我要送给你的礼物！" },
        { object: "Croal", text: "..." },
        { object: "Aside", text: "她什么也没说只是停顿了一下，便径直往府邸里走去。" },
        { object: "S", text: "珈乐小姐！我有话要对你说！..." },
        { object: "S", text: ".." },
        { object: "S", text: "..." },
        { object: "Croal", text: "当然。" },
        { object: "Aside", text: "谁知道她只是脱下了外衣，便退让了保安，面对面着他：" },
        { object: "Croal", text: "别这么客套。" },
        { object: "Croal", text: "哈哈，我还记得你呢。" },
        { object: "Aside", text: "她背对着阳光，面部带着青涩的笑容，似乎两人又回到了原来那个独一无二的时光，那个令人沉醉的夜晚..." },
        { object: "Aside", text: "【完】" }
    ],
    para1choice: [

    ]
}
export default chapter6;
