export const chapter5 = {
    para1: [
        { object: "Aside", text: "北极星这块小小的土地上，有这么一个差不多被人遗忘的地方，" },
        { object: "Aside", text: "在这么个毫不起眼的地方，有着这么一个热爱舞蹈的女孩。" },
        { object: "Aside", text: "她的性格活泼而直爽，气质就像正午的太阳那般耀眼。" },
        { object: "Aside", text: "她虽然并不是很出名，但是却很出众。" },
        { object: "Aside", text: "无论是芭蕾、民族舞还是爵士风，她都是样样拿手。" },
        { object: "Aside", text: "她就是这么自由，总是能在任何地方惬意地舞蹈。" },
        { object: "Aside", text: "在乡野、在草坪、在舞台、甚至是在古代的废墟中。" },
        { object: "Aside", text: "她总是这么随性，从不考虑太多，" },
        { object: "Aside", text: "就像新生的鸟儿，可没有什么囚笼。" },
        { object: "Aside", text: "总是让我们振奋，忘却那世界早已展现出来的不安。。。" },
        { object: "Aside", text: "我是贝拉的忠实粉丝。" },
        { object: "Aside", text: "实际上，自从我们开始在这里工作以来，我们小组的成员就没有一个不是了。" },
        { object: "Aside", text: "我们有时间会抽出空来溜到小镇去，去看她无与伦比的精彩表演。" },
        { object: "Aside", text: "我们就这样，陪了她很久很久..." },
        { object: "Aside", text: "从一个乡村小镇的小姑娘，到小有名气的舞者，" },
        { object: "Aside", text: "再到去外地演出，全国巡演，" },
        { object: "Aside", text: "到最后成为全国万众瞩目的偶像。" },
        { object: "Aside", text: "我们陪她一路见证了下去。" },
        { object: "Aside", text: "中途，也出现了许多意外事件。" },
        { object: "Aside", text: "像是从身体受伤、到被谣言诽谤，等等，" },
        { object: "Aside", text: "但贝拉都成功战胜了，并成为了更好的自己。" },
        { object: "Aside", text: "不过，舞者的表演生涯总是短暂的。" },
        { object: "Aside", text: "贝拉总算是功成身退，逐渐退回到了幕后的工作。" },
        { object: "Aside", text: "我们也一样，不再去亲自参加这么多的任务了。" },
        { object: "Aside", text: "但这并不影响我们的关系。我们仍然是很好的朋友。" },
        { object: "Aside", text: "虽然我们做着截然不同的工作，但是我们却总能找到共同的话题。", music: "Why?" },
        { object: "Aside", text: "聊着各自的过去与未来。" },
        { object: "Bella", text: "或许，我们已经老了。" },
        { object: "Bella", text: "那些梦想中的事，留给后辈去做吧。" },
        { object: "Me", text: "老倒不至于。不过我们确实不再年轻了。" },
        { object: "Me", text: "再也没有以前的那种热情了。" },
        { object: "Bella", text: "嗯。时间总是无情，将任何人都变得如此得腐朽。" },
        { object: "Bella", text: "可是你...你却似乎战胜了它。" },
        { object: "Me", text: "..." },
        { object: "Me", text: "...你以后就会知道了。" },
        { object: "Aside", text: "..." },
        { object: "Aside", text: "时间就这样流逝了.." },
        { object: "Aside", text: "十年。" },
        { object: "Aside", text: "二十年。" },
        { object: "Aside", text: "三十年。" },
        { object: "Aside", text: "不知道过了多少年。" },
        { object: "Aside", text: "我急急忙忙地闯进病房，那里也有许多我的队友，还有许多其他的人。" },
        { object: "Me", text: "贝拉...贝拉...我来了。" },
        { object: "Teammate", text: "贝拉，他来了。" },
        { object: "Bella", text: "啊。。。" },
        { object: "Bella", text: "这一回，我真的老了。" },
        { object: "Me", text: "那又能怎么样呢？" },
        { object: "Me", text: "在我眼中，你一直都很年轻。" },
        { object: "Aside", text: "我紧紧握住她那充满充满皱纹的手。" },
        { object: "Aside", text: "她的头发早已变成白花花的一片，眼睛艰难地睁开着，仿佛随时都会紧紧地合在一起。" },
        { object: "Aside", text: "可是我和我的队友们，却一点样子也没变。" },
        { object: "Aside", text: "我们仍旧是贝拉年轻时的模样，没有一丝丝改变。" },
        { object: "Bella", text: "听我说...听我说..." },
        { object: "Me", text: "好，尽管说吧，没人拦着你..." },
        { object: "Bella", text: "这么多年来..." },
        { object: "Bella", text: "我一直在等一个人..." },
        { object: "Me", text: "什么？" },
        { object: "Me", text: "我的脑海里一下子闪现出许多画面，闪现出那个熟悉女孩的笑容，闪现出无数熟悉的场景，像走马灯一样在我眼前略过:" },
        { object: "???", text: "快来倒数吧，各位！" },
        { object: "Bella", text: "我每天都望着北极星的方向，因为我知道那个人就在那里。" },
        { object: "???", text: "贝拉...等着我贝拉！" },
        { object: "Bella", text: "这个人...就是你啊..." },
        { object: "???", text: "我会陪你去更大的舞台，永远永远！" },
        { object: "Bella", text: "为什么，为什么你还是如此年轻呢" },
        { object: "???", text: "对不起...我要去很远的地方...可是要很久才能回来..." },
        { object: "Me", text: "什么...这到底是什么..." },
        { object: "Bella", text: "为什么，你没有受到时间的影响？" },
        { object: "???", text: "我要去参加大型演出了！" },
        { object: "Bella", text: "你到底在哪里..." },
        { object: "???", text: "请等着我，我一定会回来！" },
        { object: "Bella", text: "为什么，为什么我等了一辈子，你也没有回来..." },
        { object: "???", text: "我会等着你，我会等着你一辈子。" },
        { object: "Me", text: "停下。不要再说了。" },
        { object: "Bella", text: "你失守了诺言。" },
        { object: "Bella", text: "你是个骗子！" },
        { object: "Me", text: "别说了！" },
        { object: "???", text: "我们会在北极星相见的。" },
        { object: "Bella", text: "骗子！" },
        { object: "Me", text: "不是我！不是我！！！" },
        { object: "Bella", text: "..." },
        { object: "Bella", text: "..." },
        { object: "Bella", text: "..." },
        { object: "Me", text: "不...怎么会..." },
        { object: "Me", text: "不...贝拉...别离开我..." },
        { object: "Me", text: "不...这不可能..." },
        { object: "Aside", text: "突然，我的手中，出现一块结晶一样的物质。" },
        { object: "Aside", text: "它洁白无瑕，尽管灯光昏暗，可是却仍旧在闪闪发光。" },
    ],
    para1choice: [
        { object: "Aside", choice: "毁掉它", text: "突然间，我意识到了这是什么。" },
        { object: "Aside", choice: "观察它", text: "..." },
    ],
    para1AfterChoice0: [
        { object: "Me", text: "可恶！" },
        { object: "Aside", text: "我一下子愤怒到了极点，双手不住着颤抖着。" },
        { object: "Aside", text: "这一切都是一场梦！" },
        { object: "Aside", text: "一阵暴怒之下，完美的结晶被我捏了个粉碎。" },
        { object: "Aside", text: "粉尘被一阵风吹起，散落在房间的各个角落，宛如下起了一场雪。" },
        { object: "Aside", text: "我看着房间里的人，物，一件一件的消失，最终连我也不例外。" },
        { object: "Aside", text: "我缓缓地闭上了眼睛，似乎正在等待着天明。" }
    ],
    para1AfterChoice1: [
        { object: "Me", text: "这是什么..." },
        { object: "Aside", text: "我拿到手上，开始仔细地端详着..." },
        { object: "Aside", text: "突然间，我意识到了这是什么。" },
        { object: "Aside", text: "我汗毛直竖，一下子愤怒到了极点：" },
        { object: "Aside", text: "不！这一切，怎么可能，怎么可能...这是一道幻影！？" },
        { object: "Me", text: "该死！你害了多少人！" },
        { object: "Aside", text: "镜像物质被我重重地摔到了地上，变成了粉碎。" },
        { object: "Me", text: "我就这样看着周围的事物一件又一件消失，却只能跪在地上无力地叹息。" },
    ],
    para2: [
        { object: "Aside", text: "...", music: "nothing" },
        { object: "Aside", text: "..." },
        { object: "Aside", text: "我醒了。" },
        { object: "Me", text: "..." },
        { objcet: "Me", text: "怎么...怎么回事..." },
        { object: "Me", text: "我似乎...做了一个很长的梦。" },
        { object: "Teammate", text: "我也是，队长。" },
        { object: "Teammate", text: "我们睡了多久了.." },
        { object: "Aside", text: "我看着飞船里那三个刚刚睡醒的队友，又看了看窗外：" },
        { object: "Aside", text: "我们的飞船停摆在一块戈壁滩上，外面是无尽的沙尘，掀起滚滚的风浪。" },
        { object: "Me", text: "看一下计时器。" },
        { object: "Teammate", text: "65小时16分..." },
        { object: "Me", text: "我们...我们这是躺了多久...", music: "BellaEnd" },
        { object: "Radio", text: "这里是星球外部。" },
        { object: "Radio", text: "各位，一切顺利?" },
        { object: "Me", text: "一切正常。" },
        { object: "Radio", text: "收到，队长。我们已经到了预定任务期限了。" },
        { object: "Me", text: "..." },
        { object: "Me", text: "任务已经完成。正在前往总舱。" },
        { object: "Teammate", text: "嗯？" },
        { object: "Me", text: "上面是否一切正常？" },
        { object: "Radio", text: "一切正常，队长。" },
        { object: "Me", text: "好。准备返航，各位。" },
        { object: "Teammate", text: "队长，镜像物质..." },
        { object: "Aside", text: "我将我的一只手心打开。" },
        { object: "Aside", text: "镜像物质的粉末就像沙子一样掉落下来，散落在宇宙飞船之中。" },
        { object: "Teammate", text: "明白，队长！" },
        { object: "Teammate", text: "准备，返航，回家！" }
    ],
    para2choice: [

    ]
}
export default chapter5
