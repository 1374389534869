export const chapter2 = {
    para1: [
        { object: "Me", text: "我们现在在哪..." },
        { object: "Eillen", text: "180年前，戴安娜市。" },
        { object: "Me", text: "天哪！我们真的穿越了！" },
        { object: "Eillen", text: "我也不敢相信...我从来不敢去尝试，没想到真的成功了..." },
        { object: "Eillen", text: "告诉我，你看见了什么？" },
        { object: "Me", text: "我看见一个城镇..." },
        { object: "Me", text: "有一个大门，周围似乎有很多人。" },
        { object: "Eillen", text: "他们在讨论什么？" },
        { object: "Me", text: "她们...她们似乎在讨论嘉然姐姐...？" },
        { object: "Eillen", text: "我感觉我们找到了。" },
        { object: "Eillen", text: "注意一下我们的角色，我们一定能解决的。" },
        { object: "Me", text: "好的...老师。" },
        { object: "Aside", text: "法庭外，人声鼎沸。" },
        { object: "Aside", text: "他们都在讨论那个女孩，似乎他们也是这些事件的亲历者一样：" },
        { object: "People", text: "我才不信，嘉然是个很好的人！" },
        { object: "People", text: "越是这种慈眉善目，就越是危险！" },
        { object: "Faguan", text: "砰！" },
        { object: "Faguan", text: "请原告律师发言！" },
        { object: "Yls", text: "我们有理由相信，被告人嘉然小姐，正在做一些危害公共安全之事。" },
        { object: "Yls", text: "首先，我们找到了她与一位在逃罪犯私下联络的信件。" },
        { object: "Yls", text: "信中的内容显示，嘉然小姐明知对方是一名通缉犯，" },
        { object: "Yls", text: "但却仍有交好之意，甚至是包庇、窝藏，目前罪犯也亲自承认，这里是口供证明。" },
        { object: "Yls", text: "其次，我们还在她的住所找到了大量绘有奇怪符号的绘画，" },
        { object: "Yls", text: "结合之前罪犯的经历和信件的内容，这些绘画与某种邪教组织活动大有关系。" },
        { object: "Yls", text: "最后，嘉然小姐似乎展现出了某种巫术倾向。" },
        { object: "Faguan", text: "什么巫术？" },
        { object: "Yls", text: "我们有证据表明，嘉然小姐可以和老鼠对话。" },
        { object: "People", text: "啊这..." },
        { object: "Aside", text: "场面顿时一片哗然。" },
        { object: "Diana", text: "这不是巫术！" },
        { object: "Faguan", text: "请被告人保持沉默。" },
        { object: "Yls", text: "再加上嘉然小姐连夜赶马车离开，而且拒不透露目的地，我相信这一切都不是巧合。" },
        { object: "Yls", text: "证据已如实上传，我的发言就到这里，谢谢。" },
        { object: "Aside", text: "随后便是整个法庭的一阵大讨论。" },
        { object: "Faguan", text: "请被告律师发言！" },
        { object: "Bls", text: "..." },
        { object: "Faguan", text: "被告律师，你可以发言了。" },
        { object: "Bls", text: "请稍等...还有一个人要来..." },
        { object: "People", text: "市长大人来了！" },
        { object: "Aside", text: "法庭又是一阵骚动。" },
        { object: "Aside", text: "我跟在乃琳后面，提着一个箱子，缓缓地走了进去。" },
        { object: "Aside", text: "乃琳姐姐的身材非常的高挑。" },
        { object: "Aside", text: "再加上那美丽尊贵的服饰，一下子就吸引住了所有人的目光。" },
        { object: "Faguan", text: "市长大人，您为什么来了？" },
    ],
    para1choice: [
        { object: "Eillen", choice: "提供证据", text: "我是受邀提供最新证据的。" },
        { object: "Me", choice: "受邀", text: "市长大人收到邀请，前来旁观本次审判。" }
    ],
    para1AfterChoice0: [
        { object: "Aside", text: "说罢，乃琳便向我许了个眼神，我走上法庭，将箱子递给了律师。" },
        { object: "Aside", text: "嘉然小姐居然真的存在，还和乃琳说的一模一样！" },
        { object: "Aside", text: "而且嘉然小姐和乃琳老师真的认识，这让我大吃一惊，穿越时空的联结让我感觉无法相信！" },
        { object: "Diana", text: "乃琳？真的是你？" },
        { object: "Eillen", text: "如假包换！" },
        { object: "Diana", text: "啊...很抱歉让你看到现在这样子的我..." },
        { obejct: "Eillen", text: "没什么。你当初离开的时候比现在还糟糕。" },
        { object: "Diana", text: "那也就是说，你的信中写得都是真的？" },
        { object: "Eillen", text: "没错。" },
        { object: "Diana", text: "可惜我现在...意外时常发生..." },
        { object: "Eillen", text: "宝贝，你怎么出现在这个地方，还成了主角？" },
        { object: "Diana", text: "都怪我太天真太好奇了...以至于被有心之人欺骗和迷惑..." },
        { object: "Diana", text: "但请相信我！我是无辜的！" },
        { object: "Eillen", text: "我当然相信..." },
    ],
    para1AfterChoice1: [
        { object: "Eillen", text: "是的" },
        { object: "Aside", text: "随后，我将箱子递给了被告律师，与嘉然小姐擦肩而过：" },
        { object: "Aside", text: "原来嘉然小姐真的存在，还和乃琳说的一模一样！" },
        { object: "Aside", text: "而且嘉然小姐和乃琳老师真的认识，这让我大吃一惊，穿越时空的联结让我感觉无法相信！" },
        { object: "Aside", text: "不只是我，连嘉然小姐都楞了一下，同样感到难以置信：" },
        { object: "Diana", text: "...那些信件居然都是真的。" },
        { object: "Eillen", text: "好久不见，然然。" },
        { object: "Diana", text: "真不希望这次是以这样的方式见面，乃琳。" },
        { object: "Diana", text: "很抱歉，当初对你说了这么多刻薄的话。" },
        { object: "Eillen", text: "没关系，亲爱的。" },
        { object: "Eillen", text: "看起来你陷入了危险的境地，宝贝。" },
        { object: "Diana", text: "当然。" },
        { object: "Diana", text: "但我绝对是无辜的！" },
        { object: "Diana", text: "你是来帮我的吧？" },
        { object: "Eillen", text: "这不就来帮你了。" },
        { object: "Eillen", text: "相信我，决定没问题的。" }
    ],
    para2: [
        { object: "Eillen", text: "相信法官大人能够公正判案，让真相早一天浮出水面。" },
        { object: "Faguan", text: "当然...这是我的职责。" },
        { object: "Faguan", text: "好的，有请被告人律师发言..." },
        { object: "Aside", text: "...", music: "spaceStar" },
        { object: "Bella", text: "我在哪..." },
        { object: "Aside", text: "贝拉缓缓地爬起来，发现她似乎是在一个小船上。" },
        { object: "Aside", text: "小船缓缓地游荡者，游荡在湖水的中央。" },
        { object: "Aside", text: "湖水的映射着的，是存在的银河。" },
        { object: "Aside", text: "它们迸发在天空中，同时也存在于水面上。" },
        { object: "Aside", text: "宛如宇宙中的一片独木舟，迷失在这般光芒与空荡荡的黑暗之中。" },
        { object: "Bella", text: "我...我..." },
        { object: "Bella", text: "我该做什么..." },
        { object: "Aside", text: "贝拉开始划着小船。" },
        { object: "Aside", text: "可才走了一段时间，她发现一个奇怪的发光物体在朝它飞来！" },
        { object: "Bella", text: "啊！" },
        { object: "Aside", text: "贝拉弯下腰，才终于躲过一劫。" },
        { object: "Aside", text: "它快速地飞走了，但似乎掉落下什么东西。" },
        { object: "Aside", text: "那是一张纸片，正好落在她的头顶上：" },
        { object: "Aside", text: "上面映射着她年轻时候的笑脸。" },
        { object: "Bella", text: "这是我的照片..." },
        { object: "Eillen", text: "把它点起来。" }
    ],
    para2choice: [
        { object: "Me", choice: "南边的一颗星", text: "这个？" },
        { object: "Me", choice: "北边的一颗星", text: "这样吗？" }
    ],
    para2AfterChoice0: [
        { object: "Eillen", text: "对...希望她能注意到..." },
        { object: "Eillen", text: "我们要指引她回家..." },
        { object: "Aside", text: "南边的一颗星，开始熊熊燃烧起来..." },
        { object: "Aside", text: "它比天空中任何一颗星星都要亮，一下子黯淡了周围银河的光芒。" },
        { object: "Aside", text: "只可惜这样，我就更看不清楚贝拉姐姐的脸了。" },
        { object: "Bella", text: "嗯？" },
        { object: "Bella", text: "那里...那里就是方向吗？" },
        { object: "Bella", text: "是谁在那里..." }
    ],
    para2AfterChoice1: [
        { object: "Eillen", text: "不...你点错了。" },
        { object: "Eillen", text: "不是北极星。" },
        { object: "Eillen", text: "是南边那个...不要让她走回去了..." },
        { object: "Me", text: "哦，好的。" },
        { object: "Aside", text: "我赶紧熄灭了北极星，点燃了南边的那颗。" },
        { object: "Aside", text: "它开始熊熊燃烧起来，在宇宙中形成了一个明亮的光点。" },
        { object: "Eillen", text: "希望她能看见..." },
        { object: "Eillen", text: "可惜我没法和她说话..." },
        { object: "Bella", text: "...?" },
        { object: "Bella", text: "是那里吗？是去那里吗？" }
    ],
    para3: [
        { object: "Aside", text: "贝拉开始朝着那个方向划去。" },
        { object: "Aside", text: "那里就是她的指引，她的方向..." },
        { object: "Eillen", text: "好的...还有一个..." },
        { object: "Ava", text: "还有一个，还有一个，那当然就是我！" },
        { object: "Aside", text: "向晚一个人走在刚下过雨的路上。" },
        { object: "Aside", text: "周围很空旷，却也透露着一股子烟火的气息。" },
        { object: "Aside", text: "同样的，向晚的身上也充斥着一股雨后的铁锈味。" },
        { object: "Ava", text: "你们是谁啊？" },
        { object: "Ava", text: "我为什么要回枝江？" },
        { object: "Ava", text: "我要怎么回到枝江？" },
        { object: "Ava", text: "枝江对我来说是未来啊！" },
        { object: "Ava", text: "什么？你们亲自过来？你们怎么亲自过来啊？" },
        { object: "Ava", text: "我们都不在一个时空！" },
        { object: "Ava", text: "我当你是诈骗电话啊！" },
        { object: "Aside", text: "嘟..." },
        { object: "Ava", text: "嗯..." },
        { object: "Ava", text: "听起来真的很着急啊...但是..." },
        { object: "Ava", text: "这...不会...难道..." },
        { object: "Ava", text: "...?", music: "nothing" },
        { object: "Ava", text: "你怎么来了？" },
        { object: "VaV", text: "你我都知道真相，我们不需要互相欺骗。" }
    ],
    para3choice: [

    ]
}
export default chapter2;
