<template>
  <div class="main">
    <Music :music="music" />
    <transition name="fade" mode="out-in">
      <Before
        v-if="status === 'before'"
        @handleStatus="handleStatus"
        @handleMusic="handleMusic"
      />
      <Doors
        v-if="status === 'doors'"
        @handleStatus="handleStatus"
        :character="character"
      />
      <Buffer v-if="status === 'buffer'" />
      <Chapter
        v-if="status === 'start'"
        title="start"
        :chapter="start"
        @moveToNext="handleStatus"
      />
      <Start2Doors v-if="status === 'start2doors'" />
      <Story
        v-if="buttonStatus"
        @handleStatus="handleStatus"
        :characterList="character"
        :name="status"
        @handleMusic="handleMusic"
      />
      <Chapter
        v-if="status === 'levelOver'"
        title="memory"
        :chapter="levelOver"
        @moveToNext="handleStatus"
      />
      <Memory
        v-if="status === 'memory'"
        @handleMusic="handleMusic"
        @handleStatus="handleStatus"
      />
      <FakeOver v-if="status === 'fakeOver'" />
      <Chapter
        v-if="status === 'asoul'"
        title="A-SOUL"
        titleIndex="-6-"
        :showStatus="true"
        :chapter="me"
        @moveToNext="handleStatus"
        @handleMusic="handleMusic"
      />
      <End v-if="status === 'end'" @handleStatus="handleStatus" />
      <Chapter
        v-if="status === 'Ang'"
        title="weAreAsoul"
        :chapter="egg"
        @moveToNext="handleStatus"
      />
      <div v-if="status === 'weAreAsoul'" class="final-photo">
        <img src="../public/img/Asoul.png" />
      </div>
      <Wrong v-if="status === 'wrong'"/>
    </transition>
    <div class="return-button">
      <el-tooltip content="退出至主界面" effect="dark" placement="right">
        <el-button
          @click="goHome"
          icon="el-icon-s-home"
          circle
          v-if="buttonStatus"
        ></el-button>
      </el-tooltip>
    </div>
    <div class="egg">
      <DianaEgg v-if="eggStatus === 'Diana'" />
      <BellaEgg v-if="eggStatus === 'Bella'" />
    </div>
  </div>
</template>

<script>
import Story from "./views/Story.vue";
import start from "./assets/Dbace/Start";
import db from "./utils/loaclStorage";
import Before from "./views/Before.vue";
import Buffer from "./views/Buffer.vue";
import Doors from "./views/Doors.vue";
import Start2Doors from "./views/Start2Doors.vue";
import Chapter from "./Dbace/Chapter.vue";
import character from "./assets/Dbace/character";
import Music from "./Dbace/Music.vue";
import levelOver from "./assets/Dbace/levelOver";
import Memory from './Dbace/Memory.vue';
import FakeOver from './views/FakeOver.vue';
import Me from "./assets/Dbace/me/Me";
import End from './views/End.vue';
import DianaEgg from './Dbace/eggs/DianaEgg.vue';
import BellaEgg from './Dbace/eggs/BellaEgg.vue';
import avaTalk from "./assets/Dbace/ava/avaTalk";
import egg from "./assets/Dbace/egg.js";
import Wrong from "./views/Wrong.vue";
export default {
  components: { Doors, Buffer, Story, Start2Doors, Before, Chapter, Music, Memory, FakeOver, End, DianaEgg, BellaEgg, Wrong},
  data() {
    return {
      status: "before",
      character: character,
      levelOver: levelOver,
      me: Me,
      music: "",
      saver: "",
      start: {},
      currentName:"",
      eggStatus:"",
      buttonStatus: false,
      egg: egg
    };
  },
  created() {
    this.start = start;
  },
  methods: {
    handleStatus(name) {
      console.log(name);
      this.buttonStatus = false;
      if (name === "before") {
        const initial = db.get("INITIAL");
        // const initial = "";
        if(!initial.length){
          db.save("INITIAL","START");
          this.status = "start";
          this.music = "start";
        }
        else if(initial === "START"){
          this.status = "start";
          this.music = "start";
        }
        else if (initial === "INITIAL") {
          this.status = "doors";
          this.music = "doors";
        }
        else if(initial === "LEVELOVER"){
          this.status = "levelOver";
          this.music = "start";
        }
        else if(initial === "MEMORY"){
          this.status = "memory";
          this.music = "memory";
        }
        else if(initial === "FAKEOVER"){
          this.status = "fakeOver";
          this.music = "fakeOver";
        }
        else if(initial === "NOTHING"){
          this.status = "nothing";
          this.music = "empty";
        }
        else if(initial === "ASOUL" && db.get("SAVER").level === 5){
          this.status = "asoul";
        }
        else{
          this.status = "wrong";
        }


        return;
      }
      for (var i in this.character) {
        if (name === this.character[i].name) {
          this.status = "buffer";
          this.music = "into";
          setTimeout(() => {
            this.status = name;
            this.currentName = name;
            this.buttonStatus = true;
            clearTimeout();
          }, 5000);
          return;
        }
      }
      if (name === "start") {
        this.status = "start2doors";
        this.music = "start2door";
        db.save("SAVER", {
          level: 0,
          Diana: 0,
          Bella: 0,
          Ava: 0,
          Croal: 0,
          Eillen: 0,
        });
        setTimeout(() => {
          this.status = "doors";
          this.music = "doors";
          clearTimeout();
        }, 15000);
      }
      if (name === "return2doors") {
        this.goHome();
        return;
      }
      if (name === "levelOver"){
        this.status = "levelOver";
        this.music = "start";
        return;
      }
      if(name === "memory"){
        this.status = "???";
        this.music = "bee";
        setTimeout(()=>{
      this.status = "memory";
      this.music = "memory";
      clearTimeout();
    }, 9000)
        db.save("INITIAL", "MEMORY");
        return;
      }
      if(name === "fakeOver"){
       setTimeout(() => {
        this.status = "fakeOver";
        this.music = "fakeOver";
        clearTimeout();
      }, 5000);
      return;
      }
      if(name === "A-SOUL"){
        this.status = "end";
        return;
      }
      if(name === "end2doors"){
        this.buttonStatus = false;
        this.status = "buffer";
        setTimeout(() => {
          this.status = "doors";
          this.music = "doors";
        }, 5000);
        return;
      }
      if(name === "Ang"){
        this.buttonStatus = false;
        this.status = "buffer";
        setTimeout(() => {
          this.status = "Ang";
          this.music = "Ang";
        }, 5000);
        return;
      }
      if(name === "weAreAsoul"){
        this.status = "weAreAsoul";
      }
      if(name === "wrong"){
        this.status = "wrong";
        return;
      }
    },
    handleMusic(music) {
      this.music = music;
    },
    handleEggStatus(){
      if(this.eggStatus === "Diana"){
        this.music = "dianaEgg";
        setTimeout(() => {
          this.eggStatus = "";
          this.music = "doors";
          clearTimeout();
        }, 5000)
      }
      if(this.eggStatus === "Bella"){
        setTimeout(() => {
          this.eggStatus = "";
          this.music = "doors";
          clearTimeout();
        }, 60000)
      }
      if(this.eggStatus === "Ava"){
        avaTalk.map(item => console.log(item));
        this.music = "error";
      }
      if(this.eggStatus === "Croal"){
        this.music = "heart";
      }
    },
    goHome() {
      this.buttonStatus = false;
      this.status = "buffer";
      this.music = "out";
      setTimeout(() => {
        this.status = "doors";
        this.music = "doors";
        const saver = db.get("SAVER");
        if(saver[this.currentName] >= 6){
          this.eggStatus = this.currentName;
          this.handleEggStatus();
        }
      }, 5000);
    },
  },
};
</script>

<style>
body {
  margin: 0px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

::-webkit-scrollbar {
  color: transparent;
}

.video {
  position: relative;
  z-index: 1;
  opacity: 0.2;
}

.el-button {
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  background: transparent;
  font-size: 6vh;
  padding: 1vh;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}
.el-button:hover {
  border-color: rgba(255, 255, 255, 0);
  color: rgb(0, 0, 0);
  background: white;
  font-size: 6vh;
  padding: 1vh;
}
</style>
<style scoped>
.main {
  background: rgb(0, 0, 0);
  position: relative;
  /* z-index:-200; */
  height: 100vh;
  overflow: auto;
}
.main .wall-paper {
  position: relative;
  z-index: -1;
}
.main .return-button {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 20;
  padding: 20px;
}
.main .return-button button {
  color: rgba(255, 255, 255, 0.294);
  border: transparent;
}
.main .return-button button:hover {
  color: black;
}

.main .egg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.final-photo {
  text-align: center;
  padding-top: 20vh;
}
</style>
