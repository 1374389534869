<template>
  <div class="chapter-end">
    <div v-for="(line,index) in chapterEndList" :key="index">
        <p>{{line}}</p>
    </div>
  </div>
</template>

<script>
import chapterEnd from "../assets/Dbace/chapterEnd";
export default {
  props: ["person"],
  data() {
    return {
      chapterEnd: "",
      chapterEndList: [],
    };
  },
  created() {
    const name = this.person;
    this.chapterEnd = chapterEnd[name];
    this.handleLine();
  },
  methods: {
    handleLine() {
      var i = 0;
      const words = 50;
      while (i < this.chapterEnd.length) {
        this.chapterEndList.push(this.chapterEnd.slice(i, i + words));
        i += words;
      }
    },
  },
};
</script>

<style scoped>
.chapter-end {
  color: white;
  padding: 40px;
    text-align: center;
    height: 100vh;
    overflow: auto;
}
.chapter-end .chapter-end-button{
  text-align: center;
}
</style>
