<template>
  <div class="story">
    <div v-for="(character, index1) in characterList" :key="index1">
      <div v-if="character.name === personName">
        <div v-for="(chapter, index) in chapterList" :key="index">
          <transition name="fade" mode="out-in">
            <Chapter
              v-if="chapterIndex === index"
              :title="character.title"
              :showStatus="showStatus"
              :titleIndex="character.titleIndex"
              @moveToNext="moveToNext"
              @handleMusic="handleMusic"
              :chapter="chapter"
            />
          </transition>
        </div>
      </div>
    </div>
    <ChapterEnd v-if="chapterIndex === chapterList.length" :person="name"/>
  </div>
</template>

<script>
import db from "../utils/loaclStorage";
import Chapter from "../Dbace/Chapter.vue";
import ChapterEnd from "../Dbace/ChapterEnd.vue";
import story from "../assets/Dbace/story";
export default {
  components: {
    ChapterEnd,
    Chapter,
  },
  props: ["characterList", "name"],
  data() {
    return {
      chapterIndex: 0,
      chapterList: [],
      personName:"",
      showStatus:true,
      message: "",
    };
  },
  created() {
    console.log(this.characterList);
    this.chapterIndex = db.get("SAVER")[this.name];
    if(this.chapterIndex !== 0){
      this.showStatus = false;
    }
    this.chapterList = story[this.name];
    this.personName = this.name;
    console.log(this.chapterList)
  },
  methods: {
    moveToNext() {
      this.showStatus = false;
      this.chapterIndex++;
      if(this.chapterIndex === this.chapterList.length)this.over();
      const saver = db.get("SAVER");
      saver[this.name] = this.chapterIndex;
      db.save("SAVER", saver);
      this.$notify({
        title: "提示",
        message: "内容进度保存成功！",
      });
    },
    handleMusic(music){
      if(music){
        this.$emit("handleMusic",music);
      }

    },
    over() {
      const saver = db.get("SAVER");
        saver.level++;
        db.save("SAVER", saver);
    },
  },
};
</script>
<style>
.story{
  color: white;
  overflow: auto;
}
</style>
