<template>
  <div class="mytalk">
    <transition name="fade" mode="out-in">
      <div class="mytalk-button" v-if="status">
        <el-button @click="handleTalk">{{ text }}</el-button>
      </div>
    </transition>
  </div>
</template>

<script>
import myTalk from "../assets/Dbace/mytalk";
import db from '../utils/loaclStorage';
export default {
  data() {
    return {
      text: "",
      name: "start",
      num: 0,
      status: true,
    };
  },
  created() {
    this.handleTalk();
  },
  methods: {
    handleTalk() {
      this.status = false;
      setTimeout(() => {
        this.status = true;
      }, 100);
      const length = myTalk[this.name].length;
      if (this.num < length) {
        this.text = myTalk[this.name][this.num];
        this.num++;
      } else {
        this.text = "";
        this.num = 0;
        this.$emit("handleTalkName",this.name);
        if(this.name === "fakeC"){
          db.save("INITIAL","NOTHING");
        }
      }
    },
    changeTalk(name){
      this.name = name;
      this.num = 0;
      this.handleTalk();
    }
  },
};
</script>

<style scoped>
.mytalk {
  text-align: center;
}
.mytalk .mytalk-button button {
  color: rgba(0, 0, 0, 0.493);
  border: transparent;
  background: transparent;
  font-size: 20px;
  font-family: FZYaoti;
}
.mytalk .mytalk-button button:hover {
  color: black;
}
</style>
