export const egg = {
    para1: [
        { object: "Ang", text: "..." },
        { object: "Ang", text: "你终究是来了。" },
        { object: "Me", text: "是的。" },
        { object: "Me", text: "你就是那个A-SOUL的吉祥物，阿草？" },
        { object: "Ang", text: "是的。" },
        { object: "Ang", text: "哈哈！想不到我会说话吧！" },
        { object: "Ang", text: "等等，你不会都想起来了吧！" },
        { object: "Me", text: "对。" },
        { object: "Me", text: "但是为什么要让我想起这些？" },
        { obejct: "Me", text: "一样的枝江，难道最终也是一样的结局？" },
        { object: "Ang", text: "...从历史的角度来说，是的。" },
        { object: "Me", text: "我已经后悔来到这里了。" },
        { object: "Ang", text: "享受过程。" },
        { object: "Ang", text: "美好，就在这一朝一夕之间。" },
        { object: "Ang", text: "并不在过去，也并不在将来。" },
        { object: "Me", text: "可是我还是难以接受..." },
        { object: "Ang", text: "我送你一张画吧。" },
        { object: "Ang", text: "虽然很难看，不过也代表着我的一份心意。" },
        { object: "Ang", text: "希望你还愿铭记这些美好。" },
        { object: "Ang", text: "尽管仍旧是是一片虚幻。" },
        { object: "Ang", text: "可我们也还在继续前行。" }
    ],
    para1choice: [

    ]
};
export default egg;
