export const chapter2 = {
    para1: [
        { object: "Aside", text: "我落在一个昏暗的房间内。", music: "nothing" },
        { object: "Aside", text: "这房间唯一吸引到我的，是一张挂画。" },
        { object: "Aside", text: "上面有五个女孩，下面还被手写了几个小字。" },
        { object: "Aside", text: "【我曾经的朋友】" },
        { object: "Aside", text: "不过它和食物似乎是毫无联系，所以我也只能向前望去。" },
        { object: "Aside", text: "我清楚地看到，不远处便有一道亮光，直直地射了进来。" },
        { object: "Aside", text: "说实话，作为整天呆在阴暗潮湿的地方的我们，是实在不喜欢这么亮堂堂的地方的。" },
        { object: "Aside", text: "但是那诱人的食物的香气就从那里迸发出来，同时散发着难以想象的温暖气息，" },
        { object: "Aside", text: "仿佛那一片黄色的亮光不再是亮光，而是一块数米高的巨大奶酪。" },
        { object: "Aside", text: "简直就要将我狠狠拽过去不可。" },
        { object: "Me", text: "食物...食物..." },
        { object: "Aside", text: "我的脑海里面一片空白，几乎只是在想着食物的事情，" },
        { object: "Aside", text: "便一下子就冲进了亮光之中。" },
        { object: "Aside", text: "窜进去之后，却也发现并没有自己想象地那么明亮。", music: "room" },
        { object: "Aside", text: "苍黄色的灯光在天花板上一摇一曳着，" },
        { object: "Aside", text: "各式各样的家具都染上了岁月的颜色。" },
        { object: "Aside", text: "柜子上也不例外，如同它承载的那些瓶瓶罐罐一样。不过似乎近期被翻动过。" },
        { object: "Aside", text: "沙发上也满是灰尘，似乎很久都没有被光顾了。" },
        { object: "Aside", text: "地板上被一层厚厚的泛黄的纸覆盖着，像是一场没有寒冷温度的雪。" },
        { object: "Aside", text: "角落里的火炉就是这真正温暖的来源，真担心它会把地上的白花花的纸全部点燃。" },
        { object: "Aside", text: "在房间最显眼的地方，摆着一个破旧的书桌，上面堆满了乱七八糟的玩意..." },
        { object: "Aside", text: "上面的颜色却不太一样，混合着的，就像一片阴雨天的怪异彩虹。" },
        { object: "Aside", text: "旁边还放了一个架子，上面模模糊糊地也有一些看不懂颜色。" },
        { object: "Me", text: "我从来没见过这些东西..." },
        { object: "Me", text: "..." },
        { object: "Me", text: "不..我闻到味道了，这里肯定有什么吃的..." }
    ],
    para1choice: [
        { object: "Me", text: "柜子上面似乎有些什么...", choice: "柜子上面" },
        { object: "Me", text: "我闻到书桌底下好像有味道...", choice: "书桌底下" },
        { object: "Me", text: "火炉旁边看看会不会遗落了什么...", choice: "火炉旁边" },
    ],
    para1AfterChoice0: [
        { object: "Aside", text: "我蹿进那柜子里，里面的一些支架早已被白蚁啃得干干净净。" },
        { object: "Aside", text: "哪怕现在就哐当一声倒下，全部变成了粉末，我也毫不惊讶。" },
        { object: "Aside", text: "里面的瓶瓶罐罐倒是挺多...却装满了灰尘和尸体的一部分。" },
        { object: "Aside", text: "不过有一个罐子，倒是还挺新鲜的，充满了浓烈的气味..." },
        { object: "Aside ", text: "我嗅了嗅，闻了闻。" },
        { object: "Aside", text: "感觉有一股独特而熟悉的味道，可是却又有些陌生，简直就要引发我的恐怖谷效应了。" },
        { object: "Aside", text: "但没办法，我的肚子已经在不断地抗议，在不满足它就要引发大革命了。" },
        { object: "Aside", text: "我猛烈地咬开了罐口，扑了进去。" },
        { object: "Aside", text: "..." },
        { object: "Aside", text: "..." },
        { object: "MouseA", text: "吱吱吱！" },
        { object: "Me", text: "咬死你！" },
        { object: "MouseA", text: "吱吱！...吱吱吱！！" },
        { object: "Aside", text: "一个和我一样的生物畏畏缩缩地睡在这里，被我惊醒了。" },
        { object: "Aside", text: "它大叫一声，还没等我反应过来，就立马跳了出去，跑得无影无踪..." },
        { object: "Aside", text: "幸好它跑得快。" },
        { object: "Aside", text: "不然我确确实实要把它吃的个干干净净。" },
        { object: "Aside", text: "幸亏这家伙还剩了一点东西。" },
        { object: "Aside", text: "那是一小堆生硬的燕麦，很有营养。" },
        { object: "Aside", text: "我才不嫌弃任何食物，立马将这些残渣像吸尘器一样吸的干干净净。" },
        { object: "Me", text: "稍微好一点了..." },
        { object: "Aside", text: "可是这样子下来，我却感觉更饿了。" },
        { object: "Aside", text: "我知道我要更多。" },
        { object: "Aside", text: "我还没有嗅到人类的踪迹，我应该寻找更多。" },
        { object: "Aside", text: "我稍微恢复了一点体力，跳出了罐子。" },
        { object: "Aside", text: "这时候，我才注意到罐子上面贴着一张纸条，" },
        { object: "Aside", text: "上面写着：‘赠 嘉然今天吃什么’" },
        { object: "Me", text: "..." },
        { object: "Me", text: "这一定是一个巫女的名字。" }
    ],
    para1AfterChoice1: [
        { object: "Aside", text: "穿过那惨白而又发黄的地板，我小心翼翼地窜到了书桌的阴影当中。" },
        { object: "Aside", text: "我站在那些充满了奇怪颜色的纸上。" },
        { object: "Aside", text: "它们红的绿的，蓝的紫的，也有那些拼接在一起的，可却也不知道是什么。" },
        { object: "Aside", text: "突然间，我似乎想起小时候族里面流传的一个传说，说是一个家伙经常看这些东西，" },
        { object: "Aside", text: "最后着了魔，整天都在幻想之中，最后甚至把河里的月亮当做摇篮，" },
        { object: "Aside", text: "扑通一声，最后永远漂浮在永恒的水面上。" },
        { object: "Aside", text: "一想到这些，我突然就有点害怕。" },
        { object: "Aside", text: "我害怕这些就是从前所说的可怕魔法，担心我也最终变成这样。" },
        { object: "Aside", text: "来来回回略过，我发现这些废弃的纸张的右下角都写着同一个人的名字：" },
        { object: "Aside", text: "嘉然Diana。" },
        { object: "Me", text: "这家伙一定是什么恐怖的巫女，能把人迷的神魂颠倒。" },
        { object: "Me", text: "等等...这里..." },
        { object: "Me", text: "我的嗅觉探测器探测到了什么。我挖开了我下面的那块区域，" },
        { object: "Aside", text: "..." },
        { object: "Me", text: "啊！居然还有一小块饼干！" },
        { object: "Aside", text: "我二话不说，立马将饼干啃得干干净净。" },
        { object: "Me", text: "似乎好受一点了..." },
        { object: "Aside", text: "但是光是这些，似乎是完全不够的。" },
        { object: "Aside", text: "我还要更多..." },
        { object: "Aside", text: "还有哪里...会在我上面吗？" },
        { object: "Me", text: "..." }
    ],
    para1AfterChoice2: [
        { object: "Me", text: "我觉得...这里是最有可能出现食物残渣的地方了。" },
        { object: "Me", text: "说不定还有肉呢。" },
        { object: "Aside", text: "我沿着墙角，快速地滑到了火炉旁，就像一个幽灵。" },
        { object: "Aside", text: "同那阴森的下水道不同，人类的火炉，总是十分温暖的。" },
        { object: "Aside", text: "明亮的火光缓缓地燃烧着它的生命。" },
        { object: "Aside", text: "老式的摇椅一动不动着，仿佛静止了一般，" },
        { object: "Aside", text: "在加上温柔的地毯，" },
        { object: "Aside", text: "相比于外面的冰天雪地，" },
        { object: "Aside", text: "也没有这么寒颤了。" },
        { object: "Aside", text: "我终于嗅到了浓烈的味道。" },
        { object: "Aside", text: "我走到火炉面前，发现这里遗落着一块薄薄的东西。" },
        { object: "Aside", text: "它黄黄的，卷卷的，香喷喷的像烤肉，嚼起来却是干干脆脆的。" },
        { object: "Me", text: "简直是太好吃了！" },
        { object: "Aside", text: "我一下子就啃光了，迫不及待想要再来一片。" },
        { object: "Aside", text: "它是如此地印象深刻，以至于我想给它起个名字..." },
        { object: "Me", text: "就...就先叫它【鼠片】吧。" },
        { object: "Aside", text: "突然间，炉火变得异常明亮起来，可我却由不得自己" },
        { object: "Aside", text: "死死地盯着那片火光。" },
        { object: "Aside", text: "一个女孩的身影缓缓地出现，在叫喊着她的名字：" },
        { object: "Diana", text: "嗨！我是嘉然！" },
        { object: "Aside", text: "刹那间，这种奇怪的幻影消失了。" },
        { object: "Me", text: "这个嘉然...必定是某些巫女才有的名字！" },
        { object: "Me", text: "..." }
    ],
    para2: [
        { object: "Aside", text: "..." },
        { object: "Aside", text: "我缓缓地跳到了书桌之上，这里也是人类痕迹最多的地方。" },
        { object: "Aside", text: "我并不喜欢这里，" },
        { object: "Aside", text: "因为对于我们来说，人类就意味着危险。" },
        { object: "Aside", text: "但或许，也意味着食物。" },
        { object: "Aside", text: "我在这些奇奇怪怪的刷子、笔头、木头屑和暗黑彩虹组成的迷宫上穿梭着，" },
        { object: "Me", text: "食物...食物..." },
        { object: "Me", text: "这里看起来...并没有什么食物..." },
        { object: "Aside", text: "..." },
        { object: "Me", text: "..?", music: "image" },
        { object: "Aside", text: "突然间，那个我从未见过的，挂在架子上的那个彩色的组成，有了样貌：" },
        { object: "Aside", text: "它的粉红色，便是那包装；" },
        { object: "Aside", text: "它的黄色，便是那甜蜜本身；" },
        { object: "Aside", text: "它的橙色，便是那甜蜜的内核！" },
        { object: "Me", text: "这是一个巨大的糖果！" },
        { object: "Aside", text: "它不发出任何气息，却比我见过的任何糖果都要香甜；" },
        { object: "Aside", text: "它虽然只是薄薄的一个平面，却比任何实物都要生动；" },
        { object: "Aside", text: "它虽然没有生气，却比任何真实更能牵动内心！" },
        { object: "Aside", text: "而这，只是一些彩色巧妙的组合而已！" },
        { object: "Me", text: "吱吱！" },
        { object: "Aside", text: "我被吓得一动不动，一下子忘却了饥饿，忘却我那小小的本能，" },
        { object: "Aside", text: "几乎是我的一切。" },
        { object: "Me", text: "这就是魔法！" },
        { object: "Diana", text: "嗯...什么东西..." },
        { object: "Me", text: "不好！有人来了！", music: "nervous" },
        { object: "Aside", text: "我终于又被现实拉了回来，迅速跳到了地板上。" },
        { object: "Me", text: "该躲到哪里才好！" }
    ],
    para2choice: [
        { object: "Aside", choice: "旁边的箱子", text: "二话不说，我立马窜进旁边的塞满纸张的箱子里。" },
        { object: "Aside", choice: "沙发底下", text: "我马上就窜进了沙发底下，自认为这里是最安全的地方。" },
        { object: "Aside", choice: "柜子下栏", text: "我窜进了柜子下面，希望能够躲过一劫。" }
    ],
    para2AfterChoice0: [
        { object: "Me", text: "希望不要发现我...", music: "nothing" },
        { object: "Aside", text: "我听着那熟悉的拖鞋踢踏声越来越近，心理捏了一把汗。" },
        { object: "Aside", text: "一个人缓缓地走过来了，听出来她十分慵懒地坐在架子前的椅子上，似乎在摆弄些什么。" },
        { object: "Aside", text: "然后，她的椅子晃了晃，手伸进箱子中，拿走了一张纸。" },
        { object: "Aside", text: "然后她似乎拿起了什么东西，一些奇奇怪怪的摩擦声开始想起。" },
        { object: "Me", text: "." },
        { object: "Diana", text: "纸应该还够...还不错..." },
        { object: "Diana", text: "..." },
        { object: "Diana", text: "..." },
        { object: "Diana", text: "嗯？里面有什么吗？" },
        { object: "Me", text: "我超！", music: "nervous" },
        { object: "Aside", text: "当时的我便是吓得魂飞播散，一下子跳了起来，这也同样把她也吓了一跳！" },
    ],
    para2AfterChoice1: [
        { object: "Me", text: "没人回来这里吧..." },
        { object: "Aside", text: "我就这样想着，就这样冲进沙发底下的重重迷雾之中。" },
        { object: "Aside", text: "沙发底下很黑，很暗，却对我们来说这是最好的地方。" },
        { object: "Aside", text: "在这样的环境下，我的内心多多少少有了一丝安慰。" },
        { object: "Aside", text: "可是我这样子像赛车一样冲进来，一下子飘起了大量的灰尘..." },
        { object: "Me", text: "咳咳..咳咳.." },
        { object: "Aside", text: "我这辈子都没吸过这么多烟尘，就连我也受不了了。" },
        { object: "Aside", text: "在本能的驱使下，我不得不快速逃离了现场。" },
        { object: "Aside", text: "可一暴露在光线的视野下，我发现我已经跑到了她的脚底下了！" }
    ],
    para2AfterChoice2: [
        { object: "Me", text: "呼~", music: "nothing" },
        { object: "Me", text: "希望如此。" },
        { object: "Aside", text: "我躲在那个黑暗的角落里一动不动，在她走过去的时候，我便悄悄地从门缝中逃走。" },
        { object: "Aside", text: "..." },
        { object: "Aside", text: "我听到一个声音，她并没有渐行渐远。" },
        { object: "Aside", text: "相反，我感觉她正从这里走过来！" },
        { object: "Aside", text: "嘣...嘣..." },
        { object: "Diana", text: "去哪里了..." },
        { object: "Diana", text: "..." },
        { object: "Diana", text: "下面也要清理清理才行..." },
        { object: "Diana", text: "不对..." },
        { object: "Diana", text: "哎呀！", music: "nervous" },
        { object: "Me", text: "吱吱！" },
        { object: "Aside", text: "我飞快地蹿了出来，我们两个都吓了一跳。" },
    ],
    para3: [
        { object: "Aside", text: "我被吓坏了。" },
        { object: "Aside", text: "我四处乱窜，慌了阵脚。" },
        { object: "Aside", text: "我本来应该直接冲出门外的。" },
        { object: "Aside", text: "可是却不知怎的，加上冲刺速度太快，迷失了方向。" },
        { object: "Aside", text: "砰！" },
        { object: "Aside", text: "结果那便是，一下子栽在坚硬冰冷的墙上。" },
        { object: "Aside", text: "我的脑袋晃悠悠的，好似一团浆糊。" },
        { object: "Me", text: "..." },
        { object: "Aside", text: "等我再次意识到自己在哪时，我已经被笼罩在那个一米高的巨人身下了。" },
        { object: "Aside", text: "她穿着朴素的白色睡袍，有着棕色的头发，明亮的蓝色双眼和一副稍显稚嫩的身体，头顶上还戴着一个大大的蝴蝶结。" },
        { object: "Aside", text: "她用那圆圆的大眼睛望着我，仿佛第一次见到我这种生物一样：" },
        { object: "Me", text: "你怎么了？你没事吧？" },
        { object: "Aside", text: "可当时的我实在是太紧张，太害怕了。" },
        { object: "Aside", text: "趁她一个不注意，我便飞快地离开了现场，头也不回地离开了。" }
    ],
    para3choice: [

    ]

}
export default chapter2
