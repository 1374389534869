import Diana from "./diana/chapter";
import Bella from "./bella/chapter";
import Ava from "./ava/chapter";
import Croal from "./croal/chapter";
import Eillen from "./eillen/chapter";
export const story = {
    Diana,
    Bella,
    Ava,
    Croal,
    Eillen,
}
export default story;
