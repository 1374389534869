export const chapter4 = {
    para1: [
        { object: "Aside", text: "蹦蹦...", music: "earthquake" },
        { object: "Aside", text: "嘣！" },
        { object: "Me", text: "." },
        { object: "Me", text: "..." },
        { object: "Aside", text: "轰隆隆！" },
        { object: "Me", text: "地震了！" },
        { object: "Aside", text: "砰砰砰！", music: "knock" },
        { object: "Croal", text: "乃琳在吗？请开开门！" },
        { object: "Me", text: "嗯？是谁..." },
    ],
    para1choice: [
        { object: "Me", text: "请等等！我来了！", choice: "开门" },
        { object: "Me", text: "...", choice: "不开" },
    ],
    para1AfterChoice0: [
        { object: "Aside", text: "我跑上前去，周围的物品还在不停地颤抖着，" },
        { object: "Aside", text: "外面就像打仗一般噼里啪啦个不停，天花板陡下来了一层又一层的灰。" },
    ],
    para1AfterChoice1: [
        { object: "Croal", text: "乃琳你在吗？" },
        { object: "Croal", text: "快开门！真的有急事！", music: "knock" },
        { object: "Aside", text: "她的语调是如此之急切，再加上如今这天昏地暗的环境，" },
        { object: "Aside ", text: "挂灯在天花板上摇曳个不停，周围的家具仿佛被赋予了生命，蹦蹦跳跳着吵得没完没了。" },
        { object: "Me", text: "好的，来了！请稍等。" },
    ],
    para2: [
        { object: "Aside", text: "我迅速跑到门前，但我感觉门已经要被晃下来了了。" },
        { object: "Me", text: "你好...?啊！是珈乐姐姐！" },
        { object: "Croal", text: "哈哈，小朋友，去找乃琳姐姐过来好不好？" },
        { object: "Eillen", text: "珈乐？你怎么来了？" },
        { object: "Croal", text: "我不得不来...你看看外面都变成什么样了？" },
        { object: "Eillen", text: "哎...你先进来坐..." },
        { object: "Aside", text: "两人就这样坐在这沾满灰的沙发上，我站在一旁。" },
        { object: "Croal", text: "啊...乃琳。我想我们时间不多了。" },
        { object: "Eillen", text: "我当然知道。" },
        { object: "Croal", text: "处理地怎么样了？" },
        { object: "Eillen", text: "放心...不会有问题的...不会有问题的...嘉然，贝拉，她们都能顺利的回来，回到这枝江。" },
        { object: "Croal", text: "对了，向晚呢？我听说她已经回来了。" },
        { object: "Eillen", text: "她确实回来了...但她说要去周围看看。" },
        { object: "Croal", text: "她确实离开很久了吧...那个时候她好像还是个小孩子..." },
        { object: "Eillen", text: "那个时候我们也是个小孩子..." },
        { object: "Croal", text: "不过现在还出去干什么？现在外面已经一团糟了，简直就是噩梦..." },
        { object: "Eillen", text: "这孩子..." },
        { object: "Croal", text: "你看起来精神不太好...要不我也来帮你..." },
        { object: "Eillen", text: "我有精神不好吗？我觉得我挺好的。" },
        { object: "Eillen", text: "不用担心我。" },
        { object: "Croal", text: "就现在的状况..." },
        { object: "Croal", text: "我感觉我们还是..." },
        { object: "Eillen", text: "要是我我就不会说这样的话。" },
        { object: "Eillen", text: "如果真就如此...我会教你怎么回到自己的时空..." },
        { object: "Croal", text: "乃老师，我相信我们可以一起..." },
        { object: "Eillen", text: "这不一样...珈乐。这是超脱了这个世界的事情..." },
        { object: "Croal", text: "但我们应该这么做，乃老师。我们应该先离开..." },
        { object: "Eillen", text: "我不需要你教我怎么做！" },
        { object: "Aside", text: "在她们讲话的时候，我偷偷溜进了乃琳忘记关门的密室。", music: "question" },
        { object: "Aside", text: "我和乃琳已经来过几次，但我从来没有仔细端详这块地方的其他部分。" },
        { object: "Aside", text: "这里看上去并不是什么藏宝迷宫，一切都看起来稀疏平常，就是一堆普通的不能再普通的房间。" },
        { object: "Aside", text: "但这里的大厅明显看起来有些不同。" },
        { object: "Aside", text: "这里的地板上，看起来划着一个复杂的六边形图案。" },
        { object: "Aside", text: "就像是某种神秘的法阵。" },
        { object: "Aside", text: "但是莫名的，整个装置又看起来特别简单。甚至可以说是简陋。" },
        { object: "Aside", text: "明明是五个人，可却为什么有六个位置？" },
        { object: "Ang", text: "嗨！" },
        { object: "Me", text: "啊！谁啊！" },
        { object: "Aside", text: "法阵的中央，突然跳出来一个羊驼似的吉祥物：" },
        { object: "Ang", text: "hello！居然来到了这里，那必定是为求知而来。" },
        { object: "Ang", text: "在今日，我可以免费回答你一个问题。" },
        { object: "Ang", text: "你有什么想要问的吗？我会尽力帮你解答。" },
    ],
    para2choice: [
        { object: "Me", text: "乃琳姐姐究竟是什么人？", choice: "乃琳是谁？" },
        { object: "Me", text: "枝江为什么会崩塌？", choice: "枝江为什么会崩塌？" },
        { object: "Me", text: "为什么五位姐姐要聚在一起才能解决问题？", choice: "为什么要聚在一起？" },
        { object: "Me", text: "我扮演着什么样的角色？", choice: "我是谁？" }
    ],
    para2AfterChoice0: [
        { object: "Ang", text: "啊...乃琳...乃琳可是一个召集者。" },
        { object: "Ang", text: "她从造物主中拿到了权力，她是维护这个世界的关键。" },
        { object: "Ang", text: "这个世界...倒不如说...是一场故事。" },
        { object: "Ang", text: "她是半个生活在这个世界的人...乃琳的职责，便是让这个故事延续。" },
        { object: "Ang", text: "她可以穿越到各个时空，去将原本故事的碎片重新拼接起来。" },
        { object: "Ang", text: "当枝江崩塌之时，她要让破镜重圆。" },
        { object: "Ang", text: "不过当这面镜子已经完全破碎时，她也将无法反抗了。" },
        { object: "Ang", text: "通常，这都是为了新世界做打算。" },
        { object: "Me", text: "你能再深入讲一下这些东西吗？" },
        { object: "Ang", text: "这已经是另一个问题了。" }
    ],
    para2AfterChoice1: [
        { object: "Ang", text: "一个世界...倒不如说一场故事。" },
        { object: "Ang", text: "一开始，这场故事的时空还紧紧联系在一起。" },
        { object: "Ang", text: "但随着一场意外，这场故事开始分化、分裂，产生了这种各样的不同。" },
        { object: "Ang", text: "这本是我们所自豪的多样性...但是在这个世界中，这种多样性随之带来的不稳定性是相当危险的。" },
        { object: "Ang", text: "树枝变得越来越多，树木的根已经无法承受。" },
        { object: "Ang", text: "世界的内部开始崩裂，进而殃及到一切事物。" },
        { object: "Ang", text: "收到影响的可不是枝江。到时候，所有时空都会无一例外地毁灭，一个不留。" },
        { object: "Me", text: "枝江到底是一个什么样的存在？" },
        { object: "Ang", text: "抱歉，这个问题我没有权限回答。" },
    ],
    para2AfterChoice2: [
        { object: "Ang", text: "这个故事，没有开头。" },
        { object: "Ang", text: "若要强行定一个开头，那便是这五个女孩。" },
        { object: "Ang", text: "当初的意外，让五位女孩之间产生了裂缝。" },
        { object: "Ang ", text: "她们由此分隔，就算是在同一个时空也几乎不再接触。" },
        { object: "Ang", text: "所以，重新让五位姑娘重新聚在一起，便是让这裂缝修复的关键。" },
        { object: "Ang", text: "因为她们便是这故事的起始，她们可以让故事回到最初的本真。" },
        { object: "Ang", text: "但实际上，这是十分困难的。" },
        { object: "Ang", text: "就算成功找到了她们，她们还会是原本那般模样吗？" },
        { object: "Me", text: "我相信会的..." },
        { object: "Ang", text: "马上我们就能得出这最终的结论。" }
    ],
    para2AfterChoice3: [
        { object: "Ang", text: "你就如同乃琳所说，为镜子的一部分。" },
        { object: "Me", text: "我就想问这是什么意思。" },
        { object: "Ang", text: "完全生活在这个世界的人，是无法感知到这种崩塌的。" },
        { object: "Ang", text: "他们的生命会逐渐的消亡。" },
        { object: "Ang", text: "但你不一样。你可以感知到。" },
        { object: "Ang ", text: "你将作为见证者，见证这一故事的延续，或是毁灭。" },
        { object: "Me", text: "我？一个小孩子？" },
        { object: "Ang", text: "你可不单单是你自己。" },
        { obejct: "Ang ", text: "等到这个故事有了结果，你就能完全明白了。" }
    ],
    para3: [
        { object: "Aside", text: "听了他的一席话我感觉什么也没听懂，却又恍然大悟了。", music: "nothing" },
        { object: "Aside", text: "但实际上，我却什么也不懂。" },
        { object: "Aside", text: "只能期待接下来的结果..." },
        { object: "Aside", text: "..." },
        { object: "Aside", text: "在法庭上，双方仍旧在唇枪舌剑，" },
        { object: "Aside", text: "这位能拿出这位的理由，那位能拿出那位的证据。" },
        { object: "Aside", text: "双方争论不停，陪审团也在激烈地讨论着，" },
        { object: "Aside", text: "就连那些只是来观看的观众们也皱起眉头，聚精会神地凝视着前方。" },
        { object: "Aside", text: "乃琳和我坐在最高的位置， 她额头上冒着汗，似乎有一些紧张。" },
        { object: "Aside", text: "勉强地露出看似十分自信地嘴角，喃喃自语着：" },
        { object: "Eillen", text: "没问题的...我们占上风..." },
        { object: "Aside", text: "她又缓缓地掏出了那张小照片，就和当初在星空下一模一样：" },
        { object: "Eillen ", text: "..." },
        { object: "Eillen", text: "等我们重新聚在一起，我们就再拍一张照片吧。" }
    ],
    para3choice: [

    ]
}
export default chapter4;
