export const chapter5 = {
    para1: [
        { object: "Me", text: "..." },
        { object: "Me", text: "我居然都不知道，原来这里还有个小房子。" },
        { object: "Aside", text: "我跟着那个人，一路走到了一栋小棚屋面前。" },
        { object: "Aside", text: "只见他急急忙忙地走了进去，似乎有什么着急的事情一样。" },
        { object: "Me", text: "..." },
        { object: "Me", text: "虽然这是别人的隐私的事，但是..." },
        { object: "Aside", text: "我走到一旁，透过破旧的窗户，听到里面说话的声音:" },
        { object: "He", text: "我来了...我来了..." },
        { object: "He", text: "准备好了吗？" },
        { object: "Ast", text: "当然。马上就可以开始了。" },
        { object: "He", text: "好，很好！" },
        { object: "Aside", text: "听起来是两个人。跟我上次在街角里听到了几乎一样，应该就是她们两了。" },
        { object: "Ava", text: "怎么了...我在哪...我好像刚刚睡醒..." },
        { object: "Aside", text: "等等，怎么还有她的声音！" },
        { object: "Aside", text: "我悄悄抬头望去，发现向晚被绑在一个奇怪的装置上，一脸茫然。" },
        { object: "Aside", text: "那两个人蒙着面，摆弄着一些道具，似乎想要对她做些什么！", music: "cage" },
        { object: "Aside", text: "我最糟糕的想法，居然真的就是现实！" },
        { object: "Me", text: "我应该马上报警..." },
        { object: "Me", text: "等等，我的手机呢..." },
        { object: "Ast", text: "额...这是你的手机吗？" },
        { object: "Me", text: "啊，没错，真是太感谢你了..." },
        { object: "Ast", text: "没关系...去我家坐坐吧。" },
        { object: "Me", text: "嗯？" },
        { object: "Aside", text: "霎时间，我就两眼一黑，被什么东西迅速包裹了起来。" },
        { object: "Aside", text: "等我才反应过来的时候，我发现我双手被捆绑着，被丢在那个小棚屋的一个肮脏的角落。" },
        { object: "He", text: "如你所见，我们又见面了。" },
        { object: "He", text: "不过这次，我挺着急的...恐怕正式交朋友还得等到下次。" },
        { object: "Me", text: "我想已经没有下一次了。" },
        { object: "He", text: "话可不要说得这么绝。我上次不也以为我们应该不会再见面了么。" },
        { object: "He", text: "不过你来这里，倒不是因为我，而是因为她吧。" },
        { object: "Ava", text: "嗨，晚上好啊！" },
        { object: "Ava", text: "我给你们讲一个笑话！" },
        { object: "Me", text: "可恶，你们想要做什么?" },
        { object: "He", text: "我很乐意像你阐述我那伟大的想法。" },
        { object: "He", text: "这个女孩有着神秘的力量。" },
        { object: "He", text: "我们只是想来探究一下有没有可以利用的地方。" },
        { object: "He", text: "放心，我们不会伤害她一根汗毛。" },
        { object: "Me", text: "你这五花大绑的态势可一点可看不出来你的好心。" },
        { object: "He", text: "为了科学！混蛋！" },
        { object: "He", text: "为了进步和发展，我什么事也做的出来！" },
        { object: "He", text: "不多说了，动手！" },
        { object: "Aside", text: "只见另一个人从桌子上拿出了一个遥控器一样的东西，上面连着许许多多的线，通往捆绑向晚的装置上。" },
        { object: "Ava", text: "哎，你紧张吗？" },
        { object: "Me", text: "我当然...等等，最紧张的应该是你吧！" },
        { object: "Me", text: "你不觉得害怕吗？" },
        { object: "Ava", text: "我倒觉得这一觉睡得蛮舒服的。你要知道，我是一个喜欢睡觉的人。睡觉最棒了！" },
        { object: "Me", text: "额..." },
        { object: "He", text: "哈哈，我倒要看看，" },
        { object: "He", text: "为什么你一来不雨城就下雨！" },
        { object: "Ava", text: "因为冷笑话太让人无语了！" },
        { object: "Aside", text: "突然电闪雷鸣，天空中真的下雨了！" },
        { object: "Ast", text: "可以了！" },
        { object: "He", text: "启动！" },
        { object: "Aside", text: "那个拿着遥控器的人正好在我后面。" },
        { object: "Me", text: "你敢!" },
        { object: "Aside", text: "我顶着头冲撞了上去，将他撞得个人仰马翻。" },
        { object: "Aside", text: "遥控器也掉在地上，原本连接在一起的线也散落在一地。" },
        { object: "Ast", text: "该死的!" },
        { object: "Aside", text: "我被重重踢了一脚，疼得倒在地上，差点失去意识。" },
        { object: "He", text: "快装上，快装上！" },
        { object: "He", text: "他妈的！究竟是为了什么，你为这女人付出这么多！" }
    ],
    para1choice: [
        { object: "Me", choice: "她是我爹！", text: "因为她是我爹!!!" }
    ],
    para1AfterChoice0: [
        { object: "Aside", text: "我奋力挺起身，可是却又感觉腹部一阵乏力，立马就倒了下去，脑袋晕乎乎的。" },
        { object: "Ava", text: "我才不是你爹！" },
        { object: "Aside", text: "雨越下越大，小小的棚屋根本无法挡住这倾盆大雨。" },
        { object: "Aside", text: "棚屋的四处都迸出了水流，内部就像一个被打湿的鸟巢一样一团糟。" },
        { object: "Aside", text: "两人开始焦头烂额，四处奔走着：" },
        { object: "He", text: "可恶！赶快修好！" },
        { object: "Ast", text: "可是..." },
        { object: "He", text: "没什么可是的！快，一定要做出来！" },
        { object: "Ava", text: "额..." },
        { object: "Ava", text: "你们的闹剧，也该结束了吧..." },
        { object: "Aside", text: "听到这话，大家都望向棚屋外：" },
        { object: "Aside", text: "透着雨水，我隐隐约约望见一群穿着黑色的衣服的人，他们手中的手电筒异常地光亮。" },
        { object: "He", text: "可恶！不雨城居然在下雨！" },
        { object: "Aside", text: "我听见那绝望的叫喊，顺从着那雨水和泥土的气息渐行渐远。" },
        { object: "Aside", text: "我的意识越来越模糊，周围的一切也逐渐消失不见..." }
    ],
    para2: [

    ],
    para2choice: [

    ]
}
export default chapter5;
