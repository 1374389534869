export const memory = {
    Diana: {
        para1: [
            { object: "Diana", text: "抱歉..." },
            { object: "Diana", text: "我就要远行了。" },
            { object: "Diana", text: "去一个很远很远的地方。" },
            { object: "Diana", text: "我最近就要出发..." },
            { object: "Me", text: "虽然我知道总有这么一天..." },
            { object: "Me", text: "但是我还是难以接受..." },
            { object: "Diana", text: "哈哈，小可爱。" },
            { object: "Diana", text: "我不是送了你许多画嘛。" },
            { object: "Diana", text: "在很久以前，我就对自己的命运做了一次占卜。" },
            { object: "Diana", text: "我画了很多很有意思的画，上面蕴含着一些很有意思的谜语..." },
            { object: "Diana", text: "不过大多数来说，它们都没有意义..." },
            { object: "Diana", text: "比如这张。你能看出来这是什么吗？" },
            { object: "Me", text: "一张纸...上面只画了一根斜斜的线..." },
            { object: "Diana", text: "打一生活用品哦。" }
        ],
        para1choice: [],
    },
    Bella: {
        para1: [
            { object: "Bella", text: "看起来...我不知道这是哪里..." },
            { object: "Bella", text: "但我明白的是，我可能永远也无法和你再见了..." },
            { object: "Bella", text: "我划着小船，下面是璀璨的星河。" },
            { object: "Bella", text: "我的舞蹈让它们凝聚在一起，这是属于整个宇宙的韵律。" },
            { object: "Bella", text: "如果你在的话..." },
            { object: "Bella", text: "如果你真的在那里，也能听到我说的话..." },
            { object: "Bella", text: "请好好回忆一下。在那一天，我因为演出而提前一天过了生日。" },
            { object: "Bella", text: "虽然你其实并没有在场。" },
            { object: "Bella", text: "如果你知道的话，那是七个数字。" },
            { object: "Bella", text: "." },
            { object: "Bella", text: "." },
            { object: "Bella", text: "." },
            { object: "Bella", text: "." },
            { object: "Bella", text: "." },
            { object: "Bella", text: "." },
            { object: "Bella", text: "." },
            { object: "Bella", text: "然后，请忘了我吧。" }

        ],
        para1choice: [

        ],
    },
    Ava: {
        para1: [
            { object: "Ava", text: "嗨，我们又见面了。" },
            { object: "Ava", text: "我就直说了吧。" },
            { object: "Ava", text: "我是氪金玩家！" },
            { object: "Ava", text: "我提前掌握了公测消息！" },
            { object: "Ava", text: "别听乃琳的，她自己都还蒙在鼓里。" },
            { object: "Ava", text: "不过你估计也不相信我。" },
            { object: "Ava", text: "在别人眼中我就是一个整天拉胯的小姑娘..." },
            { object: "Ava", text: "..." },
            { object: "Ava", text: "对呀！哼！" },
            { object: "Ava", text: "我凭什么要告诉你呢！" },
            { object: "Ava", text: "我还是走预订流程，说冷笑话吧！" },
            { object: "Ava", text: "请听！" },
            { object: "Ava", text: "为什么落在西边的塔，比落在东边的塔要高兴呢？" }
        ],
        para1choice: [

        ],
    },
    Croal: {
        para1: [
            { object: "Aside", text: "珈乐的又一场表演结束了。" },
            { object: "Aside", text: "台下响起了如雷般的掌声，欢呼声络绎不绝。" },
            { object: "Audio", text: "烧！" },
            { object: "Audio", text: "太烧了！" },
            { object: "Audio", text: "懂不懂乐的含金量啊！" },
            { object: "Me", text: "太烧了！我着火了！" },
            { object: "Me", text: "消防救一下啊！" },
            { object: "Croal", text: "各位！" },
            { object: "Croal", text: "对女孩子说烧可是不礼貌的哦！" },
            { object: "Croal", text: "应该当面说什么词呢？你们还记得吗？" }
        ],
        para1choice: [

        ],
    },
    Eillen: {
        para1: [
            { object: "Eillen", text: "嗯..." },
            { object: "Eillen", text: "我们的节目，似乎还差了一点东西..." },
            { object: "Me", text: "差了什么？" },
            { object: "Eillen", text: "一个耐人寻味的结尾。" },
            { object: "Me", text: "..." },
            { object: "Me", text: "这么说来，确实如此。" },
            { object: "Eillen", text: "你还记得我们未来节目的结尾吗？" },
            { object: "Eillen", text: "我们可以用那个！" },
            { object: "Eillen", text: "那将是我的直播节目的经典结尾曲目！" },
            { object: "Me", text: "..." },
            { object: "Eillen", text: "怎么了？" },
            { object: "Me", text: "乃琳，你总在提未来的事。" },
            { object: "Me", text: "未来的事，怎么能被现在的自己感知呢？" },
            { object: "Eillen", text: "宝贝。" },
            { object: "Eillen", text: "你一定会明白这是怎么一回事的。" }
        ],
        para1choice: [

        ],
    }
}
export default memory;
